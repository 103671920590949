import React, { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  CssBaseline,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  CircularProgress,
  Stack,
  Paper,
  Button,
  IconButton,
  createSvgIcon,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";

import { ReactComponent as IconReport } from "../../../../assets/easyMaps/iconsSvg/easyReportIcon.svg";
import { ReactComponent as IconBI } from "../../../../assets/easyMaps/iconsSvg/easyBiIcon.svg";
import { ReactComponent as IconMaps } from "../../../../assets/easyMaps/iconsSvg/mapsIcon.svg";
import { ReactComponent as IconSistema } from "../../../../assets/easyMaps/iconsSvg/sistema.svg";

function ToolsMenu(params) {
  const { anchorFerramentas, setAnchorFerramentas } = params;
  const navigate = useNavigate();
  const maxCharLength = 80;
  const theme = useTheme();
  const colorMode = theme.palette.mode;
  const openNotifications = Boolean(anchorFerramentas);

  const EasyReportIcon = createSvgIcon(
    <IconReport height="100%" width="100%" />
  );
  const EasyBIIcon = createSvgIcon(<IconBI height="100%" width="100%" />);
  const EasyMapsIcon = createSvgIcon(<IconMaps height="100%" width="100%" />);
  const SistemaIcon = createSvgIcon(<IconSistema height="100%" width="100%" />);

  const styles = {
    menuItem: {
      borderRadius: 3,
      display: "flex",

      justifyContent: "start",
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      fontSize: "1.25rem",
      fontWeight: "400",
      paddingLeft: "27px",
      marginTop: "1px",
    },
    icon: { borderRadius: "6px", padding: "0px", width: "85px" },
    iconSVG: {
      fill: theme.palette.color.icons,
      fontSize: "80px",
    },
    menu: {
      ".MuiMenu-list": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "301px",
      },
      ".MuiMenu-paper": {
        borderRadius: 4,
        padding: "0px 10px",
        backgroundColor: theme.palette.background.paperNotification,
      },
      ".MuiMenu-paper::-webkit-scrollbar": {
        width: "0.7em",
        display: "block",
        overflow: "auto",
        borderRadius: "30px",
        height: "800px",
      },
      ".MuiMenu-paper::-webkit-scrollbar-track": {
        paddingTop: "20px",
      },
      ".MuiMenu-paper::-webkit-scrollbar-thumb": {
        background: colorMode === "dark" ? "#464B4F" : "#dbdbdb",
        maxHeight: "10px",
        overflow: "hidden",
        borderRadius: 3,
      },
      ".MuiMenu-paper::-webkit-scrollbar-thumb:hover": {
        background: colorMode === "dark" ? "#373737" : "#cccccc",
      },
    },
  };

  return (
    <Box display={"flex"}>
      <CssBaseline />

      <Menu
        id="basic-menu"
        anchorEl={anchorFerramentas}
        open={openNotifications}
        onClose={() => {
          setAnchorFerramentas(null);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disableScrollLock={true}
        elevation={colorMode === "dark" ? 0 : 3}
        sx={styles.menu}
      >
        <Typography variant="h6" sx={styles.title}>
          Ferramentas
        </Typography>{" "}
        <Paper sx={styles.menuItem} elevation={0}>
          <Stack
            direction="row"
            sx={{ padding: "15px", width: "350px", flexWrap: "wrap" }}
            gap={1}
          >
            <IconButton
              onClick={() => {
                setAnchorFerramentas(null);
                navigate("/homeIn");
              }}
              aria-label="delete"
              sx={styles.icon}
            >
              {" "}
              <SistemaIcon sx={styles.iconSVG} />
            </IconButton>
            <IconButton
              onClick={() => {
                setAnchorFerramentas(null);
                navigate("/easyReport/selecionarPagina");
              }}
              sx={styles.icon}
            >
              {" "}
              <EasyReportIcon sx={styles.iconSVG} />
            </IconButton>
            <IconButton
              onClick={() => {
                setAnchorFerramentas(null);
                navigate("/easySgp");
              }}
              sx={styles.icon}
            >
              {" "}
              <EasyBIIcon sx={styles.iconSVG} />
            </IconButton>
            <IconButton
              onClick={() => {
                setAnchorFerramentas(null);
                navigate("/easyMaps");
              }}
              aria-label="delete"
              sx={styles.icon}
            >
              {" "}
              <EasyMapsIcon sx={styles.iconSVG} />
            </IconButton>
          </Stack>
        </Paper>
      </Menu>
    </Box>
  );
}

export default ToolsMenu;
