import {
  Box,
  Paper,
  Tabs,
  Tab,
  Stack,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  Snackbar,
  Alert,
  Skeleton,
  Typography,
  Autocomplete,
  Checkbox,
  Grid,
  alpha,
} from "@mui/material";
import Alerte from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useEffect, useState } from "react";
import {
  Add,
  Search,
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank,
  MapOutlined,
  WifiFindOutlined,
  InfoOutlined,
} from "@mui/icons-material";

import { useOutletContext } from "react-router-dom";
import CardMapsHome from "../../../../componentes/easyMaps/cardMapsHome";
import NewMap from "../../../../componentes/easyMaps/newMap";
import CardMapsHomeRp from "../../../../componentes/easyMaps/cardMapsHomeRP";
const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    mt: 2,
    mb: 1,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: 1,
  },
  headerBox: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
  },

  addIcon: { mt: -0.2 },
  headerPaper: {
    boxShadow: "0px 0px 5px  #0000001a",
    mb: "15px",

    padding: "16px",
    display: "flex",
    gap: "16px",
    flexDirection: "column",
    position: "relative",
    width: "100%",
  },

  button: { width: "148px", minWidth: "148px" },
  buttonDialog: {
    color: theme?.palette?.color?.buttonConfirm || "primary.main",
  },
  textfield: { width: "360px" },
  tabs: {
    zIndex: 1500,
    ml: "-2px",
    "& button": {
      ml: "2px",
      mt: "1px",
      backgroundColor: theme.palette.background.tab,
      boxShadow: "0px 0px 5px  #0000001a",
      color: theme.palette.text.disable,
      textTransform: "none",
      borderTopLeftRadius: "5px",
      borderTopRightRadius: "5px",
      minWidth: "120px",
      minHeight: "50px",
    },
    "& button.Mui-selected": {
      backgroundColor: theme.palette.background.paper,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "transparent",
      border: "none",
    },
  },
});

export default function MapaTopologiaPOPEasyMaps() {
  const { maps, loadingPage, setMaps, api } = useOutletContext();
  const [tabValue, setTabValue] = useState(0);
  const [openNovoMapaDialog, setOpenNovoMapaDialog] = useState(false);
  const [openNovoPopDialog, setOpenNovoPopDialog] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const [textFilter, setTextFilter] = useState("");
  const handleCloseSnackbar = () => setSnackbar(null);
  const theme = useTheme();
  const styles = useStyles(theme);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    setTextFilter("");
  }, [tabValue]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  const mapasFilter =
    maps.filter((map) => {
      return map.titulo.toLowerCase().includes(textFilter.toLowerCase());
    }) || [];

  function deleteMapsInMaps(indexMap) {
    const newMaps = [...maps];
    newMaps.splice(indexMap, 1);
    setMaps(newMaps);
  }
  function addMapInMaps(map) {
    const newMaps = [...maps];
    newMaps.push(map);
    setMaps(newMaps);
  }
  function duplicateMapsInMaps(newMap, id) {
    let map = JSON.parse(JSON.stringify(maps.find((map) => map.id == id)));
    let mapDuplicated = { ...map, ...newMap };
    setMaps((maps) => [...maps, mapDuplicated]);
  }
  function updateMapTokenInMaps(mapToken, indexMap) {
    let newMaps = [...maps];
    newMaps[indexMap]["mapToken"] = mapToken;
    setMaps(newMaps);
  }

  function updateTitleInMaps(newTitle, indexMap) {
    const newMaps = [...maps];
    newMaps[indexMap].titulo = newTitle;
    setMaps(newMaps);
  }

  return (
    <Box sx={styles.container}>
      <Box sx={styles.body}>
        <Stack width="100%">
          <Paper sx={styles.headerPaper}>
            <Box sx={styles.headerBox}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                flexWrap={"wrap"}
                gap={1}
              >
                <TextField
                  placeholder={"Buscar"}
                  autoComplete="off"
                  size="small"
                  value={textFilter}
                  onChange={(event) => {
                    setTextFilter(event.target.value);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: theme.palette.background.paperLinkedMaps,
                      boxShadow: "none",
                      width: "300px",
                      "&:hover": {
                        backgroundColor:
                          theme.palette.background.paperLinkedMaps,
                      },
                    },
                  }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    },
                  }}
                />

                <Button
                  color="primary"
                  variant="contained"
                  sx={styles.button}
                  endIcon={<Add sx={styles.addIcon} />}
                  onClick={() => {
                    setOpenNovoPopDialog(true);
                  }}
                >
                  Novo POP
                </Button>
              </Stack>
            </Box>
          </Paper>
        </Stack>
        {loadingPage ? (
          Array.from({ length: 8 }).map((_, index) => (
            <Skeleton
              key={index}
              variant="rectangular"
              sx={{ width: "100%", height: "58px", borderRadius: "6px" }}
            />
          ))
        ) : (
          <>
            <Grid
              container
              rowSpacing={1}
              direction="row"
              justifyContent="flex-start"
              columnSpacing={{ xs: 1, sm: 2, md: 2 }}
              columns={parseInt(Math.floor((width * 0.75) / 360))}
            >
              {Array.isArray(mapasFilter) &&
                mapasFilter.length > 0 &&
                mapasFilter.map(
                  (map, index) =>
                    map.tipo == 2 && (
                      <Grid item key={index} xs={1} flex={1}>
                        <CardMapsHome
                          key={index}
                          index={index}
                          id={map.id}
                          mapName={map.titulo}
                          tipo={map.tipo}
                          inGroup={false}
                          alertas={map.alertas}
                          hostsNames={[map.hosts]}
                          hostsCounts={map.hosts?.length || 0}
                          popsCounts={map.pops?.length || 0}
                          mapasCounts={map.mapas?.length || 0}
                          deleteMapsInMaps={deleteMapsInMaps}
                          duplicateMapsInMaps={duplicateMapsInMaps}
                          updateTitleInMaps={updateTitleInMaps}
                          updateMapTokenInMaps={updateMapTokenInMaps}
                          setSnackbar={setSnackbar}
                          mapas={map?.mapas}
                          pops={map?.pops}
                          mapToken={map?.mapToken}
                        />
                      </Grid>
                    )
                )}
            </Grid>
          </>
        )}
      </Box>

      <NewMap
        key={"newMapa-pop-Main"}
        openNovoMapaDialog={openNovoPopDialog}
        setOpenNovoMapaDialog={setOpenNovoPopDialog}
        setSnackbar={setSnackbar}
        maps={maps}
        initialtab={1}
        addMapInMaps={addMapInMaps}
        tipoDefault={2}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Box>
  );
}
