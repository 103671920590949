import checkToken from "../../services/CheckToken";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import LoadingPage from "../../componentes/loadingPage";

function checkPerfil() {
  let perfilAlreadyExists =
    JSON.parse(localStorage.getItem("1"))?.perfilAlreadyExists || false;
  let satusPerfil = JSON.parse(localStorage.getItem("perfil"))?.status || false;
  if (satusPerfil === true && perfilAlreadyExists === true) {
    return true;
  }
  return false;
}
export default function ProtectedRoute({
  children,
  setCheckLogin,
  checkLogin,
}) {
  useEffect(() => {
    const tokenValidate = async () => {
      const data = await checkToken();
      if (data) setCheckLogin(true);
      else setCheckLogin(false);
    };
    tokenValidate();
  }, []);

  if (checkLogin === null) {
    return <LoadingPage />;
  }

  if (!checkLogin || !localStorage.getItem("1")) {
    return <Navigate to="/login" />;
  }

  if (!checkPerfil()) {
    return <Navigate to="/cadastro" />;
  }

  return children;
}
