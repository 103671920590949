import { useState } from "react";
import {
  Box,
  Stack,
  TextField,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
  Snackbar,
  Alert,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  CheckCircle,
  Delete,
  Edit,
  Error,
  ExpandMore,
  RemoveCircle,
  Settings,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import fabricantes from "../fabricantes.json";
import ModalCreateHost from "./createHostModal";
import api from "../../../services/api";
import ModalEditHost from "./editHostModal";
import CopyModal from "./copyModal/copyModal";
import AlertModal from "../../../componentes/alertModal";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "30px",
    width: "100%",
  },
  paper: {
    width: "100%",
    minWidth: "320px",
    padding: "20px",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
    elevation: 3,
  },
  accordion: {
    width: "100%",
    gap: "20px",
  },
  button: { height: "40px", minWidth: "120px", m: 1 },
  filter: { width: { xs: "100%", sm: "180px" } },
  deleButton: { width: "100px", fontWeight: "800" },
  dangerousIcon: { fontSize: 60 },
  boxDialogs: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  dialogText: { color: "#D32F2F" },
  titles: { fontWeight: 500, fontSize: "20px" },
};

export default function HostsCadastrados(params) {
  const {
    rows,
    setRows,
    loadingPage,
    loadingPredefinicoes,
    predefinicoes,
    //handleApiGetHosts,
    proxys,
  } = params;
  const [nome, setNome] = useState("");
  const [ip, setIp] = useState("");
  const [fabricante, setFabricante] = useState("");
  const [coletor, setColetor] = useState("");
  const [funcao, setFuncao] = useState("");
  const [error, setError] = useState("");
  //const [rows, setRows] = useState([]);

  //const [hiddenFilter, setHiddenFilter] = useState(false);
  //const [tipo, setTipo] = useState("easymon");
  //
  const [selectedRow, setSelectedRow] = useState({});
  //
  const [snackbar, setSnackbar] = useState(null);
  //
  const [disableButton, setDissableButton] = useState(false);
  const [disableButtonDelete, setDissableButtonDelete] = useState(false);
  //modal
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  //menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    interfaceid: false,
    senhaTelnet: false,
    portaTelnet: false,
    userTelnet: false,
    tipoSnmp: false,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleApiDelete() {
    try {
      //console.log(selectedRow);
      const response = await api.post("/easyAdd/delete", {
        id: selectedRow.id,
      });

      if (response.data.status === "Error") {
        //console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível excluir dados",
          severity: "error",
        });
      } else {
        setSnackbar({
          children: "Dados excluídos com sucesso",
          severity: "success",
        });

        deleteRow(selectedRow.id);
        setOpenDeleteDialog(false);
        handleClose();
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
    }
  }

  const filteredRows = rows.filter((row, index) => {
    row.numero = index;
    //console.log("text", row);
    return (
      row.nome.toLowerCase().includes(nome.toLowerCase()) &&
      row.ip.toLowerCase().includes(ip.toLowerCase()) &&
      row.fabricante.toLowerCase().includes(fabricante.toLowerCase()) &&
      row.funcao.toLowerCase().includes(funcao.toLowerCase()) &&
      row.coletor.toLowerCase().includes(coletor.toLowerCase())
    );
  });

  function deleteRow(id) {
    const novoArray = rows.filter((obj) => obj.id !== id);
    setRows(novoArray);
  }

  function updateRow(hostUpdate) {
    const novoArray = rows.map((obj) => {
      if (obj.id === hostUpdate.id) {
        return hostUpdate;
      } else {
        return obj;
      }
    });
    setRows(novoArray);
  }

  function AddRow(newHost) {
    const novoArray = rows;
    novoArray.push(newHost);
    setRows(novoArray);
  }

  function validateIp(ip) {
    const parts = ip.split(".");

    if (ip === "") {
      return "";
    } else {
      if (parts.length !== 4) {
        return "Formato inválido de endereço IP.";
      }
      for (let i = 0; i < 4; i++) {
        const part = parseInt(parts[i]);
        if (isNaN(part) || part < 0 || part > 255) {
          return "Formato inválido de endereço IP.";
        }
      }
      return "";
    }
  }

  const handleChangeIp = (event) => {
    const newIp = event.target.value;
    setIp(newIp);
    //setError(validateIp(newIp));
  };
  const columns = [
    { field: "id", headerName: "ID", width: 100, hide: true },
    {
      field: "interfaceid",
      headerName: "Interface ID",
      width: 100,
    },
    {
      field: "numero",
      headerName: "N°",
      width: 60,
    },
    {
      field: "nome",
      headerName: "NOME",
      //width: 175,
      flex: 1,
    },

    {
      field: "ip",
      headerName: "IP",
      width: 120,
    },
    {
      field: "portaSnmp",
      headerName: "PORTA SNMP",
      width: 100,
    },
    {
      field: "tipoSnmp",
      headerName: "TIPO SNMP",
      width: 85,
    },
    {
      field: "comunidade",
      headerName: "COMUNIDADE",

      width: 105,
    },
    {
      field: "fabricante",
      headerName: "FABRICANTE",

      width: 95,
    },
    { field: "funcao", headerName: "FUNÇÃO", width: 100 },
    { field: "modelo", headerName: "MODELO", width: 100, hide: true },
    {
      field: "coletor",
      headerName: "COLETOR",

      width: 130,
    },
    {
      field: "ping",
      headerName: "PING",
      width: 65,
      renderCell: (params) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", width: "100%" }}
        >
          {params.value ? (
            <CheckCircle color="success" />
          ) : (
            <Error color="error" />
          )}
        </Stack>
      ),
    },
    {
      field: "snmp",
      headerName: "SNMP",
      width: 65,
      type: "",
      renderCell: (params) => (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", width: "100%" }}
        >
          {params.value === true ? (
            <CheckCircle color="success" />
          ) : params.value === false ? (
            <Error color="error" />
          ) : (
            <RemoveCircle color="disabled" />
          )}
        </Stack>
      ),
    },
    {
      field: "userSsh",
      headerName: "USER SSH",

      width: 100,
    },
    {
      field: "portaSsh",
      headerName: "PORTA SSH",
      width: 100,
    },
    {
      field: "senhaSsh",
      headerName: "SENHA SSH",
      width: 100,
    },
    {
      field: "userTelnet",
      headerName: "USER TELNET",

      width: 100,
    },
    {
      field: "portaTelnet",
      headerName: "PORTA TELNET",
      width: 110,
    },
    {
      field: "senhaTelnet",
      headerName: "SENHA TELNET",
      width: 110,
    },
    // {
    //   field: "actions",
    //   headerName: "",
    //   sortable: false,
    //   width: 80,

    //   renderCell: (params) => {
    //     const onClick = (e) => {
    //       e.stopPropagation();
    //       // don't select this row after clicking
    //       const api = params.api;
    //       const thisRow = {};

    //       api
    //         .getAllColumns()
    //         .filter((c) => c.field !== "__check__" && !!c)
    //         .forEach(
    //           (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
    //         );

    //       hanldeUpDateSelectRow(thisRow);

    //       setAnchorEl(e.currentTarget);
    //     };
    //     function hanldeUpDateSelectRow(thisRow) {
    //       setSelectedRow(thisRow);
    //     }

    //     //
    //     return (
    //       <Box key={params.id}>
    //         <Tooltip title={"Ações"} id="basic-button" onClick={onClick}>
    //           <IconButton size="small">
    //             <Settings color="primary" />
    //           </IconButton>
    //         </Tooltip>
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <Box sx={styles.container}>
      {/* <Paper elevation={3} sx={styles.paper}> */}
      <Accordion
        sx={styles.accordion}
        square
        elevation={3}
        defaultExpanded={true}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography color={"primary"} sx={styles.titles}>
            Hosts Cadastrados
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack
            direction={"column"}
            gap="20px"
            sx={{
              width: "100%",
            }}
          >
            <Stack
              direction={"row"}
              gap={"20px"}
              //minWidth={"650px"}
              sx={{ flexWrap: "wrap" }}
            >
              <TextField
                id="host-nome"
                variant="outlined"
                placeholder="Nome"
                sx={styles.filter}
                value={nome}
                onChange={(event) => {
                  setNome(event.target.value);
                }}
                type="text"
              />
              <TextField
                id="ip"
                variant="outlined"
                placeholder="IP"
                value={ip}
                onChange={handleChangeIp}
                onBlur={() => setError(validateIp(ip))}
                error={Boolean(error)}
                helperText={error}
                sx={styles.filter}
              />

              <Autocomplete
                inputValue={fabricante}
                onInputChange={(event, newInputValue) => {
                  setFabricante(newInputValue);
                }}
                freeSolo
                options={fabricantes.map((options) => options.fabricante)}
                sx={styles.filter}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Fabricante"
                    variant="outlined"
                  />
                )}
              />

              <TextField
                id="host-funcao"
                variant="outlined"
                placeholder="Função"
                type="text"
                sx={styles.filter}
                value={funcao}
                onChange={(event) => {
                  setFuncao(event.target.value);
                }}
              />
              <TextField
                select
                id="host-coletor"
                variant="outlined"
                label="Coletor"
                sx={styles.filter}
                value={coletor}
                onChange={(event) => {
                  setColetor(event.target.value);
                }}
              >
                <MenuItem value={""}>TODOS</MenuItem>
                <MenuItem value={"ZABBIX SERVER"}>ZABBIX SERVER</MenuItem>
                <MenuItem value={"PROXY"}>PROXY</MenuItem>
              </TextField>
              {/* 
              <FormControl variant="outlined" label="Coletor">
                <InputLabel> Coletor</InputLabel>
                <Select
                  label="Coletor"
                  //fullWidth
                  value={coletor}
                  onChange={(event) => {
                    setColetor(event.target.value);
                  }}
                  sx={styles.filter}
                >
                  <MenuItem value={""}>TODOS</MenuItem>
                  <MenuItem value={"ZABBIX SERVER"}>ZABBIX SERVER</MenuItem>
                  <MenuItem value={"PROXY"}>PROXY</MenuItem>
                </Select>
              </FormControl> */}
            </Stack>
            <Stack
              sx={{
                width: "100%",

                height:
                  filteredRows.length > 19
                    ? "800px"
                    : filteredRows.length == 0
                    ? "250px"
                    : filteredRows.length * 36 + 115 + "px",
              }}
            >
              <DataGrid
                columns={columns}
                density="compact"
                rows={filteredRows}
                localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                sx={{
                  fontSize: 12,
                  "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                    outline: "none !important",
                  },
                  height: "100%",
                  ".MuiDataGrid-cell": {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
                pageSizeOptions={[25, 50, 100]}
                initialState={{
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                //page={1}
                loading={loadingPage}
                //slots={{ toolbar: GridToolbar }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }
                disableColumnMenu
                disableColumnFilter
                disableSelectionOnClick
              />
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
      {/* </Paper> */}
      <AlertModal
        openModal={openDeleteDialog}
        setOpenModal={setOpenDeleteDialog}
        confirmar={disableButton}
        handleButton={handleApiDelete}
        severity={"error"}
        singleButton={false}
      />
      <AlertModal
        openModal={openEditDialog}
        setOpenModal={setOpenEditDialog}
        confirmar={disableButtonDelete}
        //handleButton={handleApiDelete}
        severity={"warning"}
        singleButton={false}
      />
      {/*  <Dialog
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false);
        }}
      >
        <DialogTitle>{"Deseja realmente salva os dados editados?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uma vez após editado os dados anteriores não poderem ser
            recuperados. Tem certeza que de deseja fazer essa edição ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color={"secondary"}
            onClick={() => {
              setOpenEditDialog(false);
            }}
          >
            Cancelar
          </Button>

          <Button autoFocus disabled={disableButton}>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
      >
        <DialogTitle sx={styles.dialogText}>
          Deseja realmente <span style={{ fontWeight: "900" }}>excluir</span>{" "}
          esses dados?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Box sx={styles.boxDialogs}>
              <Dangerous color={"error"} sx={styles.dangerousIcon} />
            </Box>
            <span style={styles.dialogText}>
              Uma vez após excluidos os dados não podem ser recuperados. Tem
              certeza que de deseja fazer essa exclusão ?
            </span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Cancelar
          </Button>

          <Button
            autoFocus
            color={"error"}
            sx={styles.deleButton}
            disabled={disableButtonDelete}
            onClick={() => {
              handleApiDelete();
            }}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>*/}
      {/* <ModalCreateHost
        predefinicoes={predefinicoes}
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        setSnackbar={setSnackbar}
        AddRow={AddRow}
        proxys={proxys}
        handleApiGetHosts={handleApiGetHosts}
      />
      <ModalEditHost
        predefinicoes={predefinicoes}
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        setSnackbar={setSnackbar}
        selectedRow={selectedRow}
        updateRow={updateRow}
        proxys={proxys}
        handleApiGetHosts={handleApiGetHosts}
      /> */}
      <CopyModal
        openCopyModal={openCopyModal}
        setOpenCopyModal={setOpenCopyModal}
      />

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/*         <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          <ListItemIcon>
            <Refresh fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Atualizar</ListItemText>
        </MenuItem> */}

        <MenuItem
          onClick={() => {
            setOpenEditModal(true);
            handleClose();
          }}
          //  disabled
        >
          <ListItemIcon>
            <Edit fontSize="small" color="primary" />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteDialog(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <Delete fontSize="small" color="secondary" />
          </ListItemIcon>
          <ListItemText>Deletar</ListItemText>
        </MenuItem>
      </Menu>
    </Box>
  );
}
