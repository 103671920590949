import { alpha } from "@mui/material";

const useStyles = (theme) => ({
  ContainerBox: {
    width: "100%",
    alignItems: "flex-start",
    paddingTop: "100px",
    display: "flex",
    justifyContent: "center",
    minHeight: "60vw",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    padding: 1,
    maxWidth: "432px",
    width: "90%",
    minWidth: "232px",
    maxHeight: "90vh",
    gap: 2,
    flexDirection: "column",
    display: "flex",
    alignItems: "felx-start",
  },
  PaperSecondary: {
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    paddingTop: "10px",
    borderRadius: 3,
    bgcolor: "background.default",
  },
  Title: {
    fontFamily: "Roboto",
    fontWeight: "400",
    letterSpacing: "0.8px",
    marginLeft: "40px",
  },
  TextCampo: {
    fontSize: "16px",
    fontWeight: "600",
  },
  TextField: {
    fontSize: "16px",
    fontWeight: "600",
    margin: "10px",
  },
  TypographyCampo: {
    fontSize: "14px",
    fontWeight: "400",
  },
  StyleButtons: {
    margin: "5px",
  },
  StackFoto: {
    alignItems: "center",
    height: "220px",
    position: "absolute",
    marginTop: "50px",
    marginRight: "275px",
  },
  ButtonFoto: {
    width: "130px",
    position: "absolute",
    display: " flex",
    padding: "8px 22px",
    alignItems: "center",
    margin: "100px",
    gap: "8px",
    fontSize: "13px",
  },

  BoxAvatar: {
    width: "126px",
    height: "126px",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    bgcolor: "background.paperOutlined",
  },
  Avatar: {
    width: "110px",
    height: "110px",
  },
  paperPreview: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "100%",
    padding: 0,
    gap: 1,
  },
  boxCover: {
    height: "100px",
    borderRadius: "5px",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    width: "100%",
  },
  avatar: {
    position: "absolute",
    width: "100px",
    height: "100px",
    mb: 2,
    ml: 3,
    outline: "8px solid" + " " + "background.paper",
  },
  boxAvatar: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "end",
  },
  boxIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "white",
    bgcolor: "primary.main",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    position: "absolute",
    margin: "0px 0px 10px 90px",
  },
  boxLoginAndButton: {
    display: "flex",
    flexDirection: { sm: "row", xs: "column" },
    justifyContent: "end",
    alignItems: { xs: "end" },
    width: { sm: "100%", xs: "50%" },
    gap: { xs: 2, sm: 6 },
  },
  login: {
    display: "flex",
    fontWeight: 500,
    color: "text.primary",
    textWrap: "nowrap",
  },
  boxButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: 1,
    padding: 0,
    width: "100%",
  },
  imageButton: {
    width: "40px",
    height: "40px",
    color: "white",
    backgroundColor: "primary.main",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    position: "absolute",
    margin: "0px 0px 10px 90px",
    "&:hover": {
      backgroundColor: "background.textField",
      color: "primary.main",
    },
  },
  foto: {
    width: "30px",
    height: "30px",
  },
  stack: {
    padding: "0px",
    width: "100%",
    height: "100%",
  },
  button: {
    fontSize: "13px",
    marginRight: "10px",
    backgroundColor: "filters.main",
    color: "primary.main",
  },
  modal: { display: "flex", justifyContent: "center", alignItems: "center" },
  paper: {
    padding: 1,
    maxWidth: "432px",
    width: "90%",
    minWidth: "232px",
    maxHeight: "90vh",
    gap: 2,
    flexDirection: "column",
    display: "flex",
    alignItems: "felx-start",
  },
  boxTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    color: "primary.main",
    width: "100%",
  },
  boxHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: "text.primary",
  },
  dialogTitle: {
    padding: 0,
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: 0,
    padding: 0,
    width: "100%",
  },
  dialogContent: {
    //flexGrow: 1,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    gap: 1.5,
  },
  boxRowFields: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    width: "100%",
    gap: 1,
    padding: 1,
  },

  textfield: {
    width: "100%",
  },
  /* buttonClose: {
        color: theme.palette.grey[500],
      }, */
  alert: { mt: 2, boxShadow: 0 },
  paragraph: {
    color: "text.secondary",
    fontSize: "14px",
    fontWeight: 500,
  },
  boxFullName: {
    display: "flex",
    flexDirection: "row",
    gap: 0.5,
  },
  loadingButton: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: "primary.main",
  },
  buttonEdit: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: "primary.main",
  },
});

export default useStyles;
