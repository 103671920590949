import { alpha } from "@mui/material";

const useStyles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  paperContent: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  title: {
    fontSize: "20px",
    fontWeight: 500,
  },
  subtitle: {
    fontSize: "16px",
  },
  editorContainer: { position: "relative" },
  copyButton: { position: "absolute", top: 5, right: 5, zIndex: 9 },
  alert: {
    mt: "16px",
    borderRadius: "4px",
    backgroundColor: alpha(theme.palette.error.main, 0.05),
  },
});

export default useStyles;
