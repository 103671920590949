const styles = {
  container: {
    padding: "16px",
    display: "flex",
    position: "relative",
    width: "100%",
    borderRadius: 3,
    minWidth: "320px",
    flexDirection: "column",
    // minWidth: "620px",
  },
  paperBody: {
    marginTop: "10px",
    width: "100%",
    padding: "1rem 1.8rem",
    display: "flex",
    flexDirection: "column",
    borderRadius: 3,
    //flexWrap: "wrap",
    gap: 1,
    bgcolor: "background.paperOutlined",
  },
  title: { fontSize: "1.3rem", fontWeight: 900, color: "primary.main" },
  inboxSubtitle: {
    fontSize: "0.75rem",
    lineHeight: 1.25,
    fontWeight: 600,
    letterSpacing: "0.033em",
    color: "primary.main",
  },
  inboxTextDescricao: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: 400,
    width: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxTextCategoria: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: 400,
    width: "110px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxTextSubcategoria: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: 400,
    width: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inboxTextName: {
    fontSize: "0.875rem",
    lineHeight: 1.5,
    fontWeight: 400,
    width: "140px",
  },
  divider: {
    marginTop: "5px",
    marginBottom: "5px",
  },
  boxStatus: {
    display: "flex",
    flexDirection: "column",
    width: "260px",
    justifyContent: "center",
    //minWidth: "200px",
  },
  tooltipBoxStatus: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    gap: "5px",
    mt: "8px",
  },
  statusText: {
    fontSize: "0.85rem",
    opacity: "85%",
  },
  boxDemanda: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    gap: 0.5,
  },
  history: {
    opacity: "80%",
    mt: 0.5,
  },
  demandaText: {
    fontSize: "0.9rem",
    mt: "8px",
  },
};

export default styles;
