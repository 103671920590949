import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  Fragment,
  useMemo,
} from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  LayerGroup,
  MapContainer,
  TileLayer,
  useMap,
  useMapEvents,
} from "react-leaflet";
import simplify from "simplify-js";
import L from "leaflet";
import "../../../../../componentes/leaflet-migrations";
import "../../../../../componentes/leaflet-fullscreen/Leaflet.fullscreen";
import "leaflet-routing-machine";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import RightBarEasyMaps from "../../../../../componentes/easyMaps/rightBarEasyMaps";
import TitleBarPublic from "../../../../../componentes/easyMaps/titleBarPublic";
import DescriptionBar from "../../../../../componentes/easyMaps/descriptionBar";
import NodeIcon from "../../geolocalizado/leaflatMapa/nodeIcon";
import { PolylineCustom } from "../../geolocalizado/leaflatMapa/polyLine";
import ToolBarMapsPublic from "../../../../../componentes/easyMaps/toolbarMapsPublic";
import { useParams } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);

const LeafletMapa = forwardRef((props, ref) => {
  const {
    title,
    setFullScreem,
    fullScreem,
    elements = [],
    connections = [],
    setElementSelected,
    elementSelected,
    iconsElements = [],
    setEasyMapsConfig = () => {},
    easyMapsConfig,
  } = props;

  const [showLabel, setShowLabel] = useState(false);
  const [zoomIcons, setZoomIcons] = useState(5);
  const [contextMenu, setContextMenu] = useState(null);
  const [elementsConnected, setElementsConnected] = useState([]);
  const [openRightBar, setOpenRightBar] = useState(false);
  const [connectionSelected, setConnectionSelected] = useState(null);
  const [edgeSelected, setEdgeSelected] = useState(null);
  const [loadRouting, setLoadRouting] = useState(false);
  const [contextMenuFlow, setContextMenuFlow] = useState(null);
  const [checkChanges, setCheckChanges] = useState(null);
  const [openAlertModal, setOpenAlertModal] = useState(null);
  const [openConnection, setOpenConnection] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { token } = useParams();
  const [showLabelEnlace, setShowLabelEnlace] = useState(true);
  const mapRef = useRef(null);
  const [menuCreation, setMenuCreation] = useState(false);
  const theme = useTheme();
  const styles = {
    paperContainer: {
      padding: "16px ",
      display: "flex",
      alignItems: "center",
      position: "relative",
      width: "100%",
      borderRadius: 3,
      mt: 5.5,
    },
    body: {
      width: "100%",
      height: "100%",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      left: "0px",
      top: "0px",
      //zIndex: 0,
      overflow: "hidden",
    },

    mapInfoStack: {
      padding: "12px 16px",
      width: "250px",
    },
    mapInfoDataStack: {
      padding: "12px 16px",
      width: "120px",
    },
    mapInfoTitle: {
      fontSize: "0.75rem",
      lineHeight: 1.25,
      fontWeight: 600,
      letterSpacing: "0.033em",
      color: "primary.main",
    },
    mapInfoText: {
      fontSize: "0.875rem",
      lineHeight: 1.5,
      fontWeight: 400,
      textTransform: "capitalize",
    },
    fontPopup: {
      margin: "0px",
      fontSize: "10px",
      fontWeight: 500,
      color: theme.palette.color.textEasyMaps,
    },
    titlePopup: {
      margin: "0px",
      fontSize: "12px",
      fontWeight: 500,
      color: theme.palette.color.textEasyMaps,
      width: "100%",
      textAlign: "center",
      marginBottom: "10px",
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limite de 2 linhas
      WebkitBoxOrient: "vertical",
    },
  };

  useEffect(() => {
    // setElementSelected(null);
    setConnectionSelected(null);
    setElementsConnected(null);
    setEdgeSelected(null);
  }, [showLabel]);

  useImperativeHandle(ref, () => ({
    focusZoomExt(id) {
      focusZoom(id);
    },
  }));

  // -----------------------mapContext-----------------------

  function Events() {
    const map = useMap();
    useMapEvents({
      click(e) {
        onPaneClick(e);
      },
      zoom(e) {
        setZoomIcons(calculeZoom(map));
      },
      contextmenu(e) {
        L.DomEvent.stopPropagation(e);
      },
    });

    return null;
  }

  const SetZoom = () => {
    const map = useMap();
    useEffect(() => {
      if (!map) return;
      setZoomIcons(calculeZoom(map));
    }, [map]);

    return null;
  };

  const SetMaxBounds = () => {
    const map = useMap(); // Acesse o objeto `map`
    useEffect(() => {
      if (!map) return;

      // Define os limites máximos do mapa
      const southWest = L.latLng(-89.98155760646617, -180);
      const northEast = L.latLng(89.99346179538875, 180);
      const bounds = L.latLngBounds(southWest, northEast);

      map.setMaxBounds(bounds);
      map.invalidateSize();
    }, [map]);

    return null;
  };
  // ----------------------- fim mapContext-----------------------

  const calculeZoom = (map) => {
    return map_range(map.getZoom() || 0, 0, 19, 1, 9);
  };

  const formatPoints = (element) => {
    let nodeStart = null,
      nodeEnd = null;

    if (element.referenceId == elementSelected?.id) nodeStart = elementSelected;
    else nodeStart = elements.find((node) => node.id == element.referenceId);

    if (element.destinationId == elementSelected?.id) nodeEnd = elementSelected;
    else nodeEnd = elements.find((node) => node.id == element.destinationId);

    let nodeStartPosition = {
      lat: parseFloat(nodeStart?.elementConfig?.config?.coordenadas?.lat),
      long: parseFloat(nodeStart?.elementConfig?.config?.coordenadas?.long),
      active: false,
    };
    let nodeEndPosition = {
      lat: parseFloat(nodeEnd?.elementConfig?.config?.coordenadas?.lat),
      long: parseFloat(nodeEnd?.elementConfig?.config?.coordenadas?.long),
      active: false,
    };

    let points = [
      nodeStartPosition,
      ...(element.positionHandlers ?? []).map((a) => ({
        lat: a.y,
        long: a.x,
        active: false,
      })),
      nodeEndPosition,
    ];

    return points;
  };

  function map_range(value, low1, high1, low2, high2) {
    return parseInt(low2 + ((high2 - low2) * (value - low1)) / (high1 - low1));
  }

  function zoom(action) {
    if (!mapRef.current) return;
    const map = mapRef.current;
    let zoom = map.getZoom();
    // garante que o mapa não fique deslizando
    if (zoom > 17 && action > 0) return;
    if (zoom < 1 && action < 0) return;
    map.setZoom(zoom + action);
  }
  function focusZoom(id) {
    if (!id) return;
    let element = elements.find((node) => node.id == id);
    if (!element) return;
    let cood = element.elementConfig.config.coordenadas;
    focusZoomCoordenates(cood.lat, cood.long);
  }
  function focusZoomCoordenates(lat, lng) {
    const map = mapRef.current;
    map.flyTo([lat, lng], 14);
  }
  const onNodeClick = (event, node) => {
    const selected = elements?.find(({ id }) => id === node?.id);
    setConnectionSelected(null);
    setEdgeSelected(null);
    if (selected?.id === elementSelected?.id) {
      setElementSelected(null);
      return;
    }
    setElementSelected(JSON.parse(JSON.stringify(selected)));

    let idsElements = [selected?.id];
    let idsConnections = [];
    let elementsConnect = [];

    connections.forEach(({ referenceId, destinationId, id }) => {
      if (node?.id === destinationId || node?.id === referenceId) {
        const findElement = elements?.find(
          ({ id }) =>
            id === (node?.id === destinationId ? referenceId : destinationId)
        );
        elementsConnect.push(findElement);
        idsElements.push(findElement?.id);
        idsConnections.push(id);
      }
    });
    setElementSelected(JSON.parse(JSON.stringify(selected)));
    setElementsConnected(elementsConnect);

    const updateEntangled = (isEntangled) => ({ entangled: isEntangled });

    setOpenRightBar(true);
  };
  const onPaneClick = (e) => {
    setContextMenu(null);

    if (connectionSelected) setConnectionSelected(null);
    if (edgeSelected) setEdgeSelected(null);
    if (elementsConnected) setElementsConnected(null);
    if (elementSelected) setElementSelected(null);

    if (elementSelected || edgeSelected) {
      setEdgeSelected(null);
      //setConnectionSelected(null);
      setElementSelected(null);
    }
  };

  return (
    <>
      <Box sx={{ overflow: "visible", zIndex: 1202 }}>
        <TitleBarPublic
          title={title}
          center={elementSelected != null || connectionSelected != null}
        />
      </Box>
      <Box sx={styles.body}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <ToolBarMapsPublic
            setEasyMapsConfig={setEasyMapsConfig}
            easyMapsConfig={easyMapsConfig}
            fullScreem={fullScreem}
            setFullScreem={setFullScreem}
            showLabel={showLabel}
            setShowLabel={setShowLabel}
            setShowLabelEnlace={setShowLabelEnlace}
            showLabelEnlace={showLabelEnlace}
            tipo={0}
          >
            <ToolBarMapsPublic.InputZoomGeo zoom={zoom} />
          </ToolBarMapsPublic>
          <DescriptionBar
            key={"descrptionbar"}
            elementSelected={!!elementSelected}
          />
          <MapContainer
            maxBoundsViscosity={1}
            key={fullScreem ? "mapaFull" : "mapaSingle"}
            fullscreenControl={false}
            ref={mapRef}
            id="map"
            center={[-10, -48]}
            zoom={4}
            minZoom={3}
            zoomControl={false}
            doubleClickZoom={false}
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: 0,
            }}
          >
            <TileLayer
              //url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              //url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
              //satelite
              //url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              attribution="&copy; OpenStreetMap contributors"
            />

            <Events />
            <SetMaxBounds />
            <SetZoom />
            <LayerGroup>
              {elements.map((element, index) => (
                <NodeIcon
                  easyMapsConfig={easyMapsConfig}
                  key={element.id + "edit" + index}
                  showLabel={showLabel}
                  size={zoomIcons}
                  iconsElement={iconsElements}
                  element={element}
                  color={theme.palette.color.icons}
                  editable={false}
                  onNodeClick={onNodeClick}
                  onNodeContextMenu={() => {}}
                  selected={element.id == elementSelected?.id}
                />
              ))}
            </LayerGroup>

            {connections.map((conn, index) => {
              let position = formatPoints(conn);

              /* linha fora o modo de edição */
              //calcula a porcentagem de utilização

              let capacidade = parseFloat(conn?.config?.capacidade) ?? 0;
              let consumo =
                (parseFloat(conn?.data?.inputTraffic || 0) >
                parseFloat(conn?.data?.outputTraffic || 0)
                  ? parseFloat(conn?.data?.inputTraffic || 0)
                  : parseFloat(conn?.data?.outputTraffic || 0)) / 1000000000 ??
                0;
              let percent = (consumo / capacidade) * 100;

              let elemntOrigin = elements.find((a) => a.id == conn.referenceId);
              let elemntOriginVerify = false;
              let hostId = "null";

              if (conn?.config?.hostOriginId) {
                hostId = conn?.config?.hostOriginId;
              } else {
                hostId =
                  Array.isArray(conn?.item) && conn?.item?.length > 0
                    ? conn?.item[0]?.hostOriginId
                    : "null";
              }

              elemntOriginVerify = Boolean(
                (elemntOrigin.data?.ping.find((a) => a.hostid == hostId)
                  ?.lastvalue ||
                  elemntOrigin.data?.snmp?.find((a) => a.hostid == hostId)
                    ?.snmp_available) &&
                  conn?.data?.status
              );

              return (
                <PolylineCustom
                  editMode={false}
                  easyMapsConfig={easyMapsConfig}
                  host={
                    elements.find((ele) => ele.id == conn.referenceId)?.titulo
                  }
                  data={conn?.data}
                  percent={conn?.data?.status ? percent : null}
                  isDown={!elemntOriginVerify}
                  key={conn.id + "selected" + index}
                  positions={position.map((a) => [a.lat || 0, a.long || 0])}
                  animation={conn?.config?.animation ?? "desativada"}
                  onClick={(e) => {
                    let elemento = elements.find(
                      (ele) => ele.id == conn.referenceId
                    );

                    setElementSelected(elemento);
                    setOpenConnection(conn.id);
                  }}
                />
              );
            })}
          </MapContainer>

          <RightBarEasyMaps
            setFocusNodeId={focusZoom}
            open={openRightBar}
            setOpen={setOpenRightBar}
            elements={elements}
            //connections={elementsConnected}
            connections={connections}
            setElement={setElementSelected}
            setConnections={setElementsConnected}
            iconsElement={iconsElements}
            element={elementSelected?.id ? elementSelected : null}
            fullScreem={fullScreem}
            openConnection={openConnection}
            setOpenConnection={setOpenConnection}
            link={`/easyMaps/public/${
              elementSelected?.mapRef?.tipo === 0 ? "geo" : "top"
            }/${elementSelected?.mapRefId ?? ""}/${token}`}
          />
        </Box>
      </Box>
    </>
  );
});

export default LeafletMapa;
