import { WidthFullTwoTone } from "@mui/icons-material";
import {
  Paper,
  useTheme,
  Box,
  Typography,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as TitleBarSVG } from "../../../assets/easyMaps/titlebar.svg";
import { useState, useRef, useEffect } from "react";
import * as React from "react";
import AlertModal from "../../../componentes/alertModal";
import { useNavigate } from "react-router-dom";

const TitleBarPublic = ({ title, center }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [positionCenter, setPositionCenter] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [titleMap, setTitleMap] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [up, setUp] = useState(false);
  const inputRef = useRef();
  const barRef = useRef(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const titleMapRef = useRef();
  const upRef = useRef();
  useEffect(() => {
    titleMapRef.current = titleMap;
    upRef.current = up;
  }, [titleMap, up]);

  useEffect(() => {
    setTitleMap(title);
  }, [title]);
  useEffect(() => {
    if (editMode) inputRef.current.focus();
  }, [editMode]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    setAnchorEl(null);
    setEditMode(true);
  };
  const DeleteModal = (props) => <AlertModal {...props} />;
  function update() {
    let position = window.innerWidth - 690;
    setPositionCenter(position / 2 - 160);
  }
  useEffect(() => {
    update();
  }, [center]);

  const styles = {
    paper: {
      height: "auto",
      width: "320px",
      fill: theme.palette.color.appBarEasyMaps.backgroundColor,
    },
    box: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "360px",
      position: "absolute",
      left: 0,
      right: 0,
      background: "none",
      marginLeft: "auto",
      marginRight: "auto",
      zIndex: 1,
    },
    stack: {
      mt: "-60px",
      justifyContent: "center",
      alignItems: "center",
      gap: 1,
    },
  };

  return (
    <Box sx={styles.box} ref={barRef}>
      {/* <TitleBarSVG style={styles.paper} /> */}
      <Stack direction="row" sx={styles.stack}>
        <Typography sx={styles.text}>{titleMap}</Typography>
      </Stack>
    </Box>
  );
};

export default TitleBarPublic;
