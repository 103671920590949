import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Avatar,
  DialogTitle,
  Modal,
  Tooltip,
  DialogContent,
  Grid2,
  useTheme,
} from "@mui/material";
import { Person, Business, ModeEdit } from "@mui/icons-material";
import "dayjs/locale/pt-br";
import api from "../../services/api";
import ImageButton from "../../componentes/imageButton";
import { LoadingButton } from "@mui/lab";
import useStyles from "./styles";
import {
  maskCnpj,
  maskCPF,
  maskPhone,
  removeMask,
} from "../../utils/maskFields";
import { useNotification } from "../../componentes/notificationProvider";
import { validarCpf } from "../../utils/validateCpf";
import { validarCnpj } from "../../utils/validateCnpj";

export default function Profile(props) {
  const { openModal, setOpenModal } = props;
  const [email, setEmail] = useState("");
  const [editar, setEditar] = useState(false);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [foto, setFoto] = useState("");
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [telefone, setTelefone] = useState("");
  const [pessoaFisica, setPessoaFisica] = useState(true);
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [dataDeNascimento, setDataDeNascimento] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  const notify = useNotification();
  const handleSubmit = () => {
    setIsSubmit(true);
    let data = {};
    if (pessoaFisica) {
      if (
        !nome ||
        !sobrenome ||
        !validarCpf(cpf) ||
        !dataDeNascimento ||
        !telefone
      )
        return;
      data = {
        nome,
        sobrenome,
        dataDeNascimento,
        cpf: removeMask(cpf),
        telefone: removeMask(telefone),
        foto,
      };
    }

    if (!pessoaFisica) {
      if (!nomeFantasia || !validarCnpj(cnpj) || !telefone) return;
      data = {
        nomeFantasia,
        cnpj: removeMask(cnpj),
        email,
        telefone: removeMask(telefone),
        foto,
      };
    }

    handleApi(data);
  };
  async function handleApi(data) {
    try {
      setLoading(true);
      const response = await api.put("/perfil/update", {
        ...data,
        // foto,
        // nome,
        // sobrenome,
        // cpf: removeMask(cpf),
        // cnpj: removeMask(cnpj),
        // telefone: removeMask(telefone),
        // dataDeNascimento,
        // nomeFantasia,
        // razaoSocial,
        // pessoaFisica,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });
      if (response.data.status === "Error") {
        notify("Não foi possível salvar dados", "error");
      } else {
        notify("Dados salvos com sucesso", "success");

        localStorage.setItem("perfil", JSON.stringify(response.data));
        handleUsuarioId();
      }
    } catch (error) {
      console.error();
      notify("Não foi possível se conectar com o servidor", "error");
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    if (!openModal) {
      setEditar(false);
    }
    handleUsuarioId();
  }, [openModal, editar]);

  const handleUsuarioId = () => {
    let result = localStorage.getItem("perfil");
    let resultUserData = localStorage.getItem("1");
    result = JSON.parse(result);
    if (result) {
      resultUserData = JSON.parse(resultUserData);
      setNome(result.nome);
      setSobrenome(result.sobrenome);
      setDataDeNascimento(new Date(result.dataDeNascimento));
      setFoto(result.foto);
      setCpf(maskCPF(result.cpf));
      setTelefone(maskPhone(result.telefone));
      setCnpj(maskCnpj(result.cnpj));
      setEmail(resultUserData.usuarioCliente.email);
      setPessoaFisica(result.pessoaFisica);
      setNomeFantasia(result.nomeFantasia);
    }
  };
  return (
    <>
      <Modal
        open={openModal}
        closeAfterTransition
        onClose={() => {
          setOpenModal(false);
        }}
        sx={styles.modal}
      >
        <Paper variant="outlined" sx={styles.paper}>
          <DialogTitle sx={{ padding: 0 }}>
            <Box sx={styles.paperPreview}>
              <Box sx={styles.boxCover}></Box>
              <Box sx={styles.boxLoginAndButton}>
                {editar ? (
                  <Box sx={styles.boxButtons}>
                    <Button
                      //disabled={loading}
                      onClick={() => {
                        setEditar(false);
                      }}
                    >
                      Cancelar
                    </Button>
                    <LoadingButton
                      variant="contained"
                      sx={styles.loadingButton}
                      loading={loading}
                      onClick={handleSubmit}
                    >
                      Salvar
                    </LoadingButton>
                  </Box>
                ) : (
                  <>
                    {/* <Typography sx={styles.login}>{login}</Typography> */}
                    <Button
                      startIcon={<ModeEdit />}
                      variant="contained"
                      component="label"
                      size="small"
                      sx={styles.buttonEdit}
                      onClick={() => setEditar(true)}
                    >
                      Editar
                    </Button>
                  </>
                )}
              </Box>
              <Box sx={styles.boxAvatar}>
                {!editar ? (
                  <>
                    <Avatar src={foto} color="secondary" sx={styles.avatar} />{" "}
                    <Tooltip
                      title={pessoaFisica ? "pessoa física" : "pessoa jurídica"}
                    >
                      <Box
                        variant="contained"
                        component="label"
                        sx={styles.boxIcon}
                      >
                        {pessoaFisica ? <Person /> : <Business />}
                      </Box>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Avatar src={foto} color="secondary" sx={styles.avatar} />{" "}
                    <ImageButton
                      component="label"
                      size="small"
                      setFoto={setFoto}
                      sx={styles.imageButton}
                    />
                  </>
                )}
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid2 container columnSpacing={1.5} rowSpacing={2}>
              {pessoaFisica && editar && (
                <Grid2 item size={{ xs: 12, sm: 12 }}>
                  <Box sx={styles.boxRowFields}>
                    <Typography sx={styles.subtitle}>Nome</Typography>{" "}
                    <TextField
                      id="nome"
                      placeholder="Insira seu nome"
                      value={nome}
                      sx={styles.TextField}
                      onChange={(event) => {
                        setNome(event.target.value);
                      }}
                      error={isSubmit && !nome}
                      helperText={isSubmit && !nome ? "Campo obrigatório" : ""}
                    />
                  </Box>
                </Grid2>
              )}
              {pessoaFisica && !editar && (
                <Grid2 item size={{ xs: 12, sm: 6 }}>
                  <Box sx={styles.boxRowFields}>
                    <Typography variant="h5" sx={styles.paragraph}>
                      {nome}
                    </Typography>
                  </Box>
                </Grid2>
              )}
              {pessoaFisica && !editar && (
                <Grid2 item size={{ xs: 12, sm: 6 }}>
                  <Box sx={styles.boxRowFields}>
                    <Typography variant="h5" sx={styles.paragraph}>
                      {sobrenome}
                    </Typography>
                  </Box>
                </Grid2>
              )}
            </Grid2>
          </DialogContent>
        </Paper>
      </Modal>
    </>
  );
}
