import {
  Add,
  ArrowDropDown,
  BackspaceOutlined,
  CancelOutlined,
  Close,
  EventNote,
  FilterAltOutlined,
  Key,
  KeyOutlined,
  Person,
  Remove,
  RemoveCircle,
  Verified,
} from "@mui/icons-material";
import {
  alpha,
  Badge,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Typography,
  useTheme,
  TextField,
  InputAdornment,
  Tooltip,
  Stack,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import {
  DateCalendar,
  LocalizationProvider,
  MobileDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { ptBR as ptBRdatePicker } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { useMemo, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import useStyles from "./styles";
import { styles } from "../../easyReport/cardAgendamento/styles";

const ModalDate = ({ anchorEl, setAnchorEl, ...props }) => {
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Popover
      id={"modal-data"}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPopover-paper": {
          boxShadow: "0px 4px 14px 0px  #0000002c",
        },
      }}
    >
      <>
        {
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale={"pt-br"}
          >
            <DateCalendar
              value={props.value ? dayjs(props.value) : new Date(null)}
              {...props}
            />
          </LocalizationProvider>
        }
        <Box sx={styles.boxButtonModalDate}>
          <Button
            sx={styles.buttonModalDate}
            disabled={!props.value}
            color="error"
            startIcon={<BackspaceOutlined />}
            onClick={() => {
              props.handleClear();
              handleClose();
            }}
          >
            Limpar
          </Button>
        </Box>
      </>
    </Popover>
  );
};

const ModalPermission = ({ anchorEl, setAnchorEl, setPermissionStatus }) => {
  const handleClose = (status) => {
    setAnchorEl(null);
    if (status) setPermissionStatus(status);
  };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => handleClose(null)}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={() => handleClose({ value: "", label: "" })}>
        Nenhum
      </MenuItem>
      <MenuItem
        onClick={() => handleClose({ value: "adm002", label: "Admin" })}
      >
        Admin
      </MenuItem>
      <MenuItem
        onClick={() => handleClose({ value: "adm001", label: "Super Admin" })}
      >
        Super Admin
      </MenuItem>
      <MenuItem
        onClick={() => handleClose({ value: "user001", label: "Usuário" })}
      >
        Usuário
      </MenuItem>
    </Menu>
  );
};

const ModalPersonType = ({ anchorEl, setAnchorEl, setPersonTypeStatus }) => {
  const handleClose = (status) => {
    setAnchorEl(null);
    if (status) setPersonTypeStatus(status);
  };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => handleClose(null)}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={() => handleClose({ value: "", label: "" })}>
        Nenhum
      </MenuItem>
      <MenuItem onClick={() => handleClose({ value: true, label: " Física" })}>
        Pessoa Física
      </MenuItem>
      <MenuItem
        onClick={() => handleClose({ value: false, label: " Jurídica" })}
      >
        Pessoa Jurídica
      </MenuItem>
    </Menu>
  );
};

const ModalVerify = ({ anchorEl, setAnchorEl, setVerifiedStatus }) => {
  const handleClose = (status) => {
    setAnchorEl(null);
    if (status) setVerifiedStatus(status);
  };
  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => handleClose(null)}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={() => handleClose({ value: "", label: "" })}>
        Nenhum
      </MenuItem>
      <MenuItem
        sx={{ width: "100px" }}
        onClick={() =>
          handleClose({
            value: true,
            label: "Sim",
          })
        }
      >
        Sim
      </MenuItem>
      <MenuItem
        onClick={() =>
          handleClose({
            value: false,
            label: "Não",
          })
        }
      >
        Não
      </MenuItem>
    </Menu>
  );
};

const ModalFiltersMobile = (props) => {
  const {
    openModal,
    setOpenMobileModal = () => {},
    verifyStatus = { label: "", value: "" },
    setVerifyStatus = () => {},
    permissionStatus = { label: "", value: "" },
    setPermissionStatus = () => {},
    personTypeStatus = { label: "", value: "" },
    setPersonTypeStatus = () => {},
    criadoEm,
    setCriadoEm = () => {},
  } = props;
  const theme = useTheme();
  const styles = useStyles(theme);
  const handleClose = () => {
    setOpenMobileModal(false);
    setPermissionStatus({ label: "", value: "" });
    setPersonTypeStatus({ label: "", value: "" });
    setVerifyStatus({ label: "", value: "" });
    setCriadoEm(null);
  };

  const handleClearFilters = () => {
    setPermissionStatus({ label: "", value: "" });
    setPersonTypeStatus({ label: "", value: "" });
    setVerifyStatus({ label: "", value: "" });
    setCriadoEm(null);
  };
  return (
    <Modal open={openModal} onClose={handleClose} sx={styles.modal}>
      <Paper sx={styles.paper}>
        <DialogTitle color="primary" sx={styles.dialogTitle}>
          <Box sx={styles.boxHeader}>
            <IconButton size="small">
              <FilterAltOutlined sx={{ color: "primary.main" }} />
            </IconButton>
            <Typography fontWeight={500}>Filtros:</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Grid2 container columnSpacing={1.5} rowSpacing={2}>
            <Grid2 item size={{ xs: 12, sm: 6 }}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.labels}>Verificado</Typography>
                <TextField
                  select
                  value={verifyStatus.value}
                  onChange={(e) => setVerifyStatus({ value: e.target.value })}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small" edge="start">
                            <Verified sx={styles.iconTextFields} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                >
                  <MenuItem value="" label="">
                    Nenhum
                  </MenuItem>
                  <MenuItem value={true} label="Sim">
                    Sim
                  </MenuItem>
                  <MenuItem value={false} label="Não">
                    Não
                  </MenuItem>
                </TextField>
              </Box>
            </Grid2>
            <Grid2 item size={{ xs: 12, sm: 6 }}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.labels}>Permissão</Typography>
                <TextField
                  select
                  value={permissionStatus.value}
                  onChange={(e) =>
                    setPermissionStatus({ value: e.target.value })
                  }
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small" edge="start">
                            <KeyOutlined sx={styles.iconTextFields} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                >
                  <MenuItem value="" label="">
                    Nenhum
                  </MenuItem>
                  <MenuItem value={"adm001"}>Super Admin</MenuItem>
                  <MenuItem value={"adm002"}>Admin</MenuItem>
                  <MenuItem value={"user001"}>Usuário</MenuItem>
                </TextField>
              </Box>
            </Grid2>
            <Grid2 item size={{ xs: 12, sm: 6 }}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.labels}>Criado em</Typography>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  localeText={
                    ptBRdatePicker.components.MuiLocalizationProvider
                      .defaultProps.localeText
                  }
                  adapterLocale={"pt-br"}
                >
                  <MobileDatePicker
                    value={criadoEm || null}
                    onChange={(newValue) => {
                      if (newValue) setCriadoEm(dayjs(newValue));
                      else setCriadoEm(null);
                    }}
                    slotProps={{
                      textField: {
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarMonthIcon sx={styles.iconTextFields} />
                            </InputAdornment>
                          ),
                        },
                      },

                      actionBar: {
                        actions: [
                          <Box sx={styles.boxButtonModalDate}>
                            <Button
                              sx={styles.buttonModalDate}
                              disabled={!criadoEm}
                              color="error"
                              startIcon={<BackspaceOutlined />}
                              onClick={() => {
                                setCriadoEm(null);
                                handleClose();
                              }}
                            >
                              Limpar
                            </Button>
                          </Box>,
                        ],
                      },
                      actionBar: { actions: ["clear", "accept"] },
                    }}
                  />
                  {/* <Box sx={styles.boxButtonModalDate}>
                    <Button
                      sx={styles.buttonModalDate}
                      disabled={!criadoEm}
                      color="error"
                      startIcon={<BackspaceOutlined />}
                      onClick={() => {
                        setCriadoEm(null);
                        handleClose();
                      }}
                    >
                      Limpar
                    </Button>
                  </Box> */}
                </LocalizationProvider>
              </Box>
            </Grid2>
            <Grid2 item size={{ xs: 12, sm: 6 }}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.labels}>Pessoa</Typography>
                <TextField
                  select
                  value={personTypeStatus.value}
                  onChange={(e) =>
                    setPersonTypeStatus({ value: e.target.value })
                  }
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton size="small" edge="start">
                            <Person sx={styles.iconTextFields} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                >
                  <MenuItem value="" label="">
                    Nenhum
                  </MenuItem>
                  <MenuItem value={true}>Física</MenuItem>
                  <MenuItem value={false}>Jurídica</MenuItem>
                </TextField>
              </Box>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button
            onClick={() => {
              handleClearFilters();
            }}
          >
            Limpar Filtros
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenMobileModal(false);
            }}
            sx={styles.button}
          >
            Aplicar
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
};

export default function Filters(props) {
  const {
    verifyStatus,
    setVerifyStatus,
    permissionStatus,
    setPermissionStatus,
    personTypeStatus,
    setPersonTypeStatus,
    criadoEm,
    setCriadoEm,
    handleNewOpen,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [anchorElVerify, setAnchorElVerify] = useState(null);
  const [anchorElPermission, setAnchorElPermission] = useState(null);
  const [openMobileModal, setOpenMobileModal] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);

  const handleClick = (event, filterType) => {
    if (filterType === "verify") {
      setAnchorElVerify(event.currentTarget);
    } else if (filterType === "permission") {
      setAnchorElPermission(event.currentTarget);
    } else if (filterType === "date") {
      setAnchorElDate(event.currentTarget);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleCleanFilters = () => {
    setCriadoEm("");
    setPermissionStatus({
      value: "",
      label: "",
    });
    setPersonTypeStatus({ value: "", label: "" });
    setVerifyStatus({
      value: "",
      label: "",
    });
  };
  const formatteDate = (date) => {
    return dayjs(date).locale("pt-br").format("D [de] MMM, YYYY");
  };

  const filtersCount = useMemo(() => {
    let filtersCount = 0;
    if (verifyStatus.value.toString()) {
      filtersCount += 1;
    }
    if (permissionStatus.value.toString()) {
      filtersCount += 1;
    }
    if (personTypeStatus.value.toString()) {
      filtersCount += 1;
    }
    if (criadoEm) {
      filtersCount += 1;
    }
    return filtersCount;
  }, [openMobileModal]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: { xs: "fit-content", md: "100%" },
          justifyContent: "space-between",
        }}
      >
        <Box sx={styles.backgroundBox}>
          <Grid2
            container
            rowSpacing={1}
            spacing={1}
            sx={styles.gridItemDesktop}
          >
            <Grid2 item size={styles.sizeGridItem}>
              <Box sx={styles.boxTitle}>
                <FilterAltOutlined sx={styles.size} />
                <Typography sx={styles.text}>{"Filtros:"}</Typography>
              </Box>
            </Grid2>

            <Grid2 item size={styles.sizeGridItem}>
              <Paper
                variant="brick"
                sx={styles.paperBrick}
                onClick={(e) => handleClick(e, "verify")}
              >
                <Verified sx={styles.size} />
                <Typography
                  sx={styles.text}
                >{`Verificado: ${verifyStatus?.label} `}</Typography>
                <ArrowDropDown sx={styles.size} />
              </Paper>
              <ModalVerify
                anchorEl={anchorElVerify}
                setAnchorEl={setAnchorElVerify}
                setVerifiedStatus={setVerifyStatus}
              />
            </Grid2>

            <Grid2 item size={styles.sizeGridItem}>
              <Paper
                variant="brick"
                sx={styles.paperBrick}
                onClick={(e) => handleClick(e, "permission")}
              >
                <Key sx={styles.size} />
                <Typography
                  sx={styles.text}
                >{`Permissão: ${permissionStatus?.label}`}</Typography>
                <ArrowDropDown sx={styles.size} />
              </Paper>
              <ModalPermission
                anchorEl={anchorElPermission}
                setAnchorEl={setAnchorElPermission}
                setPermissionStatus={setPermissionStatus}
              />
            </Grid2>

            <Grid2 item size={styles.sizeGridItem}>
              <Paper
                variant="brick"
                sx={styles.paperBrick}
                onClick={(e) => handleClick(e, "date")}
              >
                <EventNote sx={styles.size} />
                <Typography sx={styles.text}>
                  {`Criado em: ${!!criadoEm ? formatteDate(criadoEm) : ""}`}
                </Typography>

                <ArrowDropDown sx={styles.size} />
              </Paper>
              <ModalDate
                anchorEl={anchorElDate}
                setAnchorEl={setAnchorElDate}
                value={criadoEm || null}
                handleClear={() => setCriadoEm(null)}
                onChange={(newValue) => setCriadoEm(dayjs(newValue))}
              />
            </Grid2>

            <Grid2 item size={styles.sizeGridItem}>
              <Paper
                variant="brick"
                sx={styles.paperBrick}
                onClick={(e) => handleClick(e)}
              >
                <Person sx={styles.size} />
                <Typography
                  sx={styles.text}
                >{`Pessoa: ${personTypeStatus?.label}`}</Typography>
                <ArrowDropDown sx={styles.size} />
              </Paper>
              <ModalPersonType
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                setPersonTypeStatus={setPersonTypeStatus}
              />
            </Grid2>

            {verifyStatus.label ||
            permissionStatus.label ||
            personTypeStatus.label ||
            criadoEm ? (
              <Grid2 item size={styles.sizeGridItem}>
                <Box sx={styles.boxTitle}>
                  <Button
                    sx={styles.buttonClearFilters}
                    startIcon={<Close />}
                    onClick={handleCleanFilters}
                  >
                    Limpar Filtros
                  </Button>
                </Box>
              </Grid2>
            ) : null}
          </Grid2>
        </Box>
        <Grid2 item size={styles.sizeGridItem}>
          <Button
            startIcon={<Add />}
            onClick={handleNewOpen}
            sx={styles.button}
            variant="contained"
          >
            Novo Usuário
          </Button>
        </Grid2>
      </Box>
      <ModalFiltersMobile
        openModal={openMobileModal}
        setOpenMobileModal={setOpenMobileModal}
        verifyStatus={verifyStatus}
        setVerifyStatus={setVerifyStatus}
        permissionStatus={permissionStatus}
        setPermissionStatus={setPermissionStatus}
        personTypeStatus={personTypeStatus}
        setPersonTypeStatus={setPersonTypeStatus}
        criadoEm={criadoEm}
        setCriadoEm={setCriadoEm}
      />
      <Tooltip title="Adicionar filtros">
        <Button
          sx={styles.buttonFilterMobile}
          onClick={() => setOpenMobileModal(true)}
          variant="contained"
        >
          <FilterAltOutlined sx={styles.size} />
          <Badge badgeContent={filtersCount} sx={styles.badge} />
        </Button>
      </Tooltip>
    </>
  );
}
