import { Box, Snackbar, Alert } from "@mui/material";

import { useEffect, useState } from "react";
import HostsCadastrados from "./hostsCadastrados";
import api from "../../services/api";
import HostsArquivados from "./hostsArquivados";
import HostsPreCadastros from "./hostsPreCadastros";

const styles = {
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    left: 0,
    right: 0,
    mr: "auto",
    ml: "auto",
    //mt: "30px",
  },
  paper: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  },
  box: { width: "100%" },
  titles: { fontWeight: 500 },
};

export default function EasyAdd({ socket }) {
  const [tabValue, setTabValue] = useState(0);
  const [snackbar, setSnackbar] = useState(null);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingPredefinicoes, setLoadingPredefinicoes] = useState(true);
  const [proxys, setProxys] = useState([]);
  const [predefinicoes, setPredefinicoes] = useState([]);
  const [hosts, setHosts] = useState([]);
  const [hostsZabbix, setHostsZabbix] = useState([]);
  const [loadingHosts, setLoadingHosts] = useState(true);

  useEffect(() => {
    if (tabValue === 0) {
      handleApiGetHostsZabbix();
      handleApiGetHostsPreCadastrados();
      handleApiGetPredefinicoes();
    }
  }, [tabValue]);
  async function handleApiGetPredefinicoes() {
    try {
      setLoadingPredefinicoes(true);
      const response = await api.get("/predefinicoes/findMany");

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível buscar os dados",
          severity: "error",
        });
        setLoadingPredefinicoes(false);
      } else {
        if (Array.isArray(response.data)) {
          setPredefinicoes(response.data || []);
        }
        setLoadingPredefinicoes(false);
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
      setLoadingPredefinicoes(false);
      console.error(error);
    }
  }
  async function handleApiGetHostsZabbix() {
    try {
      setLoadingPage(true);
      const response = await api.get("/easyAdd/findMany");

      if (response.data.status === "Error") {
        console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possível carregar dados",
          severity: "error",
        });
        setLoadingPage(false);
      } else {
        setHostsZabbix(response.data.hosts);
        setProxys(response.data.proxys);

        setSnackbar({
          children: "Dados carregados com sucesso",
          severity: "success",
        });
        setLoadingPage(false);

        /*   updateRow({
            ...response.data,
            numero: selectedRow.numero,
          });*/
      }
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });

      console.error(error);
      setLoadingPage(false);
    }
  }
  async function handleApiGetHostsPreCadastrados() {
    try {
      setLoadingHosts(true);
      const response = await api.get("/easyAdd/hosts/findMany");
      if (response.data) {
        setHosts(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setLoadingHosts(false);
    }
  }

  //atualizar os hosts via socket
  useEffect(() => {
    function setChangeTablePreCadastro(response) {
      setHosts((prevHosts) => {
        const hostsMap = new Map(prevHosts.map((host) => [host.id, host]));
        response?.data?.forEach((updateHost) => {
          hostsMap.set(updateHost.id, updateHost);
        });
        return Array.from(hostsMap.values());
      });
    }
    function setDeleteHostsTablePreCadastro(response) {
      setHosts((prevHosts) => {
        // logica para deletar hosts
        const deletedHostIds = response?.data?.map(
          (deletedHost) => deletedHost.id
        );
        return prevHosts.filter((host) => !deletedHostIds.includes(host.id));
      });
      //handleApiGetHostsZabbix();
    }
    socket.on("onChangePreCadastro", setChangeTablePreCadastro);
    socket.on("deleteChangePreCadastro", setDeleteHostsTablePreCadastro);

    // Limpa o listener quando o componente desmonta ou dependências mudam
    return () => {
      socket.off("onChangePreCadastro", setChangeTablePreCadastro);
      socket.off("deleteChangePreCadastro", setDeleteHostsTablePreCadastro);
    };
  }, []);

  return (
    <>
      <Box sx={styles.container}>
        <HostsPreCadastros
          hosts={hosts.filter(({ status }) => status === 0)}
          setHosts={setHosts}
          hostsZabbix={hostsZabbix}
          loadingPredefinicoes={loadingPredefinicoes}
          predefinicoes={predefinicoes}
          proxys={proxys}
          setSnackbar={setSnackbar}
          setTabValue={setTabValue}
          loading={loadingHosts}
          key={3}
        />{" "}
        <HostsCadastrados
          loadingPage={loadingPage}
          loadingPredefinicoes={loadingPredefinicoes}
          predefinicoes={predefinicoes}
          rows={hostsZabbix}
          proxys={proxys}
          setRows={setHostsZabbix}
          //handleApiGetHosts={handleApiGetHosts}
          key={2}
        />
        <HostsArquivados
          hosts={hosts.filter(({ status }) => status === 1)}
          setHosts={setHosts}
          loadingPage={loadingPage}
          loadingPredefinicoes={loadingPredefinicoes}
          predefinicoes={predefinicoes}
          proxys={proxys}
          loading={loadingHosts}
          setSnackbar={setSnackbar}
          //handleApiGetHosts={handleApiGetHosts}
          key={1}
        />
      </Box>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
