export const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: "min-content",
    maxWidth: "400px",
    width: "100%",
    position: "relative",
    borderRadius: "5px",
    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
    padding: "16px 12px 16px 28px",
    borderLeft: "8px solid #FB7500",
    backgroundColor: "background.card",
  },
  cardAction: {
    display: "flex",
    gap: "12px",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    height: "178px",
  },
  title: {
    color: "text.primary",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "100%",
    letterSpacing: "0.25px",
  },
  subtitle: {
    display: "flex",
    padding: "4px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "background.textField",
    borderRadius: "5px",
    color: "text.secondary",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "12px",
    letterSpacing: "0.15px",
    gap: "10px",
  },
  containerItens: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "39px",
    gap: "8px",
  },
  containerDate: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  containerTitles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
  },
  itens: {
    color: "text.primary",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    lineHeight: "16px",
    letterSpacing: "0.15px",
    textWrap: "nowrap",
  },
  dateTitle: {
    color: "text.secondary",
    fontFamily: "Roboto",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "700",
    lineHeight: "13px",
    letterSpacing: "0.15px",
  },
  date: {
    paddingTop: "4px",
    color: "text.primary",
    fontFamily: "Roboto",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "14px",
    letterSpacing: "0.15px",
  },
  boxButtonModalDate: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "end",
  },
  buttonModalDate: { marginX: 2, mt: -3, mb: 2 },
};
