import { ArrowBack } from "@mui/icons-material";
import { Button, Paper, Stack, Typography } from "@mui/material";
import notFoundIcon from "../../../../assets/easyMaps/notFoundIcon.svg";
import { useNavigate } from "react-router-dom";

export default function NotFoundMaps() {
  const styles = {
    container: {
      width: "100%",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      minWidth: "500px",
      maxWidth: "900px",
      width: "80%",
      height: "100%",
      padding: "20px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      mt: "100px",
      flexWrap: "wrap",
    },
  };
  const navigate = useNavigate();
  const handleVoltar = () => {
    navigate("/easyMaps"); // Redireciona para "/home" se não houver página anterior
  };
  return (
    <Stack sx={styles.container}>
      <Paper sx={styles.paper}>
        <Stack spacing={4} width={"50%"} justifyContent="center" height="100%">
          <Stack spacing={0}>
            <Typography variant="h2" color="primary" fontWeight={800}>
              Sinto Muito!
            </Typography>
            <Typography variant="h6" fontWeight={400}>
              Não foi possível encontrar este mapa
            </Typography>
          </Stack>
          <Button
            startIcon={<ArrowBack />}
            sx={{ width: "150px" }}
            variant="contained"
            onClick={handleVoltar}
          >
            voltar
          </Button>
        </Stack>
        <Stack spacing={2}>
          {" "}
          <img src={notFoundIcon} width="300px" />
        </Stack>
      </Paper>
    </Stack>
  );
}
