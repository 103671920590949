import { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { convertBitsToBytes } from "../../../../../utils/easyMaps/convertToBytes";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import L from "leaflet";
import * as ReactDOMServer from "react-dom/server";

export function PolylineCustom(
  {
    host = "",
    data = null,
    positions,
    animation = "desativada",
    onClick = () => {},
    dbClick = () => {},
    percent = null,
    isDown = false,
    easyMapsConfig,
  },
  key
) {
  const theme = useTheme();
  const styles = {
    fontPopup: {
      margin: "0px",
      fontSize: "10px",
      fontWeight: 500,
      color: theme.palette.color.textEasyMaps,
    },
    titlePopup: {
      margin: "0px",
      fontSize: "12px",
      fontWeight: 500,
      color: theme.palette.color.textEasyMaps,
      width: "100%",
      textAlign: "center",
      marginBottom: "10px",
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limite de 2 linhas
      WebkitBoxOrient: "vertical",
    },
  };
  let animationOp = {
    pontilhada: "1, 10",
    tracejada: "8, 13",
    desativada: null,
  };

  const cor = () => {
    if (percent == null || percent == 0 || percent === Infinity)
      return theme.palette.enlaces.offline.main;
    if (percent < 1) return theme.palette.enlaces.p0.main;
    if (percent < 5) return theme.palette.enlaces.p1.main;
    if (percent < 15) return theme.palette.enlaces.p5.main;
    if (percent < 25) return theme.palette.enlaces.p15.main;
    if (percent < 50) return theme.palette.enlaces.p25.main;
    if (percent < 70) return theme.palette.enlaces.p50.main;
    if (percent < 80) return theme.palette.enlaces.p70.main;
    if (percent < 90) return theme.palette.enlaces.p80.main;
    return theme.palette.enlaces.p90.main;
  };

  const map = useMap();
  const outPutTrafficData = convertBitsToBytes(data?.outputTraffic);
  const inPutTrafficData = convertBitsToBytes(data?.inputTraffic);

  const Content = () => (
    <Stack
      key={key}
      style={{
        width: "150px",
      }}
    >
      <Typography style={styles.titlePopup}>{host}</Typography>
      <Box
        style={{
          display: "flex",
          flexDiretion: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography style={styles.fontPopup}>TX:</Typography>
        <Typography style={styles.fontPopup}>
          {outPutTrafficData.valor + " " + outPutTrafficData.unidade}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDiretion: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography style={styles.fontPopup}>RX:</Typography>
        <Typography style={styles.fontPopup}>
          {inPutTrafficData.valor + " " + inPutTrafficData.unidade}
        </Typography>
      </Box>
    </Stack>
  );

  //var popup = L.popup();
  var popup = L.tooltip([0, 0], {
    permanent: false,
    direction: "top",
    offset: [0, -5],
    opacity: 0.9,
  });

  useEffect(() => {
    // Criação das linhas principais
    const line = L.polyline(positions, {
      color: cor(),
      weight: 3,
      dashArray: animationOp[animation],
      renderer: L.svg(),
    }).addTo(map);

    const lineBase = L.polyline(positions, {
      color: "transparent",
      weight: 5,
      renderer: L.svg(),
    }).addTo(map);

    // Configuração do popup

    popup.setContent(ReactDOMServer.renderToString(<Content />));

    // Event listeners
    lineBase.on("click", onClick);
    lineBase.on("dblclick", dbClick);
    lineBase.on("mouseover mousemove", (e) => {
      popup.setLatLng(e.latlng).openOn(map);
    });
    lineBase.on("mouseout mouseleave", () => {
      map.removeLayer(popup);
    });

    // Animação
    let offset = 0;
    let currentColors = cor();
    let lastChangeTime = 0; // Marca de tempo para mudanças de cor
    let animationFrameId; // Identificador da animação

    const animateDown = (timestamp) => {
      if (timestamp - lastChangeTime > 400) {
        currentColors =
          currentColors !== theme.palette.enlaces.p90.main
            ? theme.palette.enlaces.p90.main
            : theme.palette.enlaces.offline.main;
        lastChangeTime = timestamp;
      }
      line.setStyle({ color: currentColors });
      animationFrameId = requestAnimationFrame(animateDown);
    };

    const animate = () => {
      offset += 0.5;
      line.setStyle({ dashOffset: offset });
      animationFrameId = requestAnimationFrame(animate);
    };

    // Controle da animação
    if (isDown) {
      animationFrameId = requestAnimationFrame(animateDown);
    } else if (
      animationOp[animation] !== null &&
      easyMapsConfig?.animacao?.linha !== false
    ) {
      animationFrameId = requestAnimationFrame(animate);
    }

    // Cleanup
    return () => {
      map.removeLayer(lineBase);
      map.removeLayer(line);
      map.removeLayer(popup);

      // Remove listeners
      lineBase.off("click", onClick);
      lineBase.off("dblclick", dbClick);
      lineBase.off("mouseover mousemove");
      lineBase.off("mouseout mouseleave");

      // Cancela animações
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    };
  }, [
    key,
    map,
    positions,
    animationOp,
    animation,
    onClick,
    dbClick,
    easyMapsConfig,
    theme,
    percent,
  ]);

  return null;
}

export function PolylineSimple(
  { positions = [], dbClick = () => {}, color = null, onClick = () => {} },
  key
) {
  const theme = useTheme();
  const map = useMap();

  useEffect(() => {
    const line = L.polyline(positions, {
      color: color ? color : theme.palette.enlaces.offline.main,
      weight: 3,
    }).addTo(map);
    line.on("dblclick", dbClick);
    line.on("click", onClick);

    return () => {
      map.removeLayer(line);
      line.off("click", onClick);
      line.off("dblclick", dbClick);
    };
  }, [positions, theme, dbClick, key]);

  return null;
}
