import {
  Divider,
  Box,
  Paper,
  Stack,
  Typography,
  useTheme,
  LinearProgress,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tooltip,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { convertBitsToBytes } from "../../../../utils/easyMaps/convertToBytes";
import StraightIcon from "@mui/icons-material/Straight";

export default function Conexao(
  { conexao, elements, index, expanded, setExpanded, unique, element },
  key
) {
  const theme = useTheme();
  const [reference, setReference] = useState("");
  const [destination, setDestination] = useState("");
  const [capacidade, setCapacidade] = useState(0);
  const [percentInputTraffic, setPercentInputTraffic] = useState(0);
  const [percentOutputTraffic, setPercentOutputTraffic] = useState(0);
  const [percentMax, setPercentMax] = useState(0);
  const [corStatus, setCorStatus] = useState("");

  useEffect(() => {
    if (!conexao || !elements) return;
    let reference = elements.find((ele) => ele.id === conexao.referenceId);
    if (reference) setReference(reference.titulo);
    let destination = elements.find((ele) => ele.id === conexao.destinationId);
    if (destination) setDestination(destination.titulo);
    let cap = conexao?.config?.capacidade ?? 0;
    if (!cap || cap < 0 || cap == "") cap = 0;
    setCapacidade(cap);

    if (cap > 0 && conexao.data) {
      let inputTraffic = parseFloat(conexao.data?.inputTraffic)
        ? parseFloat(conexao.data?.inputTraffic) / 1000000000 ?? 0
        : 0;
      let outputTraffic = parseFloat(conexao.data?.outputTraffic)
        ? parseFloat(conexao.data?.outputTraffic) / 1000000000 ?? 0
        : 0;

      let percentIn = (inputTraffic / cap) * 100;
      let percentOut = (outputTraffic / cap) * 100;
      setPercentInputTraffic(percentIn.toFixed(2));
      setPercentOutputTraffic(percentOut.toFixed(2));
      let percent = percentIn > percentOut ? percentIn : percentOut;
      setPercentMax(percent.toFixed(2));
      const cor = () => {
        if (percent == null || percent == 0)
          return theme.palette.enlaces.offline.main;
        if (percent < 1) return theme.palette.enlaces.p0.main;
        if (percent < 5) return theme.palette.enlaces.p1.main;
        if (percent < 15) return theme.palette.enlaces.p5.main;
        if (percent < 25) return theme.palette.enlaces.p15.main;
        if (percent < 50) return theme.palette.enlaces.p25.main;
        if (percent < 70) return theme.palette.enlaces.p50.main;
        if (percent < 80) return theme.palette.enlaces.p70.main;
        if (percent < 90) return theme.palette.enlaces.p80.main;
        return theme.palette.enlaces.p90.main;
      };
      setCorStatus(cor());
    } else {
      setPercentInputTraffic("- ");
      setPercentOutputTraffic("- ");
      setPercentMax("- ");
      setCorStatus(theme.palette.enlaces.offline.main);
    }
  }, [conexao, elements]);

  const styles = {
    line: {
      position: "absolute",
      height: "80%", //typeElement == "Host" ? "150px" : "230px",
      minWidth: "3px",
      ml: "-5px",
    },

    textMedium: {
      color: theme.palette.color.textDisabled,
      fontSize: "0.85rem",
    },
    textLarge: { fontSize: "2em" },
    contentTab: { gap: "10px" },
    detailsCard: {
      backgroundColor: theme.palette.background.paperSelectedMaps,
      paddingX: "10px",
      paddingY: "15px",
      display: "flex",
      flexDirection: "column",
      gap: 2,
    },
    text: {
      fontSize: "0.85em",
      width: "100%",
      whiteSpace: "normal",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      WebkitLineClamp: 2, // Limite de 2 linhas
      WebkitBoxOrient: "vertical",
      textAlign: "left",
    },
    textAlerta: {
      fontSize: "0.85rem",

      color: theme.palette.color.textDisabled,
    },
    textHeaderAco: {
      fontSize: "0.85rem",

      color: theme.palette.color.text,
    },
  };

  function filterNoTraffic(itens) {
    return itens?.filter(
      (item) =>
        !item?.key_?.toLowerCase().includes("inoctets") &&
        !item?.key_?.toLowerCase().includes("outoctets")
    );
  }

  function handleChange(selected) {
    setExpanded(selected == expanded ? null : index);
  }

  const convertInputTraffic = convertBitsToBytes(conexao?.data?.inputTraffic);
  const convertOutputTraffic = convertBitsToBytes(conexao?.data?.outputTraffic);
  return (
    <Accordion
      key={key}
      disableGutters
      expanded={expanded == index || unique}
      onChange={() => handleChange(index)}
      sx={{
        padding: "5px",
        borderRadius: "5px",
        backgroundColor: theme.palette.background.paperSelectedMaps,
        "&:hover": {
          backgroundColor: theme.palette.background.hover,
        },
        boxShadow: "none",
        border: "none",
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={!unique ? <ExpandMoreIcon /> : null}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          padding: "0px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          ml={1}
        >
          <Box>
            <Box sx={[styles.line, { backgroundColor: corStatus }]}></Box>

            <Stack direction={"column"} gap={1} ml={1.5}>
              <>
                <Tooltip
                  title={
                    conexao?.referenceId === element?.id
                      ? reference
                      : destination
                  }
                >
                  <Typography sx={styles.text}>
                    {conexao?.referenceId === element?.id
                      ? reference
                      : destination}
                  </Typography>
                </Tooltip>
                <Divider width={"80px"} />

                <Tooltip
                  title={
                    conexao?.referenceId !== element?.id
                      ? reference
                      : destination
                  }
                >
                  <Typography sx={styles.text}>
                    {conexao?.referenceId !== element?.id
                      ? reference
                      : destination}
                  </Typography>
                </Tooltip>
              </>
            </Stack>
          </Box>
          <StraightIcon
            sx={{
              fontSize: "30px",
              transform:
                conexao?.referenceId == element?.id ? "rotate(180deg)" : null,
              mr: 0,
              color: theme.palette.mode == "dark" ? "#565656" : "#9A9A9A",
            }}
          />
        </Stack>
      </AccordionSummary>

      <AccordionDetails
        sx={{
          padding: "5px",
          display: "flex",
          gap: 1,
          flexDirection: "column",
        }}
      >
        {conexao?.item.length == 0 ? (
          <Typography sx={[styles.textAlerta, { textAlign: "center" }]}>
            {" "}
            Não há itens na conexão
          </Typography>
        ) : (
          <>
            {!conexao?.data ||
              (conexao?.data?.nameTraffic == null &&
                conexao?.data?.itens?.length == 0 && (
                  <Typography sx={[styles.textAlerta, { textAlign: "center" }]}>
                    {" "}
                    Não há dados disponíveis no momento
                  </Typography>
                ))}

            {conexao?.data?.nameTraffic != null && (
              <>
                <Paper
                  sx={[
                    styles.detailsCard,
                    {
                      backgroundColor: theme.palette.background.paperLinkedMaps,
                    },
                  ]}
                  elevation={0}
                >
                  <Stack direction={"column"} gap={2}>
                    <Typography textAlign={"center"} sx={styles.textAlerta}>
                      {conexao?.data?.nameTraffic}
                    </Typography>
                    <Stack direction={"row"} justifyContent={"space-around"}>
                      <Stack alignItems={"center"}>
                        <Stack
                          direction={"row"}
                          sx={{ alignItems: "flex-end" }}
                        >
                          <Typography sx={styles.textLarge}>
                            {capacidade}
                          </Typography>
                          <Typography color="primary" mb={"6px"}>
                            Gbps
                          </Typography>
                        </Stack>
                        <Typography sx={styles.textMedium}>
                          Capacidade
                        </Typography>
                      </Stack>
                      <Stack alignItems={"center"}>
                        <Stack
                          direction={"row"}
                          sx={{ alignItems: "flex-end" }}
                        >
                          <Typography sx={styles.textLarge}>
                            {percentMax}
                          </Typography>
                          <Typography color="primary" mb={"6px"}>
                            %
                          </Typography>
                        </Stack>{" "}
                        <Typography sx={styles.textMedium}>Em uso</Typography>
                      </Stack>
                    </Stack>
                    <Divider variant="middle" />
                    <Stack direction={"column"} sx={{ padding: "5px" }}>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <DownloadIcon
                          sx={[styles.textMedium, { fontSize: "20px" }]}
                        />
                        <Typography sx={styles.textMedium}>
                          Entrada (RX)
                        </Typography>
                      </Stack>
                      <Stack
                        mt={1}
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Stack direction={"row"} gap={"5px"}>
                          <Typography>{convertInputTraffic.valor}</Typography>
                          <Typography
                            color="primary"
                            variant="small"
                            mt={"4px"}
                          >
                            {convertInputTraffic.unidade}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} gap={"5px"}>
                          <Typography>{percentInputTraffic}</Typography>
                          <Typography
                            color="primary"
                            variant="small"
                            mt={"4px"}
                          >
                            {"%"}
                          </Typography>
                        </Stack>
                      </Stack>
                      <LinearProgress
                        variant="determinate"
                        sx={{
                          backgroundColor: "#4e4e4e6b",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: corStatus,
                          },
                        }}
                        value={
                          percentInputTraffic > 100
                            ? 100
                            : parseFloat(percentInputTraffic)
                        }
                        width="100%"
                      />
                    </Stack>{" "}
                    <Stack direction={"column"} sx={{ padding: "5px" }}>
                      <Stack direction={"row"} alignItems={"center"} gap={1}>
                        <UploadIcon
                          sx={[styles.textMedium, { fontSize: "20px" }]}
                        />
                        <Typography sx={styles.textMedium}>
                          Saída (TX)
                        </Typography>
                      </Stack>

                      <Stack
                        mt={1}
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Stack direction={"row"} gap={"5px"}>
                          <Typography>{convertOutputTraffic.valor}</Typography>
                          <Typography
                            color="primary"
                            variant="small"
                            mt={"4px"}
                          >
                            {convertOutputTraffic.unidade}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} gap={"5px"}>
                          <Typography>{percentOutputTraffic}</Typography>
                          <Typography
                            color="primary"
                            variant="small"
                            mt={"4px"}
                          >
                            {"%"}
                          </Typography>
                        </Stack>
                      </Stack>
                      <LinearProgress
                        variant="determinate"
                        sx={{
                          backgroundColor: "#4e4e4e6b",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: corStatus,
                          },
                        }}
                        value={
                          percentOutputTraffic > 100
                            ? 100
                            : parseFloat(percentOutputTraffic)
                        }
                        width="100%"
                      />
                    </Stack>{" "}
                  </Stack>
                </Paper>
              </>
            )}

            {conexao?.data?.itens?.length != 0 && (
              <Accordion
                key={key + "outrosItens"}
                disableGutters
                sx={{
                  backgroundColor: theme.palette.background.paperLinkedMaps,
                  boxShadow: "none",
                  "&::before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={styles.textHeaderAco}>
                    Outros itens
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {filterNoTraffic(conexao?.data?.itens)?.map((item, index) => (
                    <Fragment key={index + item.name}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent={"space-between"}
                        width="100%"
                      >
                        <Typography
                          sx={styles.textAlerta}
                          fontSize={"0.7rem"}
                          width="170px"
                        >
                          {item.name}
                        </Typography>

                        <Stack direction={"row"} gap={"2px"}>
                          <Typography
                            textAlign={"end"}
                            fontSize={isNaN(item?.lastValue) ? "0.7rem" : null}
                          >
                            {!isNaN(item.lastValue)
                              ? parseFloat(item?.lastValue).toFixed(2)
                              : item?.lastValue}
                          </Typography>
                          {!isNaN(item?.lastValue) && (
                            <Typography
                              color="primary"
                              variant="small"
                              mt={"4px"}
                            >
                              {item?.units}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                      <Divider sx={{ mt: 1, mb: 1 }} />
                    </Fragment>
                  ))}
                </AccordionDetails>
              </Accordion>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
