import axios from "axios";

const publicToken = () => {
  let result = `${window.location.pathname}`;
  result = result.split("/")[6];
  if (result) {
    return result;
  } else {
    return "";
  }
};
const mapsId = () => {
  let result = `${window.location.pathname}`;
  result = result.split("/")[5];
  if (result) {
    return result;
  } else {
    return "";
  }
};
const api = axios.create({});

api.interceptors.request.use(async (config) => {
  const url = `${
    window?.config?.REACT_APP_BASE_URL ? window?.config?.REACT_APP_BASE_URL : ""
  }`;
  if (publicToken()) {
    try {
      const tokenAtual = publicToken();
      const map = mapsId();
      config.baseURL = url;
      config.headers.Authorization = tokenAtual ? `Bearer ${tokenAtual}` : "";
      config.headers["x-map-id"] = map ? `${map}` : "";
      return config;
    } catch (error) {
      console.error(error);
    }
  } else {
    if (window?.config) {
      localStorage.clear();
      window.location.replace("/login");
    }
  }
});
export default api;
