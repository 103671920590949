import { useState } from "react";
import ToolsMenu from "./toolsMenu";
import { createSvgIcon, IconButton, Tooltip, useTheme } from "@mui/material";
import { ReactComponent as IconDots } from "../../../assets/easyMaps/iconsSvg/dots-menu.svg";

export default function Tools(props) {
  const [anchorFerramentas, setAnchorFerramentas] = useState(null);
  const DotsIcon = createSvgIcon(<IconDots />);
  const theme = useTheme();
  const styles = {
    dotsIcon: {
      padding: "3px",
      fontSize: "28px",
      fill: theme.palette.text.primary,
    },
  };
  return (
    <>
      <Tooltip
        title="Ferramentas"
        sx={{ display: { xs: "none", xsm: "flex" } }}
      >
        <IconButton
          color="inherit"
          aria-label="notificacoes"
          onClick={(event) => {
            setAnchorFerramentas(event.currentTarget);
          }}
        >
          <DotsIcon sx={styles.dotsIcon} />
        </IconButton>
      </Tooltip>
      <ToolsMenu
        anchorFerramentas={anchorFerramentas}
        setAnchorFerramentas={setAnchorFerramentas}
      />
    </>
  );
}
