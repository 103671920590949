import { NotificationsNoneOutlined } from "@mui/icons-material";
import { Badge, IconButton, Tooltip, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import apiSistema from "../../../services/apiSistema";
import MenuNotifications from "./menuNotifications";

export default function Notifications(props) {
  const { socket, snackbar, setSnackbar } = props;
  const [countNotificacoes, setCountNotificacoes] = useState(0);
  const [anchorNotification, setAnchorNotification] = useState(null);
  const [notificacoes, setNotificacoes] = useState([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    if (!notificacoes.length > 0) {
      handleApiGetNotificacoes();
    }
  }, []);
  function updateVistoNotificacao(visto, notificacaoId) {
    const novoArray = notificacoes.map((notificacao) => {
      if (notificacao.notificacaoId === notificacaoId) {
        let auxNotificao = notificacao;
        auxNotificao.visto = visto;
        return { ...auxNotificao };
      } else {
        return notificacao;
      }
    });
    if (countNotificacoes > 0) {
      setCountNotificacoes(countNotificacoes - 1);
    } else {
      setCountNotificacoes(0);
    }
    setNotificacoes(novoArray);
  }
  function updateManyVistoNotificacao(visto) {
    const novoArray = notificacoes.map((notificacao) => {
      let auxNotificao = notificacao;
      auxNotificao.visto = visto;
      return { ...auxNotificao };
    });
    setCountNotificacoes(0);
    setNotificacoes(novoArray);
  }
  function handleNovaNotificacao(novaDemanda) {
    let result = {};
    let usuarioClienteId = "";
    result = localStorage.getItem("1");
    if (result) {
      result = JSON.parse(result);
      usuarioClienteId = result.refreshToken.usuarioClienteId;
    }
    if (novaDemanda.usuarioClienteId !== usuarioClienteId) {
      setCountNotificacoes(countNotificacoes + 1);
      let novoArray = notificacoes;
      let objDemanda = handleConteudoNotificacao(novaDemanda.conteudo);
      objDemanda.tipo = novaDemanda.tipo;
      objDemanda.acao = novaDemanda.acao;
      objDemanda.visto = false;
      objDemanda.notificacaoId = novaDemanda.id;
      objDemanda.usuario = novaDemanda.usuario
        ? novaDemanda.usuario.Perfil
        : "";
      objDemanda.usuarioCliente = novaDemanda.usuarioCliente
        ? novaDemanda.usuarioCliente.PerfilCliente
        : "";
      novoArray = [objDemanda, ...novoArray];
      setNotificacoes(novoArray);
    }
  }
  function handleConteudoNotificacao(demanda) {
    let newObj = {};
    newObj.id = demanda.id;
    /**newObj.usuario = demanda.usuario ? demanda.usuario.Perfil : "";
      newObj.usuarioCliente = demanda.usuarioCliente
        ? demanda.usuarioCliente.PerfilCliente
        : "";*/
    newObj.descricao =
      demanda.descricao ||
      demanda.relatoriosCliente?.descricao ||
      demanda.topicoRelatorio?.relatoriosCliente?.descricao;

    newObj.relatoriosClienteId =
      demanda.topicoRelatorio?.relatoriosCliente?.id ||
      demanda.relatoriosCliente?.id ||
      demanda.id;

    newObj.fixar = demanda?.fixar ? demanda.fixar : false;
    newObj.status = demanda.status;

    if (demanda.updateAt) {
      newObj.tempo = handleTime(demanda.updateAt);
    } else {
      newObj.tempo = handleTime(demanda.createAt);
    }
    newObj.atualizada = demanda.updateAt ? true : false;
    return newObj;
  }
  async function handleApiGetNotificacoes() {
    try {
      setLoadingNotifications(true);
      const response = await apiSistema.post("/notificacoes/findMany", {
        usuarioClienteId: JSON.parse(localStorage.getItem("1")).refreshToken
          .usuarioClienteId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar notificacoes",
          severity: "error",
        });
        setLoadingNotifications(false);
      } else {
        setLoadingNotifications(false);
        if (response.data) {
          handleNotificacoes(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setLoadingNotifications(false);
    }
  }
  function handleNotificacoes(res) {
    let result = {};
    let usuarioClienteId = "";
    result = localStorage.getItem("1");
    if (result) {
      result = JSON.parse(result);
      usuarioClienteId = result.refreshToken.usuarioClienteId;
    }
    let auxCount = 0;
    //setCountNotificacoes(response.data.length);
    let novoArray = [];
    res.map((statusNotificacao) => {
      if (statusNotificacao.notificacao.usuarioClienteId !== usuarioClienteId) {
        let objDemanda = handleConteudoNotificacao(
          statusNotificacao.notificacao.conteudo
        );
        objDemanda.tipo = statusNotificacao.notificacao.tipo;
        objDemanda.acao = statusNotificacao.notificacao.acao;
        objDemanda.visto = statusNotificacao.visto;
        objDemanda.notificacaoId = statusNotificacao.notificacao.id;
        objDemanda.usuario = statusNotificacao.notificacao.usuario
          ? statusNotificacao.notificacao.usuario.Perfil
          : "";
        objDemanda.usuarioCliente = statusNotificacao.notificacao.usuarioCliente
          ? statusNotificacao.notificacao.usuarioCliente.PerfilCliente
          : "";
        novoArray.push(objDemanda);
        if (!statusNotificacao.visto) {
          auxCount++;
        }
      }
    });
    setCountNotificacoes(auxCount);
    setNotificacoes(novoArray);
  }
  function updateVistoNotificacao(visto, notificacaoId) {
    const novoArray = notificacoes.map((notificacao) => {
      if (notificacao.notificacaoId === notificacaoId) {
        let auxNotificao = notificacao;
        auxNotificao.visto = visto;
        return { ...auxNotificao };
      } else {
        return notificacao;
      }
    });
    if (countNotificacoes > 0) {
      setCountNotificacoes(countNotificacoes - 1);
    } else {
      setCountNotificacoes(0);
    }
    setNotificacoes(novoArray);
  }
  function updateManyVistoNotificacao(visto) {
    const novoArray = notificacoes.map((notificacao) => {
      let auxNotificao = notificacao;
      auxNotificao.visto = visto;
      return { ...auxNotificao };
    });
    setCountNotificacoes(0);
    setNotificacoes(novoArray);
  }
  function handleTime(params) {
    const agora = new Date();
    const segundos = parseInt((Date.parse(agora) - Date.parse(params)) / 1000);
    const horas = Math.floor(segundos / (60 * 60));
    const dias = horas > 24 && Math.floor(horas / 24);
    const minutos = Math.floor(segundos / 60) % 60;
    if (dias > 0) {
      if (dias <= 1) {
        return parseInt(dias) + " dia atrás";
      }
      return parseInt(dias) + " dias atrás";
    } else if (horas > 0) {
      if (horas <= 1) {
        return parseInt(horas) + " h atrás";
      } else {
        return parseInt(horas) + " hrs atrás";
      }
    } else if (minutos > 0) {
      return parseInt(minutos) + " min atrás";
    } else {
      return "segs atrás";
    }
  }
  return (
    <>
      <Tooltip
        title="Notificações"
        sx={{ display: { xs: "none", xsm: "flex" } }}
      >
        <IconButton
          color="inherit"
          aria-label="notificacoes"
          onClick={(event) => {
            setAnchorNotification(event.currentTarget);
          }}
        >
          <Badge color="secondary" badgeContent={countNotificacoes}>
            <NotificationsNoneOutlined
              sx={{
                //  fontSize: "24px",
                color: theme.palette.text.primary,
              }}
            />
          </Badge>
        </IconButton>
      </Tooltip>
      <MenuNotifications
        notificacoes={notificacoes}
        handleNovaNotificacao={handleNovaNotificacao}
        anchorNotification={anchorNotification}
        setAnchorNotification={setAnchorNotification}
        loadingNotification={loadingNotifications}
        updateVistoNotificacao={updateVistoNotificacao}
        updateManyVistoNotificacao={updateManyVistoNotificacao}
        socket={socket}
      />
    </>
  );
}
