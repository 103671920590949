import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  styled,
  Paper,
  Stack,
  Typography,
  Button,
  Avatar,
  Chip,
  Icon,
  IconButton,
  InputAdornment,
  Tabs,
  Tab,
  TextField,
  Badge,
  Tooltip,
  useTheme,
  Zoom,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import api from "../../services/api";

import { useNavigate, useOutletContext } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  Lock,
  LockOpen,
  MoreVert,
  QueryBuilder,
  Search,
  Verified,
} from "@mui/icons-material";

import { ptBR as ptBRDatagrid } from "@mui/x-data-grid/locales";

import { ReactComponent as IconUserNotFound } from "../../assets/icons/userNotFound.svg";
import useStyles from "./styles";
import MenuAdm from "../../componentes/administracao/menu";
import Filters from "../../componentes/administracao/filters";
import NewAlertModal from "../../componentes/newAlertModal";
import { useNotification } from "../../componentes/notificationProvider";
import MobileTable from "../../componentes/mobileTable";
import { maskCnpj, maskCPF, removeMask } from "../../utils/maskFields";
import ModalCreateUser from "./modalCreateUser";
import ModalEditProfile from "./modalEditProfile";
import ModalEditUserPassword from "./modalEditUserPassword";
import ModalEditPermission from "./modalEditPermission";

const StyledBadge = styled(Badge)(({ theme, color }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: color,
    color: color,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}));

const permissoes = [
  { name: "Super Admin", value: "adm001" },
  { name: "Admin", value: "adm002" },
  { name: "Usuário", value: "user001" },
];

export default function Administracao() {
  const { setTitlePage } = useOutletContext();
  const [loadingPage, setLoadingPage] = useState(false);
  const [role, setRole] = useState("");
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [openEditProfileModal, setOpenEditProfileModal] = useState(false);
  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);
  const [openEditLoginModal, setOpenEditLoginModal] = useState(false);
  const [openEditPermissionModal, setOpenEditPermissionModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [criadoEm, setCriadoEm] = useState("");
  const [verifyStatus, setVerifyStatus] = useState({
    value: "",
    label: "",
  });
  const [permissionStatus, setPermissionStatus] = useState({
    value: "",
    label: "",
  });
  const [personTypeStatus, setPersonTypeStatus] = useState({
    value: "",
    label: "",
  });
  const [modalAlert, setModalAlert] = useState(false);
  const columnVisibilityMobile = {
    foto: false,
    options: false,
    nome: true,
    sobrenome: true,
    nomeFantasia: true,
    email: true,
    cpf_cnpj: true,
    login: true,
    verificado: true,
    codigoPermissao: true,
    ultimoAcesso: true,
    createAt: true,
  };
  const { socketCliente } = useOutletContext();

  const theme = useTheme();
  const styles = useStyles(theme);
  const apiRef = useRef(null);
  const notify = useNotification();

  useEffect(() => {
    setTitlePage("Gerenciamento de usuários");
  }, []);

  useEffect(() => {
    setSelectedRow((row) => row?.id && rows.find((r) => r.id === row.id));
  }, [rows]);

  useEffect(() => {
    const interval = window.setInterval(() => {
      updateStatusOnline();
    }, 10000);

    return () => window.clearInterval(interval);
  }, []);
  const open = Boolean(anchorEl);

  const handleClick = (event, row) => {
    setSelectedRow(row);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewOpen = () => {
    setOpenCreateUserModal(true);
  };

  const handleSearchInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const getFilteredRows = useMemo(() => {
    const rowsFiltered = rows.filter(
      (row) =>
        (tabValue === 0 && row.status === true) ||
        (tabValue === 1 && row.status === false)
    );

    return rowsFiltered.filter((row) => {
      return (
        (searchText
          ? row?.PerfilCliente?.nomeFantasia
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            row?.PerfilCliente?.nome
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            row?.PerfilCliente?.sobrenome
              ?.toLowerCase()
              .includes(searchText.toLowerCase()) ||
            row?.login?.toLowerCase().includes(searchText.toLowerCase()) ||
            row?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
            (removeMask(row?.PerfilCliente?.cpf).includes(
              removeMask(searchText)
            ) &&
              !!removeMask(searchText)) ||
            (removeMask(row?.PerfilCliente?.cnpj).includes(
              removeMask(searchText)
            ) &&
              !!removeMask(searchText))
          : true) &&
        row.verificado?.toString().includes(verifyStatus?.value?.toString()) &&
        row.codigoPermissao?.includes(permissionStatus?.value) &&
        (criadoEm
          ? new Date(row?.createAt)
              .toLocaleDateString()
              .includes(new Date(criadoEm).toLocaleDateString())
          : true) &&
        (row?.PerfilCliente
          ? row?.PerfilCliente?.pessoaFisica
              ?.toString()
              .includes(personTypeStatus?.value?.toString())
          : true)
      );
    });
  }, [
    verifyStatus,
    permissionStatus,
    personTypeStatus,
    criadoEm,
    tabValue,
    rows,
    searchText,
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    async function permissionValidate() {
      try {
        const response = await api.get("/authPermissionClient");

        if (response.data.message !== "Permission is valid") {
          navigate("/homeIn");
        }
      } catch (error) {
        console.error(error);
      }
    }
    permissionValidate();
  }, []);

  useEffect(() => {
    setRole(
      JSON.parse(localStorage.getItem("1")).usuarioCliente.codigoPermissao
    );

    async function apiget(params) {
      try {
        setLoadingPage(true);
        const response = await api.get("/perfil/findMany");

        setRows(response.data);
      } catch (error) {
        console.error(error);
        if (error?.response?.data?.error)
          notify(error.response.data.error, "error");
        else notify("Não foi possível se conectar ao servidor!", "error");
      } finally {
        setLoadingPage(false);
      }
    }
    apiget();
  }, []);

  async function updateStatusOnline() {
    try {
      socketCliente.emit("onlineUsers", {}, (response) => {
        if (response.status !== 200) {
          console.error(response);
        } else {
          let responseData = response?.data;

          setRows((elements) => {
            return elements.map((element) => {
              const user = responseData.find((user) => user.id === element.id);

              if (user) {
                element.online = true;
              } else {
                element.online = false;
              }
              return element;
            });
          });
        }
      });
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.error)
        notify(error.response.data.err.or, "error");
      else
        notify("Não foi possivel atualizar status de conectividade!", "error");
    }
  }

  const columns = [
    {
      sortable: false,
      field: "foto",
      headerName: "",
      width: 60,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent={"center"}
            height="100%"
          >
            <Tooltip
              title={params.row?.online ? "online" : "offline"}
              arrow
              slots={{
                transition: Zoom,
              }}
            >
              <StyledBadge
                hidden={!params.row?.online}
                //badgeContent={!params.row?.online ? 0 : ""}
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                variant="dot"
                color={
                  params.row?.online ? "#44b700" : theme.palette.error.main
                }
                sx={{ height: "fit-content" }}
              >
                <Avatar
                  alt={
                    params?.row?.PerfilCliente?.nome
                      ? params?.row?.PerfilCliente?.nome
                      : params?.row?.PerfilCliente?.nomeFantasia
                  }
                  src={params?.row?.PerfilCliente?.foto || "/"}
                  sx={styles.foto}
                />
              </StyledBadge>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      width: 150,
      field: "nome",
      headerName: "Nome",
      valueGetter: (value, row) => row?.PerfilCliente?.nome || "-",
    },
    {
      width: 100,
      flex: 1,
      field: "sobrenome",
      headerName: "Sobrenome",
      valueGetter: (value, row) => row?.PerfilCliente?.sobrenome || "-",
    },
    {
      width: 100,
      flex: 1,
      field: "nomeFantasia",
      headerName: "Nome Fantasia",
      valueGetter: (value, row) => row?.PerfilCliente?.nomeFantasia || "-",
    },
    {
      width: 100,
      flex: 1,
      field: "email",
      headerName: "E-mail",
      valueGetter: (value, row) => row?.email || "-",
    },
    {
      width: 130,
      field: "cpf_cnpj",
      headerName: "CPF/CNPJ",
      valueGetter: (value, row) =>
        maskCPF(row?.PerfilCliente?.cpf) ||
        maskCnpj(row?.PerfilCliente?.cnpj) ||
        "",
    },
    {
      width: 120,
      field: "login",
      headerName: "Login",
      valueGetter: (value, row) => row?.login || "",
    },
    {
      field: "verificado",
      headerName: "Verificado",
      width: 150,
      renderCell: (params) => (
        <Stack
          color={params.value === true ? "sucess.main" : "text.secondary"}
          sx={styles.containerColumnVerificado}
        >
          <Icon sx={styles.iconVerified}>
            {params.value === true ? <Verified /> : <QueryBuilder />}
          </Icon>
          <Typography fontSize={13} fontWeight={500}>
            {params.value === true ? "Sim" : "Aguardando"}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "codigoPermissao",
      headerName: "Permissão",
      width: 120,
      renderCell: (params) => {
        return (
          <Stack sx={styles.containerColumnPermissao} spacing={1}>
            <Chip
              key={params?.value}
              label={
                permissoes.find((perm) => perm.value === params?.value)?.name
              }
              color="primary.main"
              sx={
                params?.value === "adm001"
                  ? styles.chipPermSuperAdmin
                  : params?.value === "adm002"
                  ? styles.chipPermAdmin
                  : styles.chipPermUsuario
              }
              variant="contained"
            />
          </Stack>
        );
      },
    },
    {
      sortable: false,
      field: "ultimoAcesso",
      headerName: "Último acesso",
      width: 150,
      renderCell: (params) => {
        return (
          params.value && (
            <Stack sx={styles.containerColumnLastLogin}>
              <Typography fontSize={13} sx={styles.textDate}>
                {dayjs(params.value).locale("pt-br").format("D [de] MMM, YYYY")}
              </Typography>
              <Typography fontSize={12} sx={styles.subTextDate}>
                {dayjs(params.value).locale("pt-br").format("D [de] MMM, YYYY")}
              </Typography>
            </Stack>
          )
        );
      },
    },

    {
      sortable: false,
      field: "createAt",
      headerName: "Criado em",
      width: 150,

      renderCell: (params) => {
        return (
          params.value && (
            <Stack sx={styles.containerColumnCreatedAt} spacing={1}>
              <Typography fontSize={13} sx={styles.textDate}>
                {dayjs(params.value).locale("pt-br").format("D [de] MMM, YYYY")}
              </Typography>
            </Stack>
          )
        );
      },
    },
    {
      sortable: false,
      field: "options",
      headerName: "Opções",
      width: 70,

      renderCell: (params) => {
        const disabledButton =
          JSON.parse(localStorage.getItem("1")).usuarioCliente?.id ===
          params?.row?.id;

        return (
          <IconButton
            disabled={disabledButton}
            onClick={(e) => handleClick(e, params.row)}
            fontSize="large"
          >
            <MoreVert fontSize="small" />
          </IconButton>
        );
      },
    },
  ];

  async function handleApiPutstatus(id, status) {
    try {
      const response = await api.put("/usuario/status/update", {
        id,
        status,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.status === 200) {
        const updateRows = rows;
        updateRows.map((row) => {
          if (row?.id === id) {
            row.status = status;
          }
        });
        setRows([...rows, updateRows]);
        notify(
          `Usuário ${status ? "ativado" : "desativado"} com sucesso!`,
          "success"
        );
      }
    } catch (error) {
      if (error?.response?.data?.error)
        notify(error.response.data.error, "error");
      else notify("Erro ao atualizar dados!", "error");
      console.error(error);
    }
  }

  function CustomNoRowsOverlay() {
    return (
      <Box sx={styles.boxNoRowsOverlay}>
        <IconUserNotFound style={styles.iconUserNotFound} />
        <Typography color="textDisabled">
          {`Não possui usuários ${tabValue === 0 ? "ativos" : "inativos"}`}
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <Box sx={styles.containerBox}>
        <Tabs
          value={tabValue}
          onChange={(event, newValue) => {
            setTabValue(newValue);
          }}
        >
          <Tab
            disableRipple
            disableFocusRipple
            icon={<LockOpen fontSize="small" />}
            label={"Ativos"}
            iconPosition="start"
            value={0}
            sx={styles.tab}
          />
          <Tab
            disableRipple
            disableFocusRipple
            icon={<Lock fontSize="small" />}
            label={"Inativos"}
            iconPosition="start"
            value={1}
            sx={styles.tab}
          />
        </Tabs>
        <Paper elevation={0} sx={styles.paper}>
          <Box sx={styles.boxHeader}>
            <Box sx={styles.boxSearchAndFilter}>
              <TextField
                size="small"
                id="outlined-adornment-amount"
                sx={styles.searchInput}
                value={searchText}
                onChange={handleSearchInputChange}
                autoComplete="off"
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Search />
                      </InputAdornment>
                    ),
                  },
                }}
                placeholder="Pesquisar Usuário"
              />

              <Filters
                verifyStatus={verifyStatus}
                setVerifyStatus={setVerifyStatus}
                permissionStatus={permissionStatus}
                setPermissionStatus={setPermissionStatus}
                personTypeStatus={personTypeStatus}
                setPersonTypeStatus={setPersonTypeStatus}
                criadoEm={criadoEm}
                setCriadoEm={setCriadoEm}
                handleNewOpen={handleNewOpen}
              />
              <Tooltip title="Adicionar usuário">
                <Button
                  onClick={handleNewOpen}
                  sx={styles.buttonMobile}
                  variant="contained"
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            </Box>

            {/*               <Button
                startIcon={<AddIcon />}
                onClick={handleNewOpen}
                sx={styles.button}
                variant="contained"
              >
                Novo Usuário
              </Button> */}
          </Box>

          <Box sx={{ width: "100%" }}>
            <MobileTable
              autoHeight={true}
              localeText={
                ptBRDatagrid.components.MuiDataGrid.defaultProps.localeText
              }
              editMode="row"
              loading={loadingPage}
              disableColumnMenu
              disableColumnFilter
              disableSelectionOnClick
              hideFooterPagination
              hideFooterSelectedRowCount
              hideFooter
              sx={styles.dataGrid}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              rows={getFilteredRows}
              columns={columns}
              onOptionsClick={(e, row) => handleClick(e, row)}
              columnVisibility={columnVisibilityMobile}
              noRowsOverlay={CustomNoRowsOverlay}
              headerMobile={({ login, PerfilCliente, online }) => (
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <StyledBadge
                    hidden={online}
                    //badgeContent={online ? 0 : ""}
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    color={online ? "#44b700" : theme.palette.error.main}
                    sx={{ height: "fit-content" }}
                  >
                    <Avatar
                      fontSize={"35px"}
                      alt={
                        PerfilCliente?.nome
                          ? PerfilCliente?.nome
                          : PerfilCliente?.nomeFantasia
                      }
                      src={PerfilCliente?.foto || "/"}
                    />
                  </StyledBadge>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={{ fontWeight: 700, fontSize: "14px" }}>
                      Login
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        color: "text.secondary",
                      }}
                    >
                      {login}
                    </Typography>
                  </Box>
                </Box>
              )}
            />
          </Box>
        </Paper>
      </Box>

      {anchorEl && (
        <MenuAdm
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          setOpenEditProfileModal={setOpenEditProfileModal}
          setOpenEditLoginModal={setOpenEditLoginModal}
          setOpenEditPermissionModal={setOpenEditPermissionModal}
          setModalAlert={setModalAlert}
          activated={tabValue === 0}
        />
      )}
      <ModalEditProfile
        openEditProfileModal={openEditProfileModal}
        setOpenEditProfileModal={setOpenEditProfileModal}
        selectedRow={selectedRow}
        setRows={setRows}
      />
      <ModalCreateUser
        openCreateUserModal={openCreateUserModal}
        setOpenCreateUserModal={setOpenCreateUserModal}
        rows={rows}
        setRows={setRows}
        permissoes={permissoes}
        role={role}
      />

      <ModalEditUserPassword
        openEditLoginModal={openEditLoginModal}
        setOpenEditLoginModal={setOpenEditLoginModal}
        selectedRow={selectedRow}
      />
      <ModalEditPermission
        openEditPermissionModal={openEditPermissionModal}
        setOpenEditPermissionModal={setOpenEditPermissionModal}
        selectedRow={selectedRow}
        rows={rows}
        setRows={setRows}
        role={role}
      />

      <NewAlertModal
        openModal={modalAlert}
        setOpenModal={setModalAlert}
        textContent={
          "Se você prosseguir com a operação o usuário será desativado"
        }
        handleButton={() => {
          handleApiPutstatus(selectedRow?.id, !selectedRow?.status);
          setModalAlert(false);
        }}
        severity={"warning"}
        singleButton={false}
      />
    </>
  );
}
