import { alpha } from "@mui/material";

const useStyles = (theme) => ({
  //ModalFilterMobile
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    padding: 2,
    maxWidth: "432px",
    width: "90%",
    minWidth: "232px",
    maxHeight: "90vh",
    gap: 2,
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-start",
    //backgroundColor: "red",
  },
  dialogTitle: { padding: 0 },
  boxHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    color: "primary.main",
    width: "100%",
  },
  dialogContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    gap: 1.5,
  },
  boxRowFields: {
    display: "flex",
    flexDirection: "column",
    with: "100%",
    gap: 1,
  },
  labels: {
    fontSize: "14px",
    fontWeight: 500,
    color: "text.primary",
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: 0,
    padding: 0,
    width: "100%",
  },
  button: { width: "50%" },
  iconTextFields: { color: "text.secondary", fontSize: "18px" },
  //Filters
  backgroundBox: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: theme.palette.background.textField,
    borderRadius: "5px",
    gap: 1.5,
    padding: { xs: 0, sm: "8px 4px 4px 4px", md: "4px 4px 4px 8px" },
    justifyContent: "center",
    alignItems: "center",
    width: "fit-content",
    display: { xs: "none", md: "flex" },
  },
  boxTitle: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "center",
    width: "100%",
    flexDirection: "row",
    gap: 0.5,
  },
  text: {
    fontSize: "14px",
    fontWeight: 500,
    height: "20px",
  },
  paperBrick: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 1.5,
    // width: { xs: "100%", md: "fit-content" },
    width: "fit-content",
    gap: 0.5,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: alpha(theme.palette.background.paper, 0.7),
    },
  },
  size: { fontSize: 20 },
  buttonClearFilters: {
    height: "32px",
    color: "text.primary",
  },
  // sizeGridItem: { xs: 12, md: "auto" },
  sizeGridItem: "auto",
  buttonMobile: { display: { xs: "flex", md: "none" } },
  badge: {
    "& .MuiBadge-badge": {
      backgroundColor: "primary.main",
      color: "#FFFFFF",
      margin: 1,
      boxShadow: "0px 3px 3px #00000052",
      top: -25,
      right: -15,
    },
  },
  buttonFilterMobile: {
    p: 1.3,
    minWidth: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    color: "primary.main",
    display: { xs: "flex", md: "none" },
  },
  button: {
    fontSize: "13px",
    flexWrap: "no-wrap",
    minWidth: "160px",
    width: { xs: "100%", md: "160px" },
    height: "40px",
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    color: "primary.main",
    display: { xs: "none", md: "flex" },
  },
});

export default useStyles;
