import { useEffect, useMemo, useState } from "react";

import {
  Box,
  createSvgIcon,
  CssBaseline,
  Fade,
  Grow,
  ThemeProvider,
} from "@mui/material";

//import { easyMapsTheme } from "../../themes/themeEasyReport";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as IconLogo } from "../../assets/easyMaps/logoEasyMaps.svg";

//import { ReactComponent as PerfilReportSvg } from "../../assets/easyReport/iconSvg/perfilReport.svg";
import easyMapsTheme from "../../themes/easyMapsTheme";
import TopBar from "../../componentes/topBar";

import {
  Delete,
  Description,
  DescriptionOutlined,
  EventNote,
  MapOutlined,
  RouteOutlined,
  WorkspacesOutlined,
} from "@mui/icons-material";
import checkToken from "../../services/CheckToken";
import SideBar from "../../componentes/sideBar";

function LayoutEasyMaps(params) {
  const { colorMode, setColorMode, socket, socketCliente, setCheckLogin } =
    params;
  const theme = useMemo(() => easyMapsTheme(colorMode), [colorMode]);
  const location = useLocation(); // Se estiver usando React Router
  const [snackbar, setSnackbar] = useState(null);
  const [openRightBar, setOpenRightBar] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);
  const navigate = useNavigate();

  const styles = {
    containerLayout: {
      display: "flex",
      width: "100%",
      height: "fit-content",
      flexDirection: "column",
    },
    containerBody: {
      display: "flex",
      width: "100%",
      height: "100%",
      minWidth: "350px",
    },
    containerOutlet: {
      flexGrow: 1,
      //paddingTop: 5, paddingInline: 3
      padding: showSidebar ? 4 : 0,
      gap: 3,
    },
  };
  //const PerfilReport = createSvgIcon(<PerfilReportSvg />);
  const rightBarPages = [
    {
      itemId: "1",
      label: "Grupo de mapas",
      link: "/easyMaps/grupos",
      icon: <WorkspacesOutlined />,
    },
    {
      itemId: "2",
      label: "Mapas",
      link: "/easyMaps/maps",
      icon: <MapOutlined />,
      nodes: [
        {
          itemId: "2.1",
          label: "Topologia",
          link: "/easyMaps/mapas/topologia",
        },
        {
          itemId: "2.2",
          label: "Geolocalizado",
          link: "/easyMaps/mapas/geolocalizado",
        },
      ],
    },
    {
      itemId: "3",
      label: "POP's",
      link: "/easyMaps/mapas/pop",
      icon: <RouteOutlined />,
    },
    // {
    //   itemId: "4",
    //   label: "Configuração",
    //   link: "/easyReport/lixeira",
    //   icon: <Delete />,
    // },
  ];
  useEffect(() => {
    setShowSidebar(!location.pathname.includes("/easyMaps/mapa/"));
  }, [location.pathname]);

  // isso deve ser removido ao merjar com o guarda de rota (ALERT)
  useEffect(() => {
    setTimeout(() => {
      tokenValidate();
      checkPerfil();
    }, 200);

    function checkPerfil() {
      let perfilAlreadyExists =
        JSON.parse(localStorage.getItem("1"))?.perfilAlreadyExists || false;
      let satusPerfil =
        JSON.parse(localStorage.getItem("perfil"))?.status || false;
      if (satusPerfil === false || perfilAlreadyExists === false) {
        navigate("/cadastro");
      }
    }

    async function tokenValidate() {
      const data = await checkToken();

      if (!data) {
        navigate("/login");
      }
      setCheckLogin(true);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={styles.containerLayout}>
        <TopBar
          setOpenDrawer={setOpenDrawer}
          setOpenRightBar={setOpenRightBar}
          iconLogo={<IconLogo />}
          link={"/easyMaps"}
          socket={socket}
          socketCliente={socketCliente}
          colorMode={colorMode}
          setColorMode={setColorMode}
          snackbar={snackbar}
          setSnackbar={setSnackbar}
          openRightBar={openRightBar}
          ocultar={!showSidebar}
        />
        <Box sx={styles.containerBody}>
          <SideBar
            items={rightBarPages}
            title="Navegação"
            openRightBar={openRightBar}
            setOpenRightBar={setOpenRightBar}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            iconLogo={<IconLogo />}
            ocultar={!showSidebar}
          />

          <Box component="main" sx={styles.containerOutlet}>
            <Outlet />
          </Box>
        </Box>{" "}
      </Box>
    </ThemeProvider>
  );
}

export default LayoutEasyMaps;
