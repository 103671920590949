import {
  CheckCircleRounded,
  ErrorRounded,
  InfoRounded,
  WarningRounded,
} from "@mui/icons-material";

import {
  DialogContentText,
  DialogActions,
  Stack,
  Paper,
  Modal,
  Typography,
  DialogTitle,
  DialogContent,
  Button,
  Box,
} from "@mui/material";

export default function NewAlertModal(props) {
  const {
    openModal,
    setOpenModal,
    confirmar = false,
    handleButton = () => {},
    handleCancel = () => {},
    severity = "success",
    textContent,
    singleButton = true,
    customButton,
    buttonText,
    buttonCancelText,
  } = props;

  const alertText = () => {
    if (textContent) {
      return textContent;
    }
    return severity === "success"
      ? "Sucesso na operação!"
      : severity === "warning"
      ? "Se você prosseguir com a operação o item será editado permanentemente e os dados anteriores são perdidos!"
      : severity === "info"
      ? "Está ação pode ser permanentemente!"
      : "Se você prosseguir com a operação o item será deletado e os dados apagados serão perdidos permanentemente!";
  };
  const alertTitle = () => {
    return severity === "success"
      ? "Sucesso!"
      : severity === "warning"
      ? "Alerta!"
      : severity === "info"
      ? "Informação"
      : "Atenção!";
  };
  const buttonTextDefault = () => {
    if (buttonText) {
      return buttonText;
    }
    return severity === "success"
      ? "Aceitar"
      : severity === "warning"
      ? "Prosseguir"
      : severity === "info"
      ? "Confirmar"
      : "Excluir";
  };

  const color = () => {
    return severity === "success"
      ? "sucess.main"
      : severity === "warning"
      ? "warning.main"
      : severity === "info"
      ? "info.main"
      : "error.main";
  };
  const stylesModal = {
    container: { minWidth: "400px", width: "100%" },
    ContainerBox: {
      width: "100%",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      height: "100%",
    },
    boxRowFields: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      with: "100%",
      gap: 1,
    },

    Paper: {
      width: "100%",
      height: "210px",
      justifyContent: "flex-end",
      alignItems: " center",
      display: "flex",
      flexDirection: "column",
      borderRadius: 2,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      bgcolor: "background.paper",
    },
    PaperTwo: {
      padding: 4,
      maxWidth: "332px",
      width: "100%",
      minWidth: "232px",
      maxHeight: "90vh",
      gap: 2,
      flexDirection: "column",
      display: "flex",
      alignItems: "felx-start",
    },
    dialogTitle: {
      padding: 0,
    },
    Stack: {
      borderRadius: 2,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      border: "none",
      bgcolor: "background.paper",
      width: "100%",
      mb: "-10px",
      zIndex: 0,
      minHeight: "182px",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    DialogTitle: {
      "&.MuiTypography-root": { color: "text.primary" },
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 500,
      padding: "5px 20px",
    },
    DialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      padding: 0,
    },
    DialogContentText: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px",
      width: "100%",
      textAlign: "center",
      color: "text.secondary",
    },
    DialogActions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      gap: singleButton ? 0 : 8,
      paddingBottom: "15px",
      width: "100%",
    },
    ButtonConfirm: {
      fontWeight: 500,
      // color: "#ffffff",
      backgroundColor: `${color()}`,
      width: singleButton ? "90%" : "35%",
      borderRadius: "3px",
    },
    ButtonCancel: {
      backgroundColor: "background.textField",
      color: "text.primary",
      borderRadius: "3px",
    },
    Icon: {
      width: "90px",
      height: "90px",
      color:
        severity === "error"
          ? "error.main"
          : severity === "success"
          ? "sucess.main"
          : severity === "warning"
          ? "warning.main"
          : "info.main",
    },
    modal: { display: "flex", justifyContent: "center", alignItems: "center" },
  };

  return (
    <Modal
      open={openModal}
      closeAfterTransition
      onClose={() => {
        setOpenModal(false);
      }}
      sx={stylesModal.modal}
    >
      <Paper sx={stylesModal.PaperTwo}>
        <DialogTitle sx={stylesModal.dialogTitle}>
          <Box sx={stylesModal.boxRowFields}>
            {severity === "success" ? (
              <CheckCircleRounded sx={stylesModal.Icon} />
            ) : severity === "error" ? (
              <ErrorRounded sx={stylesModal.Icon} />
            ) : severity === "info" ? (
              <InfoRounded sx={stylesModal.Icon} />
            ) : (
              <WarningRounded sx={stylesModal.Icon} />
            )}

            <Typography variant="h4">{alertTitle()}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={stylesModal.DialogContent}>
          <DialogContentText sx={stylesModal.DialogContentText}>
            <Typography variant="h3" fontSize={16}>
              {alertText()}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={stylesModal.DialogActions}>
          {singleButton === true ? (
            <Button
              autoFocus
              variant="contained"
              color={severity}
              sx={stylesModal.ButtonConfirm}
              onClick={handleButton}
              disabled={confirmar}
            >
              {buttonTextDefault()}
            </Button>
          ) : (
            <>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => {
                  setOpenModal(false);
                  handleCancel();
                }}
                sx={stylesModal.ButtonCancel}
              >
                {buttonCancelText ?? "Cancelar"}
              </Button>

              <Button
                autoFocus
                variant="contained"
                color={severity}
                sx={stylesModal.ButtonConfirm}
                onClick={handleButton}
                disabled={confirmar}
              >
                {buttonTextDefault()}
              </Button>
            </>
          )}
          {customButton}
        </DialogActions>
      </Paper>
    </Modal>
  );
}
