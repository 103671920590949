import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  Avatar,
  DialogTitle,
  DialogContent,
  Modal,
  Grid2,
  Divider,
  Tooltip,
  useTheme,
  IconButton,
} from "@mui/material";
import { Person, Business, ModeEdit, Close } from "@mui/icons-material";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import api from "../../../../services/api";
import { LoadingButton } from "@mui/lab";
import ImageButton from "../../../imageButton";
import { useNotification } from "../../../notificationProvider";
import NewAlertModal from "../../../newAlertModal";
import {
  maskCnpj,
  maskCPF,
  maskPhone,
  removeMask,
} from "../../../../utils/maskFields";
import useStyles from "./styles";
import { validarCpf } from "../../../../utils/validateCpf";
import { validarCnpj } from "../../../../utils/validateCnpj";

const AvatarModal = ({ openAvatarModal, setOpenAvatarModal, foto }) => {
  const handleClose = () => setOpenAvatarModal(false);
  const theme = useTheme();
  const styles = useStyles(theme);
  return (
    <Modal
      open={openAvatarModal}
      onClose={handleClose}
      sx={styles.avatarModal}
      slotProps={{
        backdrop: {
          style: { backgroundColor: "rgba(0, 0, 0, 0.8)" }, // Fundo mais escuro
        },
      }}
    >
      <>
        <img src={foto} alt="Foto de perfil" style={styles.imgAvatarModal} />
        <IconButton
          size="large"
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 40,
            right: 40,
            color: "white",
          }}
        >
          <Close />
        </IconButton>
      </>
    </Modal>
  );
};

export default function ProfileModal(props) {
  const { openModal, setOpenModal } = props;
  const [email, setEmail] = useState("");
  const [editar, setEditar] = useState(false);
  const [dialogSalvar, setDialogSalvar] = useState(false);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [foto, setFoto] = useState("");
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [telefone, setTelefone] = useState("");
  const [pessoaFisica, setPessoaFisica] = useState(true);
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [dataDeNascimento, setDataDeNascimento] = useState(null);
  const [login, setLogin] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const notify = useNotification();
  const theme = useTheme();
  const styles = useStyles(theme);
  const validateFields = () => {
    setIsSubmit(true);

    if (
      pessoaFisica &&
      (!nome ||
        !sobrenome ||
        !validarCpf(cpf) ||
        !dataDeNascimento ||
        !telefone)
    )
      return false;

    if (!pessoaFisica && (!nomeFantasia || !validarCnpj(cnpj) || !telefone))
      return false;

    return true;
  };
  async function handleApi() {
    try {
      setLoading(true);
      let data = {};
      if (pessoaFisica)
        data = {
          nome,
          sobrenome,
          dataDeNascimento,
          cpf: removeMask(cpf),
          telefone: removeMask(telefone),
          foto,
        };
      if (!pessoaFisica)
        data = {
          nomeFantasia,
          cnpj: removeMask(cnpj),
          telefone: removeMask(telefone),
          foto,
        };

      setLoading(true);
      const response = await api.put("/perfil/update", {
        ...data,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });

      if (response.status === 200) {
        notify("Dados salvos com sucesso", "success");
        localStorage.setItem("perfil", JSON.stringify(response.data));
        handleUsuarioId();
      }
    } catch (error) {
      console.error();
      if (error?.response?.data?.error)
        notify(error.response.data.error, "error");
      else notify("Não foi possível se conectar com o servidor!", "error");
    } finally {
      setLoading(false);
      setIsSubmit(false);
    }
  }
  useEffect(() => {
    if (!openModal) {
      setEditar(false);
    }
    handleUsuarioId();
  }, [openModal, editar]);

  const handleUsuarioId = () => {
    let result = localStorage.getItem("perfil");
    let resultUserData = localStorage.getItem("1");
    let resultLogin = localStorage.getItem("login");
    result = JSON.parse(result);
    if (result) {
      resultUserData = JSON.parse(resultUserData);
      resultLogin = JSON.parse(resultLogin);
      setNome(result.nome);
      setSobrenome(result.sobrenome);
      setDataDeNascimento(dayjs(result.dataDeNascimento));
      setFoto(result.foto);
      setCpf(maskCPF(result.cpf));
      setTelefone(maskPhone(result.telefone));
      setCnpj(maskCnpj(result.cnpj));
      setEmail(resultUserData.usuarioCliente.email);
      setPessoaFisica(result.pessoaFisica);
      setNomeFantasia(result.nomeFantasia);
      setLogin(resultLogin);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        closeAfterTransition
        onClose={() => {
          setOpenModal(false);
          setIsSubmit(false);
        }}
        sx={styles.modal}
      >
        <Paper variant="outlined" sx={styles.paper}>
          <DialogTitle sx={styles.dialogTitle}>
            <Box sx={styles.paperPreview}>
              <Box sx={styles.boxCover}></Box>
              <Box sx={styles.boxLoginAndButton}>
                {editar ? (
                  <Box sx={styles.boxButtons}>
                    <Button
                      disabled={loading}
                      onClick={() => {
                        setEditar(false);
                      }}
                    >
                      Cancelar
                    </Button>
                    <LoadingButton
                      variant="contained"
                      sx={styles.loadingButton}
                      loading={loading}
                      onClick={() => {
                        if (validateFields()) setDialogSalvar(true);
                      }}
                    >
                      Salvar
                    </LoadingButton>
                  </Box>
                ) : (
                  <Box sx={styles.boxLoginAndButton}>
                    <Button
                      startIcon={<ModeEdit />}
                      variant="contained"
                      component="label"
                      size="small"
                      sx={styles.loadingButton}
                      onClick={() => setEditar(true)}
                    >
                      Editar
                    </Button>{" "}
                    <Typography sx={styles.login}>{login}</Typography>
                  </Box>
                )}
              </Box>
              <Box sx={styles.boxAvatar}>
                {!editar ? (
                  <>
                    <Avatar
                      src={foto}
                      color="secondary"
                      sx={styles.avatar}
                      onClick={() => setOpenAvatarModal(true)}
                    />
                    <Tooltip
                      title={pessoaFisica ? "pessoa física" : "pessoa jurídica"}
                    >
                      <Box
                        variant="contained"
                        component="label"
                        sx={styles.boxIcon}
                      >
                        {pessoaFisica ? <Person /> : <Business />}
                      </Box>
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Avatar
                      src={foto}
                      color="secondary"
                      sx={styles.avatarEdit}
                    />{" "}
                    <ImageButton
                      component="label"
                      size="small"
                      setFoto={setFoto}
                      sx={styles.imageButton}
                    />
                  </>
                )}
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent sx={styles.dialogContent}>
            <Grid2 container columnSpacing={1.5} rowSpacing={2} sx={{ p: 1 }}>
              {pessoaFisica && (
                <Grid2 item size={{ xs: 12, sm: 12 }}>
                  {editar ? (
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                      <Box sx={styles.boxRowFields}>
                        <Typography sx={styles.subtitle}>Nome</Typography>{" "}
                        <TextField
                          id="nome"
                          placeholder="Insira seu nome"
                          value={nome}
                          sx={styles.textfield}
                          disabled={loading}
                          onChange={(event) => {
                            setNome(event.target.value);
                          }}
                          error={isSubmit && !nome}
                          helperText={isSubmit && !nome && "Campo inválido"}
                        />
                      </Box>
                      <Box sx={styles.boxRowFields}>
                        <Typography variant="h5" sx={styles.subtitle}>
                          Sobrenome
                        </Typography>
                        <TextField
                          id="sobrenome"
                          placeholder="Insira seu sobrenome"
                          value={sobrenome}
                          sx={styles.textfield}
                          disabled={loading}
                          onChange={(event) => {
                            setSobrenome(event.target.value);
                          }}
                          error={isSubmit && !sobrenome}
                          helperText={
                            isSubmit && !sobrenome && "Campo inválido"
                          }
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>
                        Nome e Sobrenome
                      </Typography>{" "}
                      <Box sx={styles.boxFullName}>
                        <Typography variant="h5" sx={styles.paragraph}>
                          {nome}
                        </Typography>
                        <Typography variant="h5" sx={styles.paragraph}>
                          {sobrenome}
                        </Typography>
                      </Box>
                    </Box>
                  )}{" "}
                </Grid2>
              )}
              {!pessoaFisica && (
                <Grid2 item size={{ xs: 12, sm: 12 }}>
                  {editar ? (
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>
                        Nome fantasia
                      </Typography>{" "}
                      <TextField
                        id="nome-fantasia"
                        placeholder="Insira o nome fantasia"
                        value={nomeFantasia}
                        sx={styles.textfield}
                        disabled={loading}
                        onChange={(event) => {
                          setNomeFantasia(event.target.value);
                        }}
                        error={isSubmit && !nomeFantasia}
                        helperText={
                          isSubmit && !nomeFantasia && "Campo inválido"
                        }
                      />
                    </Box>
                  ) : (
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>
                        Nome fantasia
                      </Typography>{" "}
                      <Typography variant="h5" sx={styles.paragraph}>
                        {nomeFantasia}
                      </Typography>
                    </Box>
                  )}
                </Grid2>
              )}
              <Divider width="100%" />
              {pessoaFisica && (
                <Grid2 item size={{ xs: 6, sm: 6 }}>
                  {editar ? (
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>
                        Data de Nascimento{" "}
                      </Typography>{" "}
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={"pt-br"}
                      >
                        <DatePicker
                          placeholder="Data de nascimento"
                          // sx={styles.textfield}
                          maxDate={dayjs()}
                          openTo="year"
                          autoComplete="off"
                          disabled={loading}
                          value={dataDeNascimento}
                          onChange={(newValeu) => {
                            setDataDeNascimento(dayjs(new Date(newValeu)));
                          }}
                          error={isSubmit && !dataDeNascimento}
                          helperText={
                            isSubmit && !dataDeNascimento && "Campo inválido"
                          }
                        />
                      </LocalizationProvider>
                    </Box>
                  ) : (
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>
                        Data de Nascimento
                      </Typography>{" "}
                      <Typography sx={styles.paragraph}>
                        {dayjs(dataDeNascimento).format("DD/MM/YYYY")}
                      </Typography>
                    </Box>
                  )}
                </Grid2>
              )}

              <Grid2 item size={{ xs: 12, sm: 6 }}>
                {editar ? (
                  <Box sx={styles.boxRowFields}>
                    <Typography sx={styles.subtitle}>Telefone</Typography>{" "}
                    <TextField
                      id="telefone"
                      placeholder="Insira o Telefone"
                      value={telefone}
                      sx={styles.textfield}
                      disabled={loading}
                      onChange={(event) => {
                        setTelefone(event.target.value);
                      }}
                      error={isSubmit && !telefone}
                      helperText={isSubmit && !telefone && "Campo inválido"}
                    />
                  </Box>
                ) : (
                  <Box sx={styles.boxRowFields}>
                    <Typography sx={styles.subtitle}>Telefone</Typography>{" "}
                    <Typography variant="h5" sx={styles.paragraph}>
                      {telefone}
                    </Typography>
                  </Box>
                )}
              </Grid2>

              {pessoaFisica && <Divider width="100%" />}

              {pessoaFisica && (
                <Grid2 item size={{ xs: 12, sm: 12 }}>
                  {editar ? (
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>CPF</Typography>{" "}
                      <TextField
                        id="cpf"
                        placeholder="Insira o CPF"
                        value={cpf}
                        sx={styles.textfield}
                        disabled={loading}
                        onChange={(event) => {
                          const value = event.target.value;
                          setCpf(maskCPF(value));
                        }}
                        error={isSubmit && !validarCpf(cpf)}
                        helperText={
                          isSubmit && !validarCpf(cpf) && "CPF inválido"
                        }
                      />
                    </Box>
                  ) : (
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>CPF</Typography>{" "}
                      <Typography variant="h5" sx={styles.paragraph}>
                        {cpf}
                      </Typography>
                    </Box>
                  )}
                </Grid2>
              )}
              {!pessoaFisica && (
                <Grid2 item size={{ xs: 12, sm: 6 }}>
                  {!editar ? (
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>CNPJ </Typography>{" "}
                      <Typography variant="h5" sx={styles.paragraph}>
                        {cnpj}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>CNPJ </Typography>{" "}
                      <TextField
                        id="cnpj"
                        placeholder="Insira o CNPJ"
                        value={cnpj}
                        disabled={loading}
                        sx={styles.textfield}
                        onChange={(event) => {
                          const value = event.target.value;
                          setCnpj(maskCnpj(value));
                        }}
                        error={isSubmit && !validarCnpj(cnpj)}
                        helperText={
                          isSubmit && !validarCnpj(cnpj) && "CNPJ inválido"
                        }
                      />
                    </Box>
                  )}
                </Grid2>
              )}
              <Divider width="100%" />
              <Grid2 item size={{ xs: 12, sm: 12 }}>
                {editar ? (
                  <Tooltip title="Não é possível editar o e-mail">
                    <Box sx={styles.boxRowFields}>
                      <Typography sx={styles.subtitle}>E-mail</Typography>
                      <TextField
                        id="telefone"
                        placeholder="Insira o e-mail"
                        value={email}
                        disabled
                        sx={styles.textfield}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        error={isSubmit && !email}
                        helperText={isSubmit && !email && "Campo inválido"}
                      />
                    </Box>
                  </Tooltip>
                ) : (
                  <Box sx={styles.boxRowFields}>
                    <Typography sx={styles.subtitle}>E-mail</Typography>
                    <Typography sx={styles.paragraph}>{email}</Typography>
                  </Box>
                )}
              </Grid2>
            </Grid2>
          </DialogContent>
        </Paper>
      </Modal>
      <NewAlertModal
        openModal={dialogSalvar}
        setOpenModal={setDialogSalvar}
        textContent={"Deseja realmente salvar os dados editados?"}
        handleButton={() => {
          setDialogSalvar(false);
          handleApi();
          setEditar(false);
        }}
        severity={"warning"}
        singleButton={false}
      />
      <AvatarModal
        openAvatarModal={openAvatarModal}
        setOpenAvatarModal={setOpenAvatarModal}
        foto={foto}
      />
    </>
  );
}
