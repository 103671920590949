import { alpha } from "@mui/material";

const useStyles = (theme) => ({
  //ModalEditProfile
  foto: {
    width: "30px",
    height: "30px",
  },
  stack: {
    padding: "0px",
    width: "100%",
    height: "100%",
  },
  modal: { display: "flex", justifyContent: "center", alignItems: "center" },
  paper: {
    padding: 2,
    maxWidth: "432px",
    width: "90%",
    minWidth: "232px",
    maxHeight: "90vh",
    gap: 2,
    flexDirection: "column",
    display: "flex",
    alignItems: "felx-start",
  },
  boxTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    color: "primary.main",
    width: "100%",
  },
  boxHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: "text.primary",
  },
  dialogTitle: {
    padding: 0,
  },
  dialogActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: 0,
    padding: 0,
    width: "100%",
  },
  dialogContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    gap: 1.5,
  },
  boxRowFields: {
    display: "flex",
    flexDirection: "column",
    with: "100%",
    gap: 1,
  },
  textfield: {
    width: "100%",
  },
  buttonClose: {
    color: theme.palette.grey[500],
  },
  alert: {
    mt: 2,
    boxShadow: 0,
    backgroundColor: alpha(theme.palette.info.main, 0.1),
  },
  //BoxPreview
  paperPreview: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "100%",
    padding: 1,
    paddingBottom: 2,
    gap: 1.5,
  },
  boxCover: {
    height: "100px",
    borderRadius: "5px",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    width: "100%",
  },
  avatar: {
    position: "absolute",
    width: "100px",
    height: "100px",
    mb: 2,
    ml: 3,
    outline: "8px solid" + " " + theme.palette.background.paper,
  },
  boxAvatar: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "end",
  },
  boxIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "white",
    bgcolor: "primary.main",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    position: "absolute",
    margin: "0px 0px 10px 90px",
  },
  button: {
    color: "primary.main",
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    borderRadius: "none",
  },
});

export default useStyles;
