import {
  Alert,
  alpha,
  Box,
  Button,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import useStyles from "./styles";
import { CopyAll, Search } from "@mui/icons-material";
import { useState } from "react";
import api from "../../services/api";

import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

export default function ScriptsOnus() {
  const [serial, setSerial] = useState("");
  const [snackbar, setSnackbar] = useState(null);
  const [onuDetails, setOnuDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const styles = useStyles(theme);

  function Editor() {
    try {
      const colorsStyles = {
        default: `${theme.palette.mode === "light" ? "#8d8d8d" : "#6d6d6d"}`,
        background_warning: "#FFDCDB",
        string: `${theme.palette.mode === "light" ? "#E83750" : "#ACC96F"}`,
        //string: "#2c8c49", // overrides theme colors with whatever color value you want
        number: `${theme.palette.mode === "light" ? "#0288D1" : "#077CB1"}`, //"#FF514A",
        colon: "#5A12CC",
        keys: `${theme.palette.mode === "light" ? "#5B2E87" : "#BDAFE7"}`,
        primitive: "#FF746E",
        background: `${theme.palette.mode === "light" ? "#f2f2f2" : "#171717"}`,
      };
      return (
        <JSONInput
          id="a_unique_id"
          placeholder={onuDetails}
          locale={locale}
          style={{ body: { "font-size": "0.8rem" } }}
          colors={colorsStyles}
          viewOnly={true}
          onchange
          confirmGood={false}
          height="100%"
          width="100%"
        />
      );
    } catch (error) {
      <></>;
    }
  }

  async function getOnuDetails() {
    try {
      setLoading(true);
      const response = await api.post("/findOnuDetais", {
        serial,
      });

      if (response.data.status === "Error") {
        //console.error(response.data);
        setSnackbar({
          children: "Error: Não foi possivel buscar informações",
          severity: "error",
        });
      } else {
        setOnuDetails(response.data);
        setSnackbar({
          children: "Informações encontradas com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      setSnackbar({
        children: `${
          error?.response?.data?.error ||
          error?.response?.data?.message ||
          "Não foi possível buscar informações da ONU"
        }`,
        severity: "error",
      });
      setOnuDetails({});

      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box sx={styles.container}>
      <Paper sx={styles.paperContent}>
        <Typography sx={styles.title}>INFORMAÇÃO DE ONU's</Typography>
        <Typography>
          Insira o serial ou username da ONU abaixo para obter informações.
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Cole ou digite seu serial aqui..."
          variant="outlined"
          value={serial}
          onChange={(e) => setSerial(e.target.value.trim())}
        />
        <Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Search />}
            disabled={loading}
            onClick={getOnuDetails}
          >
            Buscar
          </Button>
        </Box>
        {onuDetails && Object.keys(onuDetails).length > 0 && (
          <Box sx={styles.editorContainer}>
            <Tooltip
              title="Copiar JSON"
              arrow
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(onuDetails));
                setSnackbar({
                  children: "JSON copiado para a área de transferência",
                  severity: "success",
                });
              }}
            >
              <IconButton sx={styles.copyButton}>
                <CopyAll />
              </IconButton>
            </Tooltip>
            <Editor />
          </Box>
        )}
      </Paper>

      {!!snackbar && snackbar?.severity !== "error" && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}

      {!!snackbar && snackbar?.severity === "error" && (
        <Alert
          {...snackbar}
          severity={"error"}
          variant="outlined"
          sx={styles.alert}
        >
          {snackbar?.children}
        </Alert>
      )}
    </Box>
  );
}
