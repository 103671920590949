import { Close, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import api from "../../../services/api";
import { useNotification } from "../../../componentes/notificationProvider";
import { useState } from "react";
import { validarSenha } from "../../../utils/validateFields";
import { LoadingButton } from "@mui/lab";

export default function ModalEditUserPassword(params) {
  const notify = useNotification();
  const { openEditLoginModal, setOpenEditLoginModal, selectedRow } = params;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [loading, setLoading] = useState(false);

  const styles = {
    modal: { display: "flex", justifyContent: "center", alignItems: "center" },
    paper: {
      padding: 2,
      maxWidth: "432px",
      width: "90%",
      minWidth: "232px",
      maxHeight: "90vh",
      gap: 2,
      flexDirection: "column",
      display: "flex",
      alignItems: "felx-start",
    },
    boxTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
      color: "primary.main",
      width: "100%",
    },
    subtitle: {
      fontSize: "14px",
      fontWeight: 500,
      color: "text.primary",
    },
    dialogTitle: {
      padding: 0,
    },
    dialogActions: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "end",
      gap: 0,
      padding: 0,
      width: "100%",
    },
    dialogContent: {
      //flexGrow: 1,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      padding: 0,
      gap: 1.5,
      paddingRight: "5px",
    },
    boxRowFields: {
      display: "flex",
      flexDirection: "column",
      with: "100%",
      gap: 1,
    },
    textfield: {
      width: "100%",
    },
  };
  const handleUpdatePassword = async () => {
    setIsSubmit(true);
    if (
      !password ||
      !confirmPassword ||
      password !== confirmPassword ||
      !validarSenha(password)
    )
      return;
    const id = selectedRow?.id;

    try {
      setLoading(true);
      const response = await api.put("/usuario/senha/update", {
        id: id,
        senha: password,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs")).id,
      });

      if (response.data.status === "Error") {
        notify("Error: Não foi possível salvar dados", "error");
        console.log("error");
      } else {
        handleClose();
        notify("Senha atualizada com sucesso!", "success");
        console.log("success");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setOpenEditLoginModal(false);
    setPassword("");
    setConfirmPassword("");
    setShowPassword(false);
    setShowConfirmPassword(false);
    setIsSubmit(false);
  };

  const handleClickShowPassword = (type) => {
    if (type === "password") {
      setShowPassword((show) => !show);
    } else {
      setShowConfirmPassword((show) => !show);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Modal open={openEditLoginModal} onClose={handleClose} sx={styles.modal}>
      <Paper sx={styles.paper}>
        <DialogTitle color="primary" sx={styles.dialogTitle}>
          <Box sx={styles.boxTitle}>
            <IconButton size="small">
              <Edit sx={{ color: "primary.main" }} />
            </IconButton>
            <Typography fontWeight={500}>Editar Senha</Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenEditLoginModal(false);
            }}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
          >
            <Close />
          </IconButton>
          <Divider />{" "}
        </DialogTitle>

        <DialogContent sx={styles.dialogContent}>
          <Grid2 container columnSpacing={1.5} rowSpacing={2}>
            <Grid2 item size={{ xs: 12, sm: 12 }}>
              <Box sx={styles.boxRowFields}>
                <Typography sx={styles.subtitle}>Senha</Typography>{" "}
                <TextField
                  placeholder="Digite a senha"
                  size="small"
                  sx={styles.textfield}
                  type={showPassword ? "text" : "password"}
                  autoComplete="off"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            edge="end"
                            onClick={() => handleClickShowPassword("password")}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                  error={isSubmit && (!password || !validarSenha(password))}
                  helperText={
                    isSubmit &&
                    (!password || !validarSenha(password)) &&
                    "A senha tem que ter no mínimo 6 dígitos e um número"
                  }
                  disabled={loading}
                />
                <TextField
                  placeholder="Digite a senha novamente"
                  size="small"
                  sx={styles.textfield}
                  type={showConfirmPassword ? "text" : "password"}
                  autoComplete="off"
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  slotProps={{
                    input: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            edge="end"
                            onClick={() =>
                              handleClickShowPassword("confirmPassword")
                            }
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  }}
                  error={
                    isSubmit &&
                    ((password && !confirmPassword) ||
                      password !== confirmPassword)
                  }
                  helperText={
                    isSubmit &&
                    ((password && !confirmPassword) ||
                      password !== confirmPassword) &&
                    "As senhas não conferem!"
                  }
                  disabled={loading}
                />
              </Box>
            </Grid2>
          </Grid2>
        </DialogContent>

        <DialogActions sx={styles.dialogActions}>
          <Button
            onClick={() => {
              handleClose();
            }}
            disabled={loading}
          >
            Cancelar
          </Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={() => handleUpdatePassword()}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </Paper>
    </Modal>
  );
}
