import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const URL = window?.config?.REACT_APP_BASE_URL;
const URL_SISTEMA = window?.config?.REACT_APP_LINK_MW;
const USUARIO_CLIENTE_ID = JSON.parse(localStorage.getItem("1"))?.usuarioCliente
  ?.id;
export const socketCliente = io(URL + "/private", {
  reconnection: true,
  autoConnect: false,
  query: {
    usuarioClienteId: USUARIO_CLIENTE_ID,
  },
  auth: {
    token: window?.config?.REACT_APP_SOCKET_TOKEN || "",
  },
});
//console.log(USUARIO_CLIENTE_ID);

export const socket = io(URL_SISTEMA, {
  reconnection: true,
  autoConnect: false,
  auth: {
    token: window?.config?.REACT_APP_MW_SISTEMA_TOKEN,
  },
});

let result = `${window.location.href}`;
const mapsId = result.split("/")[7];
const token = result.split("/")[8];

export const socketPublic = io(URL + "/public", {
  reconnection: true,
  autoConnect: false,
  auth: {
    token,
    mapsId,
  },
});
