import {
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function CustomMenu(props) {
  const {
    anchorEl,
    openMenu = false,
    setAnchorEl = () => {},
    nodes = null,
    setSelectedItems = () => {},
    setExpandedItems = () => {},
    selectedItems = [],
    itemId,
  } = props;
  const theme = useTheme();
  const navigate = useNavigate();

  const handleItemClick = (node) => {
    setSelectedItems([itemId, node?.itemId]);
    setExpandedItems([node?.itemId]);
    if (node?.link) {
      navigate(node?.link);
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const styles = {
    paper: {
      marginRight: "5px",
      paddingX: "5px",
      paddingY: "5px",
    },
    menuList: {
      p: 0,
      m: 0,
    },
    menuItem: {
      marginY: "3px",
      height: "40px",
      borderRadius: 1,
      color: theme.palette.text.disabled,
    },
  };
  return (
    <Popper
      id="menu-right-bar"
      sx={{ zIndex: 1300 }}
      anchorEl={anchorEl}
      open={openMenu && Array.isArray(nodes) && nodes.length > 0}
      onClose={handleCloseMenu}
      placement="right-start"
    >
      <Paper elevation={2} sx={styles.paper}>
        {nodes && Array.isArray(nodes)
          ? nodes.map((node) => (
              <MenuList key={itemId} sx={styles.menuList}>
                <MenuItem
                  sx={{
                    ...styles.menuItem,
                    ...(selectedItems.includes(node?.itemId) && {
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.primary.text,
                    }),
                  }}
                  onClick={() => {
                    handleCloseMenu();
                    handleItemClick(node);
                  }}
                >
                  <ListItemText>{node?.label}</ListItemText>{" "}
                </MenuItem>
              </MenuList>
            ))
          : null}
      </Paper>
    </Popper>
  );
}
