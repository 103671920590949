import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";

const DescriptionBar = ({ elementSelected = false }, key) => {
  let theme = useTheme();
  const cores = [
    { cor: theme.palette.enlaces.offline.main, label: "Offline" },
    { cor: theme.palette.enlaces.p0.main, label: "0%" },
    { cor: theme.palette.enlaces.p1.main, label: "1%" },
    { cor: theme.palette.enlaces.p5.main, label: "5%" },
    { cor: theme.palette.enlaces.p15.main, label: "15%" },
    { cor: theme.palette.enlaces.p25.main, label: "25%" },
    { cor: theme.palette.enlaces.p50.main, label: "50%" },
    { cor: theme.palette.enlaces.p70.main, label: "70%" },
    { cor: theme.palette.enlaces.p80.main, label: "80%" },
    { cor: theme.palette.enlaces.p90.main, label: "90%" },
  ];
  const styles = {
    bar: {
      position: "absolute",
      left: elementSelected ? 10 : null,
      right: elementSelected ? null : 10,
      bottom: 10,
      zIndex: 10,
      flexDirection: "row",
    },
    box: {
      minHeight: "10px",
      minWidth: "60px",
      ml: "-1px",
    },
  };
  return (
    <Stack sx={styles.bar} key={key}>
      {cores.map((cor, index) => (
        <Stack
          key={"barColor" + index}
          direction="row"
          sx={{ justifyContent: "center", height: "25px" }}
        >
          <Divider orientation="vertical" />
          <Stack gap={0.4}>
            <Box
              sx={[
                styles.box,
                {
                  backgroundColor: cor.cor,
                },
              ]}
            />
            <Typography sx={{ fontSize: "10px", ml: "2px" }}>
              {" "}
              {cor.label}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default DescriptionBar;
