export function validarCpf(cpf) {
  if (!cpf) return false;

  cpf = cpf.replace(/\D/g, "");

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return false;
  }

  const calcularDigito = (base) => {
    let soma = 0;
    for (let i = 0; i < base.length; i++) {
      soma += parseInt(base[i]) * (base.length + 1 - i);
    }
    const resto = soma % 11;
    if (resto === 0 || resto === 1) {
      return 0;
    }
    return 11 - resto;
  };

  const base = cpf.slice(0, 9);
  const digito1 = calcularDigito(base);
  const digito2 = calcularDigito(base + digito1);

  return cpf === base + digito1 + digito2;
}
