import { KeyOutlined } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
  Typography,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../services/api";
import { useNotification } from "../../../componentes/notificationProvider";

export default function ModalEditPermission(params) {
  const {
    openEditPermissionModal,
    setOpenEditPermissionModal,
    selectedRow,
    rows,
    setRows,
    role,
  } = params;
  const [permission, setPermission] = useState(null);
  const [hasProfile, setHasProfile] = useState(false);
  const isSuperAdmin = role === "adm001";
  const notify = useNotification();

  useEffect(() => {
    setPermission(selectedRow?.codigoPermissao);
    if (selectedRow?.PerfilCliente) {
      setHasProfile(true);
    }
  }, [selectedRow]);

  const styles = {
    modal: { display: "flex", justifyContent: "center", alignItems: "center" },
    paper: {
      padding: 2,
      maxWidth: "432px",
      width: "90%",
      minWidth: "232px",
      maxHeight: "90vh",
      gap: 2,
      flexDirection: "column",
      display: "flex",
      alignItems: "felx-start",
    },
    boxDialogTitle: {
      display: "flex",
      flexDirection: "row",
      gap: 1,
      marginBottom: 1,
    },
    foto: {
      width: "80px",
      height: "80px",
    },
    fotoModalPermission: {
      width: "50px",
      height: "50px",
    },
    textfield: {
      width: "100%",
      minWidth: "min-content",
    },
    name: {
      color: "text.primary",
      fontWeight: 500,
    },
    login: {
      color: "text.secondary",
      fontWeight: 400,
    },
    boxTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
      color: "primary.main",
    },
    boxLabelsSwitch: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
      alignItems: "start",
    },
    label: {
      marginTop: "20px",
      fontWeight: 500,
      color: "text.primary",
    },
    subLabel: {
      fontWeight: 400,
      color: "text.secondary",
    },

    buttonCancelDialog: {
      color: "primary.main",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      padding: 0,
    },
    boxFoto: {
      display: "flex",
      width: "440px",
      height: "80px",
      justifyContent: "space-between",
      alignItems: "start",
    },
    boxContainerFoto: {
      display: "flex",
      alignItems: " center",
      gap: "12px",
    },
    formLabel: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "noWrap",
      marginLeft: 4,
      width: "90%",
    },
  };

  const handleChange = (event) => {
    setPermission(event.target.value);
  };

  const handleUpdatePermission = async (id, permission) => {
    try {
      const response = await api.put("/usuario/permissoes/update", {
        id: id,
        permissoes: permission,
        logsLoginClienteId: JSON.parse(localStorage.getItem("logs"))?.id || "",
      });
      if (response.data) {
        const updateRows = rows;
        updateRows.map((row) => {
          if (row?.id === selectedRow.id) {
            const codPermission = response.data.codigoPermissao;
            if (codPermission) row.codigoPermissao = codPermission;
          }
        });
        notify("Permissão atualizada com sucesso!", "success");
        setRows(updateRows);
      }
    } catch (error) {
      if (error?.response?.data?.error)
        notify(error.response.data.error, "error");
      else notify("Erro ao atualizar dados!", "error");
      console.log(error);
    } finally {
      setOpenEditPermissionModal(false);
    }
  };

  return (
    <Modal
      open={openEditPermissionModal}
      onClose={() => {
        setOpenEditPermissionModal(false);
      }}
      sx={styles.modal}
    >
      <Paper sx={styles.paper}>
        <DialogTitle color="primary" sx={{ padding: 0 }}>
          {!hasProfile ? (
            <Box>
              <Typography sx={styles.name}>{selectedRow?.email}</Typography>
              <Typography sx={styles.login}>{selectedRow?.login}</Typography>
            </Box>
          ) : (
            <Box sx={styles.boxDialogTitle}>
              <Avatar
                alt={"foto de perfil"}
                src={selectedRow?.PerfilCliente?.foto}
                sx={styles.fotoModalPermission}
              />
              <Box>
                <Typography sx={styles.name}>
                  {selectedRow?.PerfilCliente?.nome}
                </Typography>
                <Typography sx={styles.login}>{selectedRow?.login}</Typography>
              </Box>
            </Box>
          )}

          <Divider />
        </DialogTitle>
        <DialogContent sx={styles.dialogContent}>
          <Divider />
          <Box sx={styles.boxTitle}>
            <IconButton>
              <KeyOutlined sx={{ color: "primary.main" }} />
            </IconButton>
            <Typography fontWeight={500}>Permissões</Typography>
          </Box>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={permission}
              onChange={handleChange}
            >
              <FormControlLabel
                id="group-label"
                value="adm001"
                control={<Radio disabled={!isSuperAdmin} />}
                label="Super Admin"
                subLabel="testeee"
              />
              <Typography sx={styles.formLabel}>
                Acesso a página de adminstração, pode remover e conceder
                qualquer permissões para outros usuários
              </Typography>
              <FormControlLabel
                id="group-label1"
                value="adm002"
                control={<Radio />}
                label="Admin"
              />
              <Typography sx={styles.formLabel}>
                Acesso a página de adminstração, pode remover e conceder algumas
                permissões para outros usuários
              </Typography>
              <FormControlLabel
                id="group-label2"
                value="user001"
                control={<Radio />}
                label="Usuário"
              />
              <Typography sx={styles.formLabel}>
                Acesso padrão de usuário
              </Typography>
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Button
            sx={styles.buttonCancelDialog}
            onClick={() => {
              setOpenEditPermissionModal(false);
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleUpdatePermission(selectedRow.id, permission);
            }}
          >
            Salvar
          </Button>
        </DialogActions>
      </Paper>
    </Modal>
  );
}
