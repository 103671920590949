import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import "reactflow/dist/style.css";
import SliderBarMaps from "../../../../componentes/easyMaps/sideBarEasyMaps";
import api from "../../../../services/apiPublic";
import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import MenuMaps from "../../../../componentes/easyMaps/menuMaps";
import ReactFlowMapas from "./reactFlowMapa";
import AlertModal from "../../../../componentes/alertModal";
//import useUndoable from "use-undoable";

const MapaTopologiaPublic = () => {
  const tipo = "easymon";
  const [snackbar, setSnackbar] = useState(null);
  const [produtos, setProdutos] = useState(false);
  const [map, setMap] = useState({});
  const [elements, setElements] = useState([]);
  // const [
  //   elements,
  //   setElements,
  //   { undo, canUndo, redo, reset, canRedo, past, future },
  // ] = useUndoable([]);
  const [elementSelected, setElementSelected] = useState(null);
  const [connections, setConnections] = useState([]);
  const [connectionsAnalog, setConnectionsAnalog] = useState([]);
  const [connectionSelected, setConnectionSelected] = useState(null);
  const [edgeSelected, setEdgeSelected] = useState(null);
  const [checkChanges, setCheckChanges] = useState(false);
  const [elementsConnected, setElementsConnected] = useState([]);
  const [fullScreem, setFullScreem] = useState(false);
  const [iconsElements, setIconsElements] = useState([]);
  const [openRightBar, setOpenRightBar] = useState(false);
  const [mapas, setMapas] = useState([]);
  const [focusNodeId, setFocusNodeId] = useState(null);
  const [coordenadasFlow, setCoordenadasFlow] = useState(null);
  const [loadingMap, setLoadingMap] = useState(true);
  const [loadinButtonEnlace, setLoadinButtonEnlace] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [easyMapsConfig, setEasyMapsConfig] = useState(null);
  const { id } = useParams();
  const [socket] = useOutletContext();
  const navigate = useNavigate();
  const styles = {
    fullScreem: {
      position: "fixed",
      zIndex: 1500,
      width: "100vw",
      height: "100vh",
      left: 0,
      top: 0,
    },
    loadingMap: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      height: "100%",
      backgroundColor: "#00000093",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: 4,
    },
  };
  useEffect(() => {
    let config = JSON.parse(localStorage.getItem("easyMapsConfig")) || null;
    if (config) setEasyMapsConfig(config);
  }, []);

  useEffect(() => {
    if (!easyMapsConfig) return;
    localStorage.setItem("easyMapsConfig", JSON.stringify(easyMapsConfig));
  }, [easyMapsConfig]);

  useEffect(() => {
    handleApi();

    const interval = window.setInterval(() => {
      updateDataElementSocket();
    }, 60000);

    return () => window.clearInterval(interval);
  }, [id]);

  async function handleApi() {
    try {
      setLoadingMap(true);
      setElementSelected(null);
      setConnectionSelected(null);
      await Promise.all([
        handleApiGetMap(id),
        handleApiGetMaps(),
        handleApiGetElements(id),
        getIconDB(),
      ]);
    } catch (error) {
    } finally {
      setLoadingMap(false);
    }
  }

  //-----funcoes de chamada a api
  async function handleApiGetElements(mapId) {
    try {
      const response = await api.post("/easyMaps/public/element/findMany", {
        mapId,
      });
      if (response?.data?.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar os elementos",
          severity: "error",
        });
      } else {
        if (response?.data) {
          setElements(response.data?.responseAlerts);
          //reset(response.data.responseAlerts);
          setConnections(
            response.data?.connections ? [...response.data.connections] : []
          );
          setConnectionsAnalog(
            response.data?.connections ? [...response.data.connections] : []
          );
          await updateDataElementSocket();
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      setLoadingMap(false);
    }
  }
  async function handleApiGetMap(mapId) {
    try {
      setOpenAlertModal(false);
      const response = await api.post("/easyMaps/public/map/findMapById", {
        mapId,
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar o mapa",
          severity: "error",
        });
      } else {
        setMap(response.data);
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.status == 404) navigate("/easyMaps/pages/notFound");
      if (error?.response?.status == 401)
        setOpenAlertModal({
          open: true,
          severity: "error",
          text: "Você não tem permissão para acessar este mapa, solicite permissão ao administrador",
        });
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      //setLoadingProdutos(false);
    }
  }
  async function updateDataElementSocket() {
    try {
      socket.emit("getDataElementsMap", { mapId: id }, (response) => {
        if (response.status !== 200) {
          setSnackbar({
            children: "Error: Não foi possível atualizar os elemntos",
            severity: "error",
          });
        } else {
          let responseData = response?.data;

          setElements((elements) => {
            elements.map((ele) => {
              let elemntoUpdate = responseData?.find(
                (eleUpdate) => eleUpdate?.elementId == ele?.id
              );

              //atualiza os alertas
              ele.alerts = elemntoUpdate?.alerts;
              //adicionar ping, snmp e latẽncia em data
              if (!ele?.data) ele["data"] = {};

              let itens = elemntoUpdate?.itensHosts || [];

              ele.data["itens"] = itens?.filter(
                (con) =>
                  con.key_?.toLowerCase() != "icmppingloss" &&
                  con.key_?.toLowerCase() != "snmp" &&
                  con.key_?.toLowerCase() != "icmppingsec" &&
                  con.key_?.toLowerCase() != "icmpping"
              );

              ele.data["perdaPing"] = itens?.filter(
                (con) => con.key_?.toLowerCase() == "icmppingloss"
              );

              ele.data["snmp"] = itens?.filter(
                (con) => con.key_?.toLowerCase() == "snmp"
              );

              ele.data["latencia"] = itens?.filter(
                (con) => con.key_?.toLowerCase() == "icmppingsec"
              );
              ele.data["ping"] = itens?.filter(
                (con) => con.key_?.toLowerCase() == "icmpping"
              );
            });
            return [...elements];
          });

          let connectionsUpdate = responseData.flatMap(
            (ele) => ele.connections
          );

          setConnections((connections) => {
            return [
              ...connections.map((conn) => {
                let connectionUpdate = connectionsUpdate.find(
                  (connUp) => connUp.id == conn.id
                );
                if (!connectionUpdate) return conn;

                //adiciona as infomações da conexão em data
                if (!conn.data) conn["data"] = {};

                conn.data["nameTraffic"] = (
                  connectionUpdate?.itens?.find(
                    (con) =>
                      con.key_?.toLowerCase().includes("inoctets") ||
                      con.key_?.toLowerCase().includes("outoctets")
                  )?.name || null
                )

                  ?.replace(" de Entrada", "")
                  ?.replace(" de Saída", "");

                conn.data["inputTraffic"] =
                  connectionUpdate?.itens?.find((con) =>
                    con.key_?.toLowerCase().includes("inoctets")
                  )?.lastvalue || 0;

                conn.data["outputTraffic"] =
                  connectionUpdate?.itens?.find((con) =>
                    con.key_?.toLowerCase().includes("outoctets")
                  )?.lastvalue || 0;

                conn.data["units"] =
                  connectionUpdate?.itens?.find((con) =>
                    con.key_?.toLowerCase().includes("inoctets")
                  )?.units || null;

                conn.data["status"] = connectionUpdate?.status;
                conn.data["itens"] =
                  connectionUpdate?.itens
                    ?.filter(
                      (con) =>
                        !con.key_?.toLowerCase().includes("inoctets") &&
                        !con.key_?.toLowerCase().includes("outoctets")
                    )
                    ?.map((item) => ({
                      name: item.name,
                      lastValue: item.lastvalue,
                      units: item.units,
                    })) || [];

                return conn;
              }),
            ];
          });
        }
      });
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possível atualizar",
        severity: "error",
      });
    }
  }
  async function handleApiGetMaps() {
    try {
      const response = await api.get("/easyMaps/public/map/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          setMapas(
            response.data.map((map) => ({
              nome: map?.titulo,
              id: map?.id,
              tipo: map?.tipo,
            }))
          );
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function getIconDB() {
    try {
      const response = await api.get("/easyMaps/public/img/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível buscar os icons",
          severity: "error",
        });
      } else {
        setIconsElements(response.data);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Error: Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    } finally {
      // setHostLoading(false);
    }
  }

  const changeConnection = (edgeId, updateConfig, updateData) => {
    setCheckChanges(true);
    setConnections((cons) => {
      return cons.map((con) => {
        if (con.id === edgeId)
          return {
            ...con,
            ...updateData,
            config: {
              ...con?.config,
              ...updateConfig,
            },
          };
        else return con;
      });
    });
  };
  const handleCancelLink = () => {
    setConnections(
      connections?.map((con) => {
        if (con?.id === connectionSelected?.id) {
          return connectionSelected;
        } else {
          return con;
        }
      })
    );
    setConnectionSelected(null);
  };
  const addMapInMaps = (map) => {
    setMapas([...mapas, { nome: map?.titulo, tipo: map?.tipo, id: map?.id }]);
  };
  return (
    <>
      <Stack direction="row">
        <SliderBarMaps
          map={map}
          iconsElements={iconsElements}
          elements={elements}
          setFocusNodeId={setFocusNodeId}
        />
        <Stack
          sx={
            !fullScreem
              ? {
                  position: "relative",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }
              : styles.fullScreem
          }
          width="100%"
          height={"100%"}
        >
          {loadingMap && (
            <Box sx={styles.loadingMap}>
              <CircularProgress size="50px" />
              <Typography fontSize="14px">
                Carregando dados do mapa...
              </Typography>
            </Box>
          )}{" "}
          <ReactFlowMapas
            setEasyMapsConfig={setEasyMapsConfig}
            easyMapsConfig={easyMapsConfig}
            connections={connections}
            setEdgeSelected={setEdgeSelected}
            connectionsAnalog={connectionsAnalog}
            elements={elements}
            elementSelected={elementSelected}
            setElementSelected={setElementSelected}
            setElementsConnected={setElementsConnected}
            connectionSelected={connectionSelected}
            setConnectionSelected={setConnectionSelected}
            iconsElements={iconsElements}
            setOpenRightBar={setOpenRightBar}
            setLoadingPage={setLoadingMap}
            focusNodeId={focusNodeId}
            setFullScreem={setFullScreem}
            fullScreem={fullScreem}
            setSnackbar={setSnackbar}
            mapId={id}
            title={map?.titulo}
            checkChanges={checkChanges}
            setCoordenadasFlow={setCoordenadasFlow}
            setElements={setElements}
            setConnections={setConnections}
            edgeSelected={edgeSelected}
            coordenadasFlow={coordenadasFlow}
            elementsConnected={elementsConnected}
            openRightBar={openRightBar}
            setFocusNodeId={setFocusNodeId}
            socketCliente={socket}
            mapas={mapas}
            addMapInMaps={addMapInMaps}
            produtos={produtos}
            changeConnection={changeConnection}
            setCheckChanges={setCheckChanges}
            updateDataElementSocket={updateDataElementSocket}
            loadinButtonEnlace={loadinButtonEnlace}
          />
          {/* {false && (
            <MenuMaps
              setElementSelected={setElementSelected}
              iconsElement={iconsElements.filter((icon) =>
                icon?.nome?.startsWith("_")
              )}
              setConnectionSelected={setConnectionSelected}
            />
          )} */}
        </Stack>
      </Stack>
      <AlertModal
        openModal={openAlertModal?.open ?? false}
        setOpenModal={(data) => setOpenAlertModal({ open: data })}
        severity={openAlertModal?.severity}
        buttonText={openAlertModal.buttonText}
        buttonCancelText={openAlertModal.buttonCancelText}
        textContent={openAlertModal?.text}
        handleButton={() => {
          setOpenAlertModal({ open: false });
          navigate(-1);
        }}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}{" "}
    </>
  );
};
export default MapaTopologiaPublic;
