import { alpha, createTheme } from "@mui/material";
//import { colors } from "./colors";
import { dark, light } from "@mui/material/styles/createPalette";
const colors = {
  primary: {
    light: "#FB7500",
    dark: "#ED7B00",
  },
  secondary: {
    light: "#000000",
    dark: "#FFFFFF",
  },
};
const easyReportTheme = (colorMode) =>
  createTheme({
    palette: {
      mode: !colorMode ? "light" : "dark",
      primary: {
        main: !colorMode ? colors.primary.light : colors.primary.dark,
      },
      secondary: {
        main: !colorMode ? colors.secondary.light : colors.secondary.dark,
      },
      background: {
        default: !colorMode ? "#f6f6f6" : "#141617",
        textField: !colorMode ? "#E3E3E3" : "#2F2F2F",
        card: !colorMode ? "#FFFFFF" : "#272727",
        paper: !colorMode ? "#FFFFFF" : "#1E1E1E",
        switch: !colorMode ? "#d6d6d6" : "#9c9c9c",
      },
      text: {
        primary: !colorMode ? "#3C3C3C" : "#ECECEC",
        secondary: !colorMode ? "#757575" : "#9A9A9A",
        disabled: !colorMode ? "#9E9E9E" : "#878686",
      },
      success: {
        main: "#04B55D",
      },
      warning: {
        main: "#FFBB00",
      },
      error: {
        main: "#FF4130",
      },
      info: {
        main: "#00B8D9",
      },
      color: {
        //links: !colorMode ? colors.lightOrange[600] : colors.darkOrange[400],
        links: !colorMode
          ? alpha(colors.primary.light, 0.8)
          : alpha(colors.primary.dark, 0.8),
        //icons: !colorMode ? colors.lightBlue[100] : colors.darkBlue[100],
        icons: !colorMode
          ? alpha(colors.primary.light, 0.1)
          : alpha(colors.primary.dark, 0.1),
        stroke: !colorMode ? "#D8D8D8" : "#3A3A3A",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        fh: 1920,
        qhd: 2560,
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: ({ theme }) => ({
            "& ::placeholder": {
              color: theme.palette.text.primary,
            },
            "& .MuiInputLabel-root": {
              fontSize: "1rem", // Tamanho do texto do label
            },
            "& .MuiInputBase-input": {
              fontSize: "1rem", // Tamanho do texto do placeholder
              display: "flex",
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "transparent",
              },
              boxShadow: "0px 0px 2px  #0000001a",
              backgroundColor: theme.palette.background.textField,

              "&.Mui-focused": {
                backgroundColor: "none",
                "& fieldset": {
                  border: `1px solid  ${theme.palette.text.disabled}`,
                },
                "&:hover": {
                  "& fieldset": {
                    border: `1px solid  ${theme.palette.text.disabled}`,
                  },
                },
              },

              "&:hover": {
                backgroundColor: theme.palette.background.textField,
                "& fieldset": {
                  borderColor: "transparent",
                },
              },
              "&.Mui-disabled": {
                backgroundColor: alpha(theme.palette.background.paper, 0.2),
                "& fieldset": {
                  borderColor: "transparent",
                },
                "&:hover": {
                  "& fieldset": {
                    borderColor: "none",
                  },
                },
              },
            },
          }),
        },
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "contained" },
            style: {
              color: colorMode ? colors.secondary.light : colors.secondary.dark,
            },
          },
          {
            props: { variant: "smoth" },
            style: {
              backgroundColor: !colorMode
                ? alpha(colors.primary.light, 0.1)
                : alpha(colors.primary.dark, 0.1),
              color: !colorMode ? colors.primary.light : colors.primary.dark,
            },
          },
        ],
        styleOverrides: {
          root: {
            textTransform: "capitalize",
            boxShadow: "none",
            borderRadius: "5px",
            "&:hover": {
              boxShadow: "none",
            },
          },
        },
      },
      MuiPaper: {
        variants: [
          {
            props: { variant: "default" },
            style: {
              border: "none",
              //boxShadow: "0px 1px 2px  #0000000c",
              boxShadow: "0px 0px 4px  #0000000c",
            },
          },
          {
            props: { variant: "card" },
            style: {
              border: "none",
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.10)",
            },
          },
          {
            props: { variant: "brick" },
            style: {
              display: "flex",
              border: "none",
              boxShadow: "0px 0px 4px 0px  rgba(0, 0, 0, 0.25)",
              padding: "6px",
            },
          },
        ],
        defaultProps: {
          variant: "default",
        },
        styleOverrides: {
          root: {
            borderRadius: "5px",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: ({ theme }) => ({
            "& button": {
              backgroundColor: theme.palette.background.textField,
              color: theme.palette.text.secondary,
              textTransform: "none",
              paddingBottom: "30px",
              borderRadius: "4px",
            },
            "& .MuiTabs-flexContainer": {
              height: "42px",
            },
            "& button.Mui-selected": {
              backgroundColor: theme.palette.background.paper,
              color: theme.palette.text.primary,
            },
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.background.paper,
            },
          }),
        },
      },
    },
    mixins: {
      MuiDataGrid: {
        containerBackground: !colorMode ? "#fff" : "#1E1E1E",
      },
    },
  });

export default easyReportTheme;
