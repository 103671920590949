import {
  Paper,
  Stack,
  TextField,
  Divider,
  Fade,
  LinearProgress,
  Typography,
  useTheme,
  Button,
  Autocomplete,
  Drawer,
  Box,
  Tooltip,
  IconButton,
  Skeleton,
  Chip,
  CircularProgress,
  InputAdornment,
  alpha,
} from "@mui/material";

import React, {
  Fragment,
  useEffect,
  useState,
  useImperativeHandle,
  forwardRef,
  useCallback,
} from "react";
//icons
import TextFieldsIcon from "@mui/icons-material/TextFields";
import EditLocationAltOutlinedIcon from "@mui/icons-material/EditLocationAltOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import { ReactComponent as LinkedIcon } from "../../../assets/easyMaps/iconsSvg/Linked.svg";
import { ReactComponent as InfoIcon } from "../../../assets/easyMaps/iconsSvg/info.svg";
import { ReactComponent as ZabbixIcon } from "../../../assets/easyMaps/iconsSvg/zabbix.svg";
import { ReactComponent as Produto } from "../../../assets/easyMaps/iconsSvg/produto.svg";
import { ReactComponent as ItemIcon } from "../../../assets/easyMaps/iconsSvg/item.svg";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CardConnection from "./cardConnection";
import ModalIcon from "./modalAddIcons";
import ModalMap from "./modalAddMap";
import IconTransform from "../iconsElement";
import ListIcon from "./listIcons";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import NotListedLocationOutlinedIcon from "@mui/icons-material/NotListedLocationOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  AbcOutlined,
  Add,
  CancelOutlined,
  Check,
  DeleteOutline,
  ViewInArOutlined,
} from "@mui/icons-material";
import PainelIcons from "./painelIcons";

const CreationSliderBarEasyMaps = forwardRef(
  (
    {
      setOpenAlertModal = () => {},
      handleUpdatePositionElement = () => {},
      focusZoomCoordenates = () => {},
      iconsElement,
      setIconsElement,
      mapId,
      socket,
      element,
      connections = [],
      elements = [],
      setElements,
      setConnections,
      produtos = [],
      api,
      setSnackbar,
      setElementSelected,
      maps = [],
      addMapInMaps = () => {},
      fullScreem,
      tipoMapa = 0,
      coordenadasFlow,
      setFocusNodeId = () => {},
      checkChanges,
      setCheckChanges = () => {},
      updateDataElementSocket = () => {},
      deleteElementSocket = () => {},
      centerLeaflatMap = {},
      isPointInBounds = () => {
        return true;
      },
    },
    ref
  ) => {
    const theme = useTheme();
    //variveis default
    const tiposElementos = ["Host", "Mapa", "POP"];
    //variaveis do elemento
    const [connectionsElement, setConnectionsElement] = useState([]);
    const [connectionsElementOld, setConnectionsElementOld] = useState([]);
    const [elementName, setElementName] = useState(null);
    const [typeElement, setTypeElement] = useState(null);
    const [productElement, setProductElement] = useState(null);
    const [iconElement, setIconElement] = useState(null);
    const [mapRefElement, setMapRefElement] = useState(null);
    const [coordinatesLat, setCoordinatesLat] = useState(null);
    const [coordinatesLon, setCoordinatesLon] = useState(null);
    //variaveis zabbix
    const [hostsCliente, setHostsCliente] = useState([]);
    const [itensConfig, setItensConfig] = useState([]);
    const [requestSend, setRequestSend] = useState(false);
    const [hostsConfigOld, setHostsConfigOld] = useState([]);
    const [itensHost, setItensHost] = useState([]);
    //outras variaveis

    const [hostsConfig, setHostsConfig] = useState([]);
    const [expanded, setExpanded] = useState(null);
    const [buttonHostDisable, setButtonHostDisable] = useState(true);
    const [hostLoading, setHostLoading] = useState(false);
    const [itensLoading, setItensLoading] = useState(false);
    const [openModalMap, setOpenModalMap] = useState(false);
    const [coordenatesValid, setCoordenatesValid] = useState(false);

    const styles = {
      textAdorment: {
        ml: "-5px",
        fontSize: "12px",
        fontWeight: 800,
        color: theme.palette.color.bgIcon,
      },
      removeIncDec: {
        "& input[type=number]": {
          MozAppearance: "textfield", // Remove as setas no Firefox
        },
        "& input[type=number]::-webkit-outer-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      },
      buttonAdd: {
        width: "max-content",
        padding: "2px 10px",
        justifyContent: "flex-start",
        textTransform: "none",
        fontWeight: 400,
        mt: "-4px",
        mb: "-2px",

        color: theme.palette.mode == "dark" ? "#ff8282" : "none",
      },
      buttonBottom: {
        width: "33%",
        textTransform: "none",
      },

      text: {
        fontSize: "0.875rem",
        color: theme.palette.color.text,
      },
      textPaperTitle: {
        fontSize: "18px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "200px",
      },
      paper: {
        borderRadius: "0px",
        height: "100vh",
        position: "sticky",
        width: "340px!important",
        backgroundColor: theme.palette.background.sideBar,
      },
      card: {
        borderRadius: "5px",
        backgroundColor: theme.palette.background.paperSelectedMaps,
        boxShadow: "0px 0px 3px  #0000001a",
        border: "none",
        "&::before": {
          display: "none",
        },
      },
      header: {
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "start",
        gap: 2,
        p: "15px",
      },
      container: {
        height: "70vh",
        overflow: "auto",
        paddingRight: "5px",
        gap: 1.5,
      },
      drawer: {
        position: "absolute",

        clipPath: "none",
        marginTop: "65px",
        flexShrink: 0,
        overflow: "visible",
        "& .MuiDrawer-paper": {
          //zIndex: 1202,
          //boxShadow: "0px 0px 10px  #0000001a",
          borderRadius: "7px",
          color: theme.palette.text.primary,
          mr: "10px",
          height: "calc(100vh - 85px)",
          width: "350px",
          padding: "10px 10px",
          marginTop: fullScreem ? "" : "75px",
          overflow: "visible",
          backgroundColor: theme.palette.background.sideBar,
          border: "1px solid " + theme.palette.color.appBarBorder,
          display: "flex",
          gap: 2,
        },
      },
    };

    useImperativeHandle(ref, () => ({
      update() {
        updateElement();
      },
    }));

    useEffect(() => {
      if (typeElement == "Host") return;
      setElementName(mapRefElement?.nome);
    }, [mapRefElement]);

    useEffect(() => {
      if (connections?.length == 0 || !element?.id) {
        setConnectionsElement([]);
        setConnectionsElementOld([]);
        return;
      }
      let con = connections?.filter((a) => a.referenceId == element.id);

      setConnectionsElement(con);
      setConnectionsElementOld(con);
    }, [connections, element]);

    useEffect(() => {
      if (!element) return;
      if (!element?.elementConfig?.config?.coordenadas && tipoMapa == 0) {
        handleUpdatePositionElement(null, centerLeaflatMap);
        setCoordinatesLat(centerLeaflatMap?.lat);
        setCoordinatesLon(centerLeaflatMap.lng);
      } else {
        setCoordinatesLat(element?.elementConfig?.config?.coordenadas?.lat);
        setCoordinatesLon(element?.elementConfig?.config?.coordenadas?.long);
      }
    }, [element]);

    useEffect(() => {
      setCheckChanges(false);

      if (!element) return;

      if (!element.id) {
        clean();
      }

      setTypeElement(tiposElementos[element?.tipo]);
      setIconElement(
        iconsElement?.find((icon) => icon.id == element.img) ||
          iconsElement?.find(
            (icon) =>
              icon.nome ==
              (element.tipo === 0
                ? "_roteador"
                : element.tipo === 1
                ? "_mapa"
                : "_pop")
          )
      );

      if (element.id) {
        setElementName(element?.titulo);
        if (element.mapRefId == null && element.tipo == 0) {
          setHostsConfig(element?.elementConfig?.config?.hosts);
          setItensConfig(element?.elementConfig?.config?.hosts[0]?.itens || []);
        } else {
          setHostsConfig(
            element?.mapRef?.Element?.flatMap(
              (ele) => ele.elementConfig?.config?.hosts
            )
          );
          setItensHost([]);
          setItensConfig([]);
        }
        setMapRefElement(maps?.find((map) => map.id == element?.mapRefId));

        setProductElement(
          produtos.find(
            (produto) => produto.id == element?.elementConfig?.config?.produtoId
          )
        );
        setCoordinatesLat(element?.elementConfig?.config?.coordenadas?.lat);
        setCoordinatesLon(element?.elementConfig?.config?.coordenadas?.long);
      } else {
        if (produtos?.length == 1) {
          setProductElement(produtos[0]);
          if (typeElement == "Host") {
            getHosts(produtos[0]?.AcessoLink[0]?.link);
          }
        } else {
          setProductElement(null);
        }
      }
    }, [element?.id, element?.tipo]);
    //fuções
    useEffect(() => {
      if (productElement == null) {
        setHostsCliente([]);
        setHostsConfig([]);
        setItensConfig([]);
        setButtonHostDisable(true);
      }
      if (
        productElement != null &&
        Array.isArray(productElement?.AcessoLink) &&
        productElement?.AcessoLink?.length > 0 &&
        productElement?.AcessoLink[0]?.link != undefined &&
        typeElement == "Host"
      ) {
        setButtonHostDisable(true);
        getHosts(productElement?.AcessoLink[0]?.link);
      }
    }, [productElement, typeElement]);

    useEffect(() => {
      if (hostsConfig?.length == 0) {
        setItensHost([]);
        setConnectionsElement((data) => {
          data?.map((conection) => {
            conection.item = [];
          });
          return [...data];
        });
      }
      //remove os items que não tem host seleciocionado
      setItensHost((data) => {
        let filter = data?.filter((item) => {
          return hostsConfig?.some((host) => {
            return host?.hostid == item?.hostid;
          });
        });
        return [...filter];
      });
      //adiciona itens de novos host selecionados
      hostsConfig?.map(async (host) => {
        getItens(host?.hostid);
      });

      setHostsConfigOld(hostsConfig?.map((host) => ({ hostid: host?.hostid })));
    }, [hostsConfig]);

    useEffect(() => {
      if (isPointInBounds({ lat: coordinatesLat, lng: coordinatesLon }))
        setCoordenatesValid(true);
      else {
        setCoordenatesValid(false);
      }
    }, [coordinatesLat, coordinatesLon]);

    const validHost = useCallback(() => {
      return Array.isArray(hostsConfig) &&
        hostsConfig?.length > 0 &&
        hostsConfig[0]
        ? hostsConfig[0]
        : [];
    }, [hostsConfig]);

    async function getHosts(link) {
      if (!link) return;
      setHostLoading(true);
      try {
        const response = await api.post("/easyMaps/zabbix/hosts", {
          link: link,
        });
        if (response.data.status === "Error") {
          setSnackbar({
            children: "Error: Não foi possível buscar os hosts",
            severity: "error",
          });
        } else {
          setButtonHostDisable(false);
          setHostsCliente(response.data);
        }
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setHostLoading(false);
      }
    }

    async function getItens(hostid) {
      if (hostsConfig?.length == 0) return;
      setItensLoading(true);
      try {
        const response = await api.post("/easyMaps/zabbix/itens", {
          link: productElement?.AcessoLink[0]?.link,
          hostids: hostid,
        });
        if (response.data.status === "Error") {
          setSnackbar({
            children: "Error: Não foi possível buscar os hosts",
            severity: "error",
          });
        } else {
          setItensHost((data) => [...data, ...response.data]);
        }
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setItensLoading(false);
      }
    }

    async function handleApiGetElements(mapId) {
      setHostsConfig([]);
      setItensLoading(true);
      setHostLoading(true);
      try {
        //setLoadingProdutos(true);
        const response = await api.post("/easyMaps/element/findMany", {
          mapId,
        });
        if (response.data.status === "Error") {
          setSnackbar({
            children: "Error: Não foi possível buscar o mapa",
            severity: "error",
          });
        } else {
          const element = response.data.responseAlerts;

          setHostsConfig(
            element
              ?.filter((ele) => ele.tipo == 0)
              ?.flatMap((map) => map.elementConfig?.config?.hosts)
          );
        }
      } catch (error) {
        console.error(error);
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setHostLoading(false);
        setItensLoading(false);
      }
    }

    function createElement() {
      setRequestSend(true);
      if (!isValidate()) return;

      let logs = JSON.parse(localStorage.getItem("logs"));
      let data = {
        titulo: elementName,
        tipo: tiposElementos.indexOf(typeElement),
        img: iconElement.id,
        mapRefId: mapRefElement?.id || null,
        configuracao: {
          coordenadas:
            tipoMapa == 0
              ? {
                  lat: coordinatesLat || centerLeaflatMap?.lat,
                  long: coordinatesLon || centerLeaflatMap?.lng,
                }
              : {
                  lat: coordenadasFlow?.y
                    ? coordenadasFlow?.y + Math.floor(Math.random() * 21) - 10
                    : 0,
                  long: coordenadasFlow?.x
                    ? coordenadasFlow?.x + Math.floor(Math.random() * 21) - 10
                    : 0,
                },
          hosts:
            typeElement === "Host"
              ? [{ ...validHost(), itens: itensConfig }]
              : hostsConfig,
          produtoId: productElement?.id,
        },
        mapId: mapId,
        usuarioClienteId: logs.usuarioClienteId,
        logsLoginClienteId: logs.id,
        connections: connectionsElement?.filter(
          (con) => con.destinationId != "" && con.destinationId != null
        ),
      };

      try {
        socket.emit("createElement", data, (response) => {
          if (response.status !== 201) {
            setSnackbar({
              children: "Error: Não foi possível criar o elemento",
              severity: "error",
            });
          } else {
            updateDataElementSocket();
            setSnackbar({
              children: "Elemento criado com sucesso!",
              severity: "success",
            });
            closeDrawer();

            let newElement = response.data.element;
            let newLinks = response.data.element.connections;
            setElements((data) => [newElement, ...data]);

            if (newLinks) setConnections((data) => [...newLinks, ...data]);
            setFocusNodeId(response.data.element?.id);
            let cood = newElement.elementConfig.config.coordenadas;
            focusZoomCoordenates(cood.lat, cood.long);
          }
        });
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setCheckChanges(false);
      }
    }

    async function updateElement() {
      setRequestSend(true);

      if (!isValidate()) return;

      //verificar o que foi alterado nas conexões
      let arrayDelete = [];
      //verifica se houve delete
      connectionsElementOld?.map((connectionOld) => {
        if (!connectionsElement?.some((con) => con.id == connectionOld.id)) {
          arrayDelete.push(connectionOld.id);
        }
      });

      // atualização do elemento puro
      let logs = JSON.parse(localStorage.getItem("logs"));
      let data = {
        id: element.id,
        titulo: elementName,
        mapRefId: mapRefElement?.id || null,
        tipo: tiposElementos.indexOf(typeElement),
        img: iconElement.id,
        configuracao: {
          coordenadas:
            tipoMapa == 0
              ? {
                  lat:
                    coordinatesLat == "" || !coordinatesLat
                      ? centerLeaflatMap?.lat
                      : coordinatesLat,
                  long:
                    coordinatesLon == "" || !coordinatesLon
                      ? centerLeaflatMap?.lng
                      : coordinatesLon,
                }
              : {
                  lat: coordinatesLat,
                  long: coordinatesLon,
                },
          hosts:
            typeElement === "Host"
              ? [{ ...validHost(), itens: itensConfig }]
              : hostsConfig,
          produtoId: productElement?.id,
        },
        connections: {
          updates: connectionsElement?.filter(
            (con) => con.destinationId != "" && con.destinationId != null
          ),
          deletes: arrayDelete,
        },
        usuarioClienteId: logs.usuarioClienteId,
        logsLoginClienteId: logs.id,
      };
      try {
        socket.emit("updateElement", data, (response) => {
          if (response.status !== 201) {
            setSnackbar({
              children: "Error: Não foi possível atualizar o elemento",
              severity: "error",
            });
          } else {
            updateDataElementSocket();
            setSnackbar({
              children: "Elemento atualizado com sucesso!",
              severity: "success",
            });
            setRequestSend(false);

            let updateElement = response.data.element;
            let updatedLinks = response.data.updatedLinks;

            setElements((elements) => {
              return elements.map((ele) =>
                ele.id === updateElement.id
                  ? { ...updateElement, alerts: ele.alerts }
                  : ele
              );
            });

            setConnections((con) => {
              con = con?.filter((c) => c.referenceId !== element.id);
              return [
                ...updatedLinks?.filter((link) => link?.id != null),
                ...con,
              ];
            });
          }
        });
      } catch (error) {
        setSnackbar({
          children: "Error: Não foi possível se conectar com o servidor",
          severity: "error",
        });
      } finally {
        setCheckChanges(false);
      }
    }

    function addConexao() {
      setConnectionsElement((data) => [...data, {}]);
      setExpanded(connectionsElement?.length);
    }

    function removeConexao(id) {
      setConnectionsElement((data) => {
        return [...data?.filter((con) => con.id !== id)];
      });
    }

    function updateConexao(index, host) {
      setConnectionsElement((data) => {
        data[index] = host;
        return [...data];
      });
    }
    function clean() {
      setRequestSend(false);
      setConnectionsElement([]);
      setElementName(null);
      setHostsConfig([]);
      setItensConfig([]);
      setProductElement(null);
      setMapRefElement(null);
      setExpanded(null);
      setMapRefElement(null);
      setIconElement(null);
      setCoordinatesLon(null);
      setCoordinatesLat(null);
    }
    function closeDrawer() {
      setElementSelected(null);
      clean();
    }

    const isOptionEqualToValue = (option, value) => {
      return option?.hostid === value?.hostid;
    };
    const isOptionEqualToValueNome = (option, value) => {
      return option?.nome === value?.nome;
    };

    const isValidate = () => {
      if (typeElement === "Mapa" || typeElement === "POP") {
        return (
          mapRefElement != null &&
          elementName != "" &&
          elementName != null &&
          typeElement != null &&
          productElement != null &&
          iconElement != null &&
          (tipoMapa == 0 ? coordenatesValid : true)
        );
      } else {
        return (
          elementName != "" &&
          elementName != null &&
          hostsConfig?.length != 0 &&
          hostsConfig[0] != null &&
          typeElement != null &&
          productElement != null &&
          iconElement != null &&
          (tipoMapa == 0 ? coordenatesValid : true)
        );
      }
    };
    const mapsFilter = (maps, tipo) => {
      return tipo == "POP"
        ? maps?.filter(({ tipo, id }) => tipo == 2 && id != mapId)
        : maps?.filter(({ tipo, id }) => tipo != 2 && id != mapId);
    };
    const selectMapRef = (map) => {
      const newValue = { nome: map?.titulo, tipo: map?.tipo, id: map?.id };
      setMapRefElement(newValue);
      setElementName(newValue?.nome);
      if (newValue) handleApiGetElements(newValue.id);
      else setHostsConfig([]);
    };
    useEffect(() => {
      if (element === null) {
        clean();
      }
    }, [element]);

    const handleOnDeleteIcon = (id) => {
      setOpenAlertModal({
        open: true,
        severity: "error",
        text: "Essa ação apagará o icon do banco de forma irreversível.\n Deseja continuar?",
        callback: async () => {
          try {
            const response = await api.put("easyMaps/img/delete", { id });
            if (response.data.status === "Error") {
              setSnackbar({
                children: "Error: não foi possível deletar o icon",
                severity: "error",
              });
            } else {
              setIconsElement((icons) => {
                let newicons = icons.filter((a) => a.id != id);
                return [...newicons];
              });
              setSnackbar({
                children: "Icone excluido com sucesso",
                severity: "success",
              });
            }
          } catch (error) {
            console.log(error);
            setSnackbar({
              children: "Error: Não foi possivel se conectar com o servidor",
              severity: "error",
            });
          }
        },
      });
    };
    function verificarIpPosicao(string) {
      const padraoIpv4 = /\b(?:\d{1,3}\.){3}\d{1,3}\b/g;
      const padraoIpv6 = /\b(?:[0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}\b/g;
      let match;

      // Verificar IPv4 primeiro
      if ((match = padraoIpv4.exec(string)) !== null) {
        return match.index;
      }
      // Verificar IPv6 se não encontrou IPv4
      if ((match = padraoIpv6.exec(string)) !== null) {
        return match.index;
      }
      return -1;
    }
    return (
      <>
        <Drawer
          key={"creationBar"}
          variant="persistent"
          anchor={"right"}
          open={!!element}
          //onClose={() => closeDrawer()}
          sx={styles.drawer}
        >
          <Stack sx={[styles.header, styles.card]}>
            <IconTransform img={iconElement?.img} size="35px" />
            <Divider orientation="vertical" />
            <Stack>
              <Tooltip title={elementName}>
                <Typography sx={styles.textPaperTitle}>
                  {elementName ? elementName : "Novo elemento"}
                </Typography>
              </Tooltip>
              <Typography
                sx={{
                  fontSize: "12px",
                  mt: "-5px",
                  color: theme.palette.color.textDisable,
                }}
              >
                {typeElement}
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={styles.container}>
            <Stack direction="row" gap={2} alignItems="center">
              <Produto
                style={{
                  fill: theme.palette.primary.main,
                  height: "24px",
                  width: "24px",
                }}
              />
              <Typography sx={styles.text}>
                Selecione o produto de origem
              </Typography>
            </Stack>
            <Autocomplete
              getOptionLabel={(option) => option?.nome}
              disabled={produtos?.length == 0}
              value={productElement || null}
              onChange={(event, newValue) => {
                setCheckChanges(true);
                setProductElement(newValue);
              }}
              size="small"
              options={produtos || []}
              renderInput={(params) => {
                return (
                  <>
                    <TextField
                      {...params}
                      error={requestSend && productElement == null}
                      slotProps={{
                        input: {
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <ZabbixIcon
                                style={{
                                  fill: theme.palette.color.bgIcon,
                                  height: "20px",
                                  width: "20px",
                                }}
                              />
                            </InputAdornment>
                          ),
                        },
                      }}
                      helperText={
                        requestSend &&
                        productElement == null &&
                        "O produto precisa ser indicado!"
                      }
                      color="primary"
                    />
                  </>
                );
              }}
            />
            <Stack direction="row" gap={2} alignItems="center">
              <SettingsOutlinedIcon color="primary" />
              <Typography sx={styles.text}>Selecione o tipo</Typography>
            </Stack>
            <Autocomplete
              disablePortal
              value={typeElement || null}
              onChange={(event, newValue) => {
                setCheckChanges(true);
                setMapRefElement(null);
                setHostsConfig([]);
                setTypeElement(newValue);
              }}
              id="combo-box-demo"
              size="small"
              color="primary"
              options={tiposElementos}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Tipo de elemento"
                  error={requestSend && typeElement == null}
                  helperText={
                    requestSend &&
                    typeElement == null &&
                    "O tipo não pode ser nulo!"
                  }
                  color="primary"
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      startAdornment: (
                        <Box sx={{ mr: "-5px" }}>
                          <InputAdornment position="start">
                            {typeElement == null && (
                              <SettingsOutlinedIcon
                                sx={{
                                  color: theme.palette.color.bgIcon,
                                  fontSize: "20px",
                                }}
                              />
                            )}
                            {typeElement == "Host" && (
                              <IconTransform
                                img={
                                  iconsElement.find(
                                    (icon) => icon.nome == "_roteador"
                                  )?.img
                                }
                                size="20px"
                                color={theme.palette.color.bgIcon}
                              />
                            )}
                            {typeElement == "Mapa" && (
                              <IconTransform
                                img={
                                  iconsElement.find(
                                    (icon) => icon.nome == "_mapa"
                                  )?.img
                                }
                                size="20px"
                                color={theme.palette.color.bgIcon}
                              />
                            )}
                            {typeElement == "POP" && (
                              <IconTransform
                                img={
                                  iconsElement.find(
                                    (icon) => icon.nome == "_pop"
                                  )?.img
                                }
                                size="20px"
                                color={theme.palette.color.bgIcon}
                              />
                            )}
                          </InputAdornment>
                        </Box>
                      ),
                    },
                  }}
                />
              )}
            />
            {typeElement == "Host" ? (
              <>
                <Stack direction="row" gap={2} alignItems="center">
                  <>
                    <StorageOutlinedIcon color="primary" />
                    <Typography sx={styles.text}>Selecione o host</Typography>
                  </>
                </Stack>

                <Autocomplete
                  disablePortal
                  disabled={buttonHostDisable}
                  getOptionLabel={(option) => option.host}
                  isOptionEqualToValue={isOptionEqualToValue}
                  id="combo-box-demo"
                  size="small"
                  color="primary"
                  options={hostsCliente}
                  value={
                    Array.isArray(hostsConfig) && hostsConfig.length > 0
                      ? hostsConfig[0]
                      : null
                  }
                  onChange={(event, newValue) => {
                    setCheckChanges(true);
                    //setItensLoading(true);
                    if (newValue == null) setHostsConfig([]);
                    else
                      setHostsConfig([
                        { ...newValue, hostname: newValue.host },
                      ]);

                    if (newValue?.hostid !== validHost()?.hostid)
                      setItensConfig([]);

                    //lógica para inseriro o nome do elemnto sem o ip
                    if (verificarIpPosicao(newValue?.host) != -1) {
                      setElementName(
                        newValue.host?.substring(
                          0,
                          verificarIpPosicao(newValue?.host) - 3
                        )
                      );
                    } else {
                      setElementName(newValue?.host);
                    }
                  }}
                  renderInput={(params) => (
                    <>
                      <TextField
                        {...params}
                        placeholder="Host"
                        error={
                          requestSend &&
                          (hostsConfig?.length == 0 || hostsConfig[0] == null)
                        }
                        helperText={
                          (requestSend &&
                            (hostsConfig?.length == 0 ||
                              hostsConfig[0] == null) &&
                            "O host não pode ser nulo!") ||
                          (buttonHostDisable &&
                            "Esse produto não tem hosts disponíveis")
                        }
                        color="primary"
                        slotProps={{
                          input: {
                            ...params.InputProps,
                            startAdornment: (
                              <Box sx={{ mr: "-5px" }}>
                                <InputAdornment position="start">
                                  <IconTransform
                                    img={
                                      iconsElement.find(
                                        (icon) => icon.nome == "_roteador"
                                      )?.img
                                    }
                                    size="20px"
                                    color={theme.palette.color.bgIcon}
                                  />
                                </InputAdornment>
                              </Box>
                            ),
                          },
                        }}
                      />
                      <Fade in={hostLoading}>
                        <LinearProgress sx={{ height: "2px" }} />
                      </Fade>
                    </>
                  )}
                />
                <Stack direction="row" gap={2} alignItems="center">
                  <>
                    {/* <StorageOutlinedIcon color="primary" /> */}
                    <ItemIcon
                      style={{
                        fill: theme.palette.primary.main,
                        height: "24px",
                        width: "24px",
                      }}
                    />
                    <Typography sx={styles.text}>
                      Selecione os itens do host
                    </Typography>
                  </>
                </Stack>

                <Autocomplete
                  multiple
                  key="itensAutocomplete"
                  disableCloseOnSelect
                  //sx={{ width: "245px" }}
                  disabled={typeElement != "Host" && validHost() == null}
                  //disablePortal
                  id="itensHost"
                  size="small"
                  color="primary"
                  options={
                    typeElement == "Host"
                      ? itensHost
                      : itensHost?.filter(
                          (item) => item?.hostid === validHost()?.hostid
                        )
                  }
                  value={itensConfig}
                  onChange={(event, newValue) => {
                    setItensConfig(newValue);
                  }}
                  renderTags={(value, getTagProps) => {
                    return value?.map((option, index) => (
                      <Tooltip
                        title={option.name}
                        key={option.itemid + "toolItens"}
                      >
                        <Chip label={option.name} {...getTagProps({ index })} />
                      </Tooltip>
                    ));
                  }}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) =>
                    option.itemid === value.itemid
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option.itemid + option.name + "itens"}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => {
                    return (
                      <>
                        {" "}
                        <TextField
                          {...params}
                          key="itensAutoText"
                          color="primary"
                          placeholder={itensConfig.length == 0 ? "Itens" : null}
                          helperText={
                            typeElement == "Host" && !itensLoading
                              ? itensHost?.length == 0 &&
                                "Esse elemento não contém itens"
                              : itensHost?.filter(
                                  (item) => item.hostid === validHost()?.hostid
                                )?.length == 0 &&
                                validHost() != null &&
                                !itensLoading &&
                                "O host selecionado não contém itens"
                          }
                          slotProps={{
                            input: {
                              ...params.InputProps,
                              startAdornment: (
                                <Stack
                                  direction={"row"}
                                  justifyContent={"center"}
                                  alignItems={"center"}
                                >
                                  <InputAdornment position="start">
                                    <ViewInArOutlined
                                      sx={{
                                        color: theme.palette.color.bgIcon,
                                        fontSize: "20px",
                                      }}
                                    />
                                  </InputAdornment>
                                  <Stack
                                    sx={{
                                      maxWidth: "200px",
                                    }}
                                  >
                                    {params.InputProps.startAdornment}
                                  </Stack>
                                </Stack>
                              ),
                            },
                          }}
                        />{" "}
                        <Fade in={itensLoading}>
                          <LinearProgress sx={{ height: "2px" }} />
                        </Fade>
                      </>
                    );
                  }}
                />
              </>
            ) : (
              <>
                <Stack direction={"column"} gap={1} alignItems={"start"}>
                  <Stack
                    direction="row"
                    gap={2}
                    alignItems="center"
                    width="100%"
                    mb={"5px"}
                  >
                    <MapOutlinedIcon color="primary" />
                    <Typography sx={styles.text}>
                      Selecione o mapa de referência
                    </Typography>
                  </Stack>

                  <Autocomplete
                    disablePortal
                    sx={{ width: "100%" }}
                    value={mapRefElement || null}
                    getOptionLabel={(option) => option?.nome || ""}
                    isOptionEqualToValue={isOptionEqualToValueNome}
                    onChange={(event, newValue) => {
                      setCheckChanges(true);

                      setMapRefElement(newValue);
                      setElementName(newValue?.nome);
                      if (newValue) handleApiGetElements(newValue.id);
                      else setHostsConfig([]);
                    }}
                    id="combo-box-demo"
                    size="small"
                    color="primary"
                    options={
                      Array.isArray(maps) ? mapsFilter(maps, typeElement) : []
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={requestSend && mapRefElement == null}
                        helperText={
                          requestSend &&
                          mapRefElement == null &&
                          "A referência do mapa não pode ser nula!"
                        }
                        color="primary"
                        placeholder="Mapa de referência"
                        slotProps={{
                          input: {
                            ...params.InputProps,
                            startAdornment: (
                              <Box sx={{ mr: "-5px" }}>
                                <InputAdornment position="start">
                                  <IconTransform
                                    img={
                                      iconsElement.find(
                                        (icon) => icon.nome == "_mapa"
                                      )?.img
                                    }
                                    size="20px"
                                    color={theme.palette.color.bgIcon}
                                  />
                                </InputAdornment>
                              </Box>
                            ),
                          },
                        }}
                      />
                    )}
                  />
                  <Button
                    startIcon={<AddOutlinedIcon />}
                    variant="text"
                    onClick={() => setOpenModalMap(true)}
                    sx={styles.buttonAdd}
                  >
                    Criar novo mapa
                  </Button>
                </Stack>
              </>
            )}
            <Stack direction={"column"} gap={1} alignItems={"start"}>
              <Stack
                direction="row"
                gap={1}
                alignItems="center"
                width="100%"
                mb={"5px"}
              >
                <NotListedLocationOutlinedIcon color="primary" />
                <Typography sx={styles.text}>Ícone do elemento</Typography>
              </Stack>

              <PainelIcons
                iconsElement={iconsElement}
                setIconElement={setIconElement}
                setIconsElement={setIconsElement}
                api={api}
                setSnackbar={setSnackbar}
                handleOnDelete={(id) => handleOnDeleteIcon(id)}
                setCheckChanges={setCheckChanges}
              />
            </Stack>
            {typeElement == "Host" && (
              <>
                <Stack direction="row" gap={2} alignItems="center">
                  <TextFieldsIcon color="primary" />
                  <Typography sx={styles.text}>
                    Informe o nome do elemento
                  </Typography>
                </Stack>
                <TextField
                  autoComplete="off"
                  value={elementName || ""}
                  onChange={(value) => {
                    setCheckChanges(true);
                    setElementName(value.target.value);
                  }}
                  color="primary"
                  id="hostName"
                  placeholder="Nome do Elemento"
                  variant="outlined"
                  size="small"
                  error={
                    requestSend && (elementName == "" || elementName == null)
                  }
                  helperText={
                    requestSend &&
                    (elementName == "" || elementName == null) &&
                    "O nome não pode ser nulo!"
                  }
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <AbcOutlined
                            sx={{
                              ml: "-5px",
                              color: theme.palette.color.bgIcon,
                              fontSize: "22px",
                            }}
                          />
                        </InputAdornment>
                      ),
                    },
                  }}
                />
              </>
            )}
            {tipoMapa == 0 && (
              <>
                <Stack direction="row" gap={2} alignItems="center">
                  <EditLocationAltOutlinedIcon color="primary" />
                  <Typography sx={styles.text}>
                    Informe as coordenadas
                  </Typography>
                </Stack>
                <Stack direction="row" gap={1}>
                  <TextField
                    error={!coordenatesValid}
                    autoComplete="off"
                    value={coordinatesLat || ""}
                    onChange={(value) => {
                      setCheckChanges(true);
                      setCoordinatesLat(value.target.value);
                    }}
                    color="primary"
                    placeholder="Latitude"
                    variant="outlined"
                    size="small"
                    type="number"
                    sx={styles.removeIncDec}
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography sx={styles.textAdorment}>
                              LAT
                            </Typography>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />

                  <TextField
                    error={!coordenatesValid}
                    autoComplete="off"
                    value={coordinatesLon || ""}
                    onChange={(value) => {
                      setCheckChanges(true);
                      setCoordinatesLon(value.target.value);
                    }}
                    sx={styles.removeIncDec}
                    color="primary"
                    id="hostName"
                    placeholder="Longitude"
                    variant="outlined"
                    size="small"
                    type="number"
                    slotProps={{
                      input: {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Typography sx={styles.textAdorment}>
                              LNG
                            </Typography>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                </Stack>{" "}
                {!coordenatesValid && (
                  <Typography
                    sx={{
                      fontSize: "12px",
                      textAlign: "center",
                      mt: "-5px",
                      color: theme.palette.primary.main,
                    }}
                  >
                    O ponto precisa estar contido na área do mapa
                  </Typography>
                )}
              </>
            )}
            {/* <Stack direction="row" gap={2} alignItems="center">
                <LinkedIcon
                  style={{
                    fill: theme.palette.primary.main,
                    height: "24px",
                    width: "24px",
                  }}
                />
                <Typography sx={styles.text}>
                  Conexão(ões) de entrada
                </Typography>
              </Stack> */}
            <Stack direction="row" gap={2} alignItems="center">
              <LinkedIcon
                style={{
                  fill: theme.palette.primary.main,
                  height: "24px",
                  width: "24px",
                }}
              />
              <Typography sx={styles.text}>
                Selecione a(s) conexão(ões)
              </Typography>
            </Stack>
            {itensLoading && hostsConfig.length > 0 && (
              <Skeleton variant="rounded" width="100%" height={50} />
            )}
            {!itensLoading && connectionsElement?.length > 0 && (
              <Stack gap={1}>
                {connectionsElement?.map((connection, index) => (
                  <CardConnection
                    key={`connection-${connection?.id}-${connectionsElement?.length}-${element?.id}`}
                    idElement={element?.id}
                    hostsConfig={hostsConfig}
                    typeElement={typeElement}
                    id={connection.id}
                    productId={productElement?.id}
                    index={index}
                    connection={connection}
                    elements={elements}
                    itens={itensHost}
                    updateConexao={updateConexao}
                    expanded={expanded}
                    setExpanded={setExpanded}
                    removeConexao={removeConexao}
                    coordinatesLat={coordinatesLat}
                    coordinatesLon={coordinatesLon}
                  />
                ))}
              </Stack>
            )}
            <Tooltip
              title={
                (hostsConfig?.length == 0 && "Nenhum host foi selecionado") ||
                (itensHost?.length == 0 &&
                  "O host selecionado não contém itens de tráfico")
              }
              disableHoverListener={
                !itensHost?.length == 0 || !hostsConfig?.length == 0
              }
            >
              <span>
                <Button
                  disabled={itensHost?.length == 0}
                  sx={{
                    width: "150px",
                    color: theme.palette.color.text,
                    textTransform: "none",
                  }}
                  color="primary"
                  variant="text"
                  onClick={addConexao}
                  startIcon={<AddOutlinedIcon />}
                >
                  Nova conexão
                </Button>
              </span>
            </Tooltip>
          </Stack>{" "}
          <Divider />
          <Stack
            direction={"row"}
            sx={{ mt: "-10px" }}
            justifyContent={"space-between"}
            gap={2}
          >
            {element?.id && (
              <Button
                sx={styles.buttonBottom}
                color="primary"
                variant="text"
                //endIcon={<DeleteOutline />}
                startIcon={<DeleteOutline />}
                onClick={() => {
                  deleteElementSocket(element.id);
                }}
              >
                {"Remover"}
              </Button>
            )}
            <Button
              sx={styles.buttonBottom}
              color="primary"
              variant="text"
              startIcon={<CancelOutlined />}
              onClick={() =>
                checkChanges
                  ? setOpenAlertModal({
                      open: true,
                      severity: "warning",
                      text: "As alterações feitas não foram salvas. Se prosseguir com a operação as mudanças serão perdidas.",
                    })
                  : closeDrawer()
              }
            >
              {"Cancelar"}
            </Button>

            <Button
              disabled={itensLoading || !isValidate()}
              sx={[
                styles.buttonBottom,
                {
                  color: theme.palette.text.primary,
                  "&:hover": {
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  },
                },
              ]}
              variant="text"
              startIcon={!element?.id ? <SaveOutlinedIcon /> : <Check />}
              onClick={() => {
                element?.id ? updateElement() : createElement();
                closeDrawer();
              }}
            >
              {element?.id ? "Atualizar" : "Adicionar"}
            </Button>
          </Stack>
        </Drawer>

        <ModalMap
          setOpen={setOpenModalMap}
          open={openModalMap}
          type={typeElement}
          api={api}
          addMapInMaps={addMapInMaps}
          selectMapRef={selectMapRef}
          setSnackbar={setSnackbar}
        />
      </>
    );
  }
);

export default CreationSliderBarEasyMaps;
