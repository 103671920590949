import {
  CancelOutlined,
  CheckCircleOutline,
  WarningAmberOutlined,
} from "@mui/icons-material";
import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";

export default function StatusCard({ status, tipo = "ping" }) {
  const theme = useTheme();
  const styles = {
    statusCard: {
      backgroundColor: theme.palette.background.paperLinkedMaps,

      paddingX: "10px",
      paddingY: "15px",
      display: "flex",
      alignItems: "center",
      gap: 2,
    },
    line: { backgroundColor: "#FFFFFF", width: "3px", height: "60px" },
  };
  return (
    <Paper sx={styles.statusCard} elevation={0} variant="miniCards">
      <Box
        sx={[
          styles.line,
          {
            backgroundColor: status
              ? theme?.palette?.color?.status?.up
              : theme?.palette?.color?.status?.down,
          },
        ]}
      />
      <Stack
        direction={"column"}
        width={"100%"}
        justifyContent={"start"}
        gap={1.5}
      >
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant="default">
            {"Status do " + tipo.toUpperCase()}
          </Typography>
          {status ? (
            <CheckCircleOutline
              sx={{ color: theme.palette.color?.status?.up, fontSize: "22px" }}
            />
          ) : tipo == "cto" || tipo == "onu" ? (
            <CancelOutlined
              sx={{
                color: theme.palette.color?.status?.down,
                fontSize: "22px",
              }}
            />
          ) : (
            <WarningAmberOutlined
              sx={{
                color: theme.palette.color?.status?.down,
                fontSize: "22px",
              }}
            />
          )}
        </Stack>
        {(tipo == "cto" || tipo == "onu") && (
          <>
            {status ? (
              <Typography variant="small">
                {"A " + tipo.toUpperCase() + " está funcionando bem!"}
              </Typography>
            ) : (
              <Typography variant="small">
                {"A " + tipo.toUpperCase() + " está offline!"}
              </Typography>
            )}
          </>
        )}
        {(tipo == "ping" || tipo == "snmp") && (
          <>
            {status ? (
              <Typography variant="small">
                {"Operações de " + tipo + " realizadas com sucesso!"}
              </Typography>
            ) : (
              <Typography variant="small">
                {"Falha ao realizar operações de " + tipo + "!"}
              </Typography>
            )}
          </>
        )}
      </Stack>
    </Paper>
  );
}
