import { useEffect, useState } from "react";
import ProfileModal from "./profileModal";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  DarkModeOutlined,
  LightModeOutlined,
  Logout,
  PersonOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";

export default function Profile(props) {
  const {
    socket,
    socketCliente,
    colorMode,
    setColorMode = () => {},
    administrador,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [baseImage, setBaseImage] = useState("");
  const theme = useTheme();

  const navigate = useNavigate();

  useEffect(() => {
    let resultPerfil = localStorage.getItem("perfil");
    if (resultPerfil) {
      resultPerfil = JSON.parse(resultPerfil);
      setBaseImage(resultPerfil.foto);
    }
  }, [localStorage.getItem("perfil")]);

  const handlePerfil = () => {
    setOpenModal(true);
    //navigate("/perfil");
    handleClose();
  };
  const handleLogout = () => {
    socket.disconnect();
    socketCliente.disconnect();
    localStorage.clear();
    navigate("/login");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAdm = () => {
    navigate("/administracao");
  };
  return (
    <>
      <Tooltip title="Abrir Opções">
        <IconButton onClick={handleClick}>
          <Avatar src={baseImage} />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disableScrollLock={true}
        sx={{ marginLeft: "-5px" }}
      >
        <MenuItem onClick={handlePerfil}>
          <ListItemIcon>
            <PersonOutlined />
          </ListItemIcon>
          <ListItemText>Perfil</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setColorMode((prev) => {
              localStorage.setItem(
                "theme",
                JSON.stringify({ colorMode: !prev })
              );
              return !prev;
            });
          }}
          disabled={false}
        >
          <ListItemIcon>
            {!colorMode ? <DarkModeOutlined /> : <LightModeOutlined />}
          </ListItemIcon>
          <ListItemText>
            {!colorMode ? "Modo Noturno" : "Modo Claro"}
          </ListItemText>
        </MenuItem>
        {administrador && (
          <MenuItem onClick={handleAdm}>
            <ListItemIcon>
              <SupervisorAccountOutlined />
            </ListItemIcon>
            <ListItemText>Administração</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </MenuItem>
      </Menu>
      <ProfileModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}
