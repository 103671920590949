import { alpha } from "@mui/material";

const useStyles = (theme) => ({
  containerColumnVerificado: {
    height: "100%",
    gap: "8px",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
  },
  containerColumnCreatedAt: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    height: "100%",
    justifyContent: "start",
    alignItems: "center",
  },
  containerColumnLastLogin: {
    display: "flex",
    justifyContent: "start",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    gap: 0.5,
    height: "100%",
  },
  textDate: {
    color: "text.secondary",
    fontWeight: 500,
    lineHeight: 1.5,
  },
  subTextDate: {
    color: "text.secondary",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  containerColumnPermissao: {
    display: "flex",
    flexWrap: "wrap",
    height: "100%",
    fontWeight: "bold",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  foto: {
    width: "30px",
    height: "30px",
  },
  stack: {
    padding: "0px",
    width: "100%",
    height: "100%",
  },

  buttonMobile: {
    color: "primary.main",
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    display: { xs: "flex", md: "none" },
    maxWidth: "40.78px",
    maxHeight: "40.78px",
    p: 1.3,
    minWidth: 0,
  },
  buttonModal: {
    color: "#FFFFFF",
    backgroundColor: "primary.main",
    fontSize: "11px",
  },
  boxDataGrid: { display: { xs: "none", sm: "flex" }, width: "100%" },
  dataGrid: {
    borderLeft: "none",
    borderRight: "none",
    borderBottom: "none",
    width: "100%",
  },
  boxHeader: {
    width: "100%",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 2,
    alignItems: "center",
  },
  boxSearchAndFilter: {
    width: { xs: "100%", md: "100%" },
    flexWrap: { xs: "nowrap", md: "wrap" },
    gap: { xs: 1, md: 2 },
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  containerBox: {
    //padding: "1vw",
    //width: "calc(100vw-300px)",
    width: "100%",
    // marginRight: "auto",
    // marginLeft: "auto",
    minWidth: "320px",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  paper: {
    padding: 2,
    gap: 2,
    width: "100%",
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    minWidth: "320px",
    borderRadius: "0px 5px 5px 5px",
  },
  searchInput: {
    width: { xs: "100%", md: "400px" },
  },
  iconVerified: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  chipPermSuperAdmin: {
    color: theme.palette.access.superAdmin,
    backgroundColor: alpha(theme.palette.access.superAdmin, 0.1),
    height: "25px",
  },
  chipPermAdmin: {
    color: theme.palette.access.admin,
    backgroundColor: alpha(theme.palette.access.admin, 0.1),
    height: "25px",
  },
  chipPermUsuario: {
    color: theme.palette.access.usuario,
    backgroundColor: alpha(theme.palette.access.usuario, 0.1),
    height: "25px",
  },
  boxNoRowsOverlay: {
    p: 2,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  iconUserNotFound: { fill: theme.palette.text.disabled },
});

export default useStyles;
