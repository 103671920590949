import React, { useEffect, useState } from "react";

import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Icon,
  IconButton,
  Stack,
  Toolbar,
  ThemeProvider,
} from "@mui/material";

import { ReactComponent as LogoEasyMapsLigth } from "../../assets/easyMaps/logoEasyMaps.svg";
import easyMapsTheme from "../../themes/easyMapsTheme";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
import LoadingPage from "../../componentes/loadingPage";
import { socketPublic as socket } from "../../services/socket";

function LayoutEasyMapsPublic(params) {
  const { colorMode, setColorMode } = params;
  const themeEasyMaps = React.useMemo(
    () => easyMapsTheme(colorMode),
    [colorMode]
  );
  const styles = {
    logo: {
      width: "180px",
      height: "auto",
      marginTop: " 7px",
      fill: themeEasyMaps.palette.color.text,
    },
  };
  const [themeLayout, setThemeLayout] = useState({
    color: themeEasyMaps.palette.color.appBarEasyMaps.backgroundColor,
    icon: <LogoEasyMapsLigth style={styles.logo} alt={"mw-logo"} />,
  });
  //const [pageValue, setPageValeu] = useState(0);
  const [loadingPage, setLoadingPage] = useState(false);
  const { id, token } = useParams();
  const [isConnected, setIsConnected] = useState(socket.connected);
  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Servidor juntou-se ao grupo id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(true);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }

    socket.connect();
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", connectErrorEvent);
    socket.io.on("reconnect_attempt", reconnectAttemptEvent);
    socket.io.on("reconnect_error", reconnectErrorEvent);
    socket.io.on("reconnect", reconnectEvent);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", connectErrorEvent);
      socket.io.off("reconnect_attempt", reconnectAttemptEvent);
      socket.io.off("reconnect_error", reconnectErrorEvent);
      socket.io.off("reconnect", reconnectEvent);
    };
  }, []);

  useEffect(() => {
    setThemeLayout({
      color: themeEasyMaps.palette.background.paper,
      icon: <LogoEasyMapsLigth style={styles.logo} alt={"mw-logo"} />,
    });
  }, [themeEasyMaps.palette.mode]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoadingPage(true);
  //     //tokenValidate();
  //     //checkPerfil();
  //   }, 200);

  //   // async function tokenValidate() {
  //   //   const data = await checkTokenPublicMap(id, token);
  //   //   if (!data) {
  //   //     //navigate("/login");
  //   //   }
  //   //   setCheckLogin(true);
  //   // }
  // }, [pageValue]);

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={themeEasyMaps}>
      <Box display={"flex"}>
        <CssBaseline />
        {/* {!loadingPage && <LoadingPage />}
        {loadingPage && ( */}
        <Box>
          <AppBar
            position="fixed"
            sx={{
              top: 0,
              backgroundColor: themeLayout.color,
              borderBottom:
                "1px solid " + themeEasyMaps.palette.color.appBarBorder,
              zIndex: 1201,
            }}
            elevation={0}
            color="primary"
          >
            <Container maxWidth="100vw">
              <Toolbar sx={{ width: "98vw", height: 65 }}>
                <Stack direction={"row"} width={"100%"} alignItems={"center"}>
                  <Icon style={styles.logo}>{themeLayout.icon}</Icon>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      height: 62,
                      width: "90%",
                      gap: 1.5,
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        if (colorMode) {
                          setColorMode(false);
                          localStorage.setItem(
                            "3",
                            JSON.stringify({ colorMode: false })
                          );
                        } else {
                          setColorMode(true);
                          localStorage.setItem(
                            "3",
                            JSON.stringify({ colorMode: true })
                          );
                        }
                      }}
                    >
                      {!colorMode ? (
                        <DarkModeOutlined />
                      ) : (
                        <LightModeOutlined />
                      )}
                    </IconButton>
                  </Box>
                </Stack>
              </Toolbar>
            </Container>
          </AppBar>
          <Box
            sx={{
              height: "65px",
              width:
                window.location.pathname === "/easyMaps" ? "99vw" : "100vw",
            }}
          />

          <Outlet context={[socket]} />
        </Box>
        {/* )} */}
      </Box>
    </ThemeProvider>
  );
}

export default LayoutEasyMapsPublic;
