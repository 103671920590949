import React, { useCallback, useEffect, useState } from "react";
import {
  EdgeLabelRenderer,
  getBezierPath,
  getSimpleBezierPath,
  getSmoothStepPath,
  getStraightPath,
  useReactFlow,
  useStore,
} from "reactflow";
import ClickableBaseEdge from "./clickableBaseEdge";
import { useTheme } from "@mui/material";
import { convertBitsToBytes } from "../../../utils/easyMaps/convertToBytes";
export const getSpecialPath = (
  { sourceX, sourceY, targetX, targetY },
  offset
) => {
  const centerX = (sourceX + targetX) / 2;
  const centerY = (sourceY + targetY) / 2;

  return `M ${sourceX} ${sourceY} Q ${centerX} ${
    centerY + offset
  } ${targetX} ${targetY}`;
};

export default function PositionableEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  source,
  target,
  markerEnd,
  data,
  selected,
}) {
  const theme = useTheme();
  const { getEdges, setEdges, screenToFlowPosition } = useReactFlow();
  const {
    editMode,
    entangled,
    lineType,
    mapRef,
    itens = {
      inputTraffic: 0,
      outputTraffic: 0,
      units: "bps",
      status: false,
    },
    capacidade = 1,
    animation = "desativada",
    showLabelEnlace,
    easyMapsConfig,
  } = data;
  const [colors, setColors] = useState("");
  const edges = getEdges();
  const positionHandlers = data?.positionHandlers ?? [];
  const edgeSegmentsCount = positionHandlers.length + 1;
  const traffic =
    itens?.inputTraffic > itens?.outputTraffic
      ? itens?.inputTraffic
      : itens?.outputTraffic;
  const percentilTraffic =
    capacidade && capacidade != 0 && traffic
      ? (parseFloat(traffic) / (capacidade * 1000000000)) * 100
      : null;
  const [isDragging, setIsDragging] = useState(false);
  let pathFunction;

  switch (lineType) {
    case "reta":
      pathFunction = getStraightPath;
      break;
    case "ortogonal":
      pathFunction = getSmoothStepPath;
      break;
    case "curvada":
      pathFunction = getBezierPath;
      break;
    default:
      pathFunction = getStraightPath;
  }

  const edgeSegmentsArray = Array.from({ length: edgeSegmentsCount }).map(
    (_, i) => {
      const segmentSource =
        i === 0 ? { x: sourceX, y: sourceY } : positionHandlers[i - 1];
      const segmentTarget =
        i === edgeSegmentsCount - 1
          ? { x: targetX, y: targetY }
          : positionHandlers[i];

      const { x: segmentSourceX, y: segmentSourceY } = segmentSource;
      const { x: segmentTargetX, y: segmentTargetY } = segmentTarget;

      const difX = targetX - sourceX;
      let _sourcePosition =
        i === 0 ? sourcePosition : difX < 0 ? "left" : "right";
      let _targetPosition =
        i === edgeSegmentsCount - 1
          ? targetPosition
          : difX < 0
          ? "right"
          : "left";

      const [edgePath, labelX, labelY] = pathFunction({
        sourceX: segmentSourceX,
        sourceY: segmentSourceY,
        sourcePosition: _sourcePosition,
        //sourcePosition,
        targetX: segmentTargetX,
        targetY: segmentTargetY,
        targetPosition: _targetPosition,
        //targetPosition,
      });

      return { edgePath, labelX, labelY };
    }
  );

  const styles = {
    clickableBaseEdge: {
      stroke: selected
        ? theme.palette.color.zabbixSeverty.selectedTranslucid
        : editMode
        ? "#5e5e5e"
        : colors,
      opacity: entangled ? 1 : 0.33,
      strokeWidth: 2,
      animation: easyMapsConfig?.animacao?.linha != false ? null : "none",
      strokeDasharray:
        editMode || animation === "desativada"
          ? "0"
          : animation === "pontilhada"
          ? "2,8"
          : "5",
    },
    divEdge: {
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      fontSize: 12,
      pointerEvents: "all",
    },
    buttonDraggable: {
      width: "20px",
      height: "20px",
      fontSize: "14px",
      borderRadius: "50%",
      cursor: "pointer",
      lineHeight: 4,
      padding: 0,
      background: theme.palette.color.zabbixSeverty.selectedTranslucid,
    },
    label: {
      position: "absolute",
      // transform: `translate(-50%, -50%) translate(${sourceX + targetX / 6}px,${
      //   (sourceY + targetY) / 2
      // }px)`,
      background: theme.palette.color.zabbixSeverty.default,
      paddingLeft: 5,
      paddingRight: 5,
      borderRadius: 5,
      fontSize: 12,
      fontWeight: 700,
      color: theme.palette.color.textEasyMaps,
      opacity: entangled ? 1 : 0.33,
    },
    edit: {
      position: "absolute",
      transform: `translate(-50%, -50%) translate(${
        (sourceX + targetX) / 2
      }px,${(sourceY + targetY) / 2}px)`,
      background: theme.palette.color.zabbixSeverty.selectedTranslucid,
      paddingLeft: 1,
      paddingRight: 1,
      borderRadius: 5,
      fontSize: 10,
      fontWeight: 500,
      color: "#FFFFFF",
      opacity: entangled ? 1 : 0.33,
    },
  };
  useEffect(() => {
    const cor = () => {
      if (percentilTraffic == null || percentilTraffic == 0)
        return theme.palette.enlaces.offline.main;
      if (percentilTraffic < 1) return theme.palette.enlaces.p0.main;
      if (percentilTraffic < 5) return theme.palette.enlaces.p1.main;
      if (percentilTraffic < 15) return theme.palette.enlaces.p5.main;
      if (percentilTraffic < 25) return theme.palette.enlaces.p15.main;
      if (percentilTraffic < 50) return theme.palette.enlaces.p25.main;
      if (percentilTraffic < 70) return theme.palette.enlaces.p50.main;
      if (percentilTraffic < 80) return theme.palette.enlaces.p70.main;
      if (percentilTraffic < 90) return theme.palette.enlaces.p80.main;
      return theme.palette.enlaces.p90.main;
    };
    setColors(cor);
  }, [data, selected, theme]);
  const handleMouseMove = (event, id, handlerIndex) => {
    let activeEdge = parseInt(event.target.dataset.active ?? -1);
    if (activeEdge === -1) {
      return;
    }
    if (activeEdge === 1) {
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const edgeIndex = edges.findIndex((edge) => edge.id === id);
      let latlng = mapRef?.containerPointToLatLng([position.x, position.y]);
      setEdges((edges) => {
        //edges[edgeIndex].id = Math.random();
        edges[edgeIndex].data.positionHandlers[handlerIndex] = mapRef
          ? {
              x: position.x,
              y: position.y,
              active: 1,
              location: {
                long: latlng.lng,
                lat: latlng.lat,
              },
            }
          : {
              x: position.x,
              y: position.y,
              active: 1,
            };
        return edges;
      });
    }
  };
  const handleMouseUp = () => {
    if (editMode) {
      setIsDragging(false);
      setEdges((edges) => {
        const updatedEdges = edges.map((edge) => ({
          ...edge,
          data: {
            ...edge.data,
            positionHandlers: edge.data.positionHandlers?.map((handler) => ({
              ...handler,
              active: -1,
            })),
          },
        }));

        return updatedEdges;
      });
    }
    return;
  };

  const handleMouseDown = (event, id, handlerIndex, active) => {
    if (editMode) {
      setIsDragging(true);
      setEdges((edges) => {
        const edgeIndex = edges.findIndex((edge) => edge.id === id);
        edges[edgeIndex].data.positionHandlers[handlerIndex].active = 1;
        return edges;
      });
      event.preventDefault();
    }
    event.preventDefault();
    return;
  };

  const handleDeleteEdge = (event, id, handlerIndex) => {
    event.preventDefault();
    if (editMode) {
      //setEdges((edges) => edges.filter((edge) => edge.id !== id));
      setEdges((edges) => {
        const edgeIndex = edges.findIndex((edge) => edge.id === id);
        edges[edgeIndex].data.positionHandlers?.splice(handlerIndex, 1);
        return edges;
      });
    }
    return;
  };
  const transform = (handlerIndex, x, y) => {
    return handlerIndex === 0
      ? `translate(-50%, -50%) translate(${(x + sourceX) / 2}px,${
          (y + sourceY) / 2
        }px)`
      : handlerIndex === positionHandlers.length - 1
      ? `translate(-50%, -50%) translate(${(x + targetX) / 2}px,${
          (y + targetY) / 2
        }px)`
      : null;
  };
  const handleCreateEdge = (event, id, index) => {
    if (selected && editMode) {
      //event.preventDefault();
      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      let latlng = mapRef?.containerPointToLatLng([position.x, position.y]);

      setEdges((edges) => {
        const edgeIndex = edges.findIndex((edge) => edge.id === id);
        edges[edgeIndex].data.positionHandlers =
          edges[edgeIndex].data.positionHandlers ?? [];
        edges[edgeIndex].data.positionHandlers.splice(
          index,
          0,
          mapRef
            ? {
                x: position.x,
                y: position.y,
                active: -1,
                location: {
                  long: latlng.lng,
                  lat: latlng.lat,
                },
              }
            : {
                x: position.x,
                y: position.y,
                active: -1,
              }
        );
        return edges;
      });
    }
  };
  let positionLabel = 0;
  if (Array.isArray(positionHandlers) && Array.isArray(edgeSegmentsArray)) {
    const posLegnth = positionHandlers.length;
    const edsLegnth = edgeSegmentsArray.length;

    positionLabel =
      posLegnth % 2 !== 0
        ? positionHandlers[(posLegnth - 1) / 2]
        : {
            x: edgeSegmentsArray[(edsLegnth - 1) / 2].labelX,
            y: edgeSegmentsArray[(edsLegnth - 1) / 2].labelY,
          };
  } else {
    return;
  }
  const convertInputTraffic = convertBitsToBytes(itens?.inputTraffic);
  const convertOutputTraffic = convertBitsToBytes(itens?.outputTraffic);
  return (
    <>
      {edgeSegmentsArray.map(({ edgePath, labelX, labelY }, index) => (
        <ClickableBaseEdge
          onDoubleClick={(event) => {
            handleCreateEdge(event, id, index);
          }}
          key={`edge${id}_segment${index}`}
          path={edgePath}
          markerEnd={
            index === edgeSegmentsArray.length - 1 ? markerEnd : "false"
          }
          style={styles.clickableBaseEdge}
        />
      ))}
      {!positionHandlers.length > 0 && (
        <EdgeLabelRenderer key={`edge`}>
          {!editMode &&
            showLabelEnlace &&
            (!!itens?.inputTraffic || !!itens?.outputTraffic) && (
              <div
                className="nodrag nopan"
                style={{
                  ...styles.label,
                  zIndex: 1,
                  transform: `translate(-50%, -50%) translate(${positionLabel.x}px,${positionLabel.y}px)`,
                }}
              >
                {"RX: " +
                  convertInputTraffic.valor +
                  " " +
                  convertInputTraffic.unidade}
                <br />
                {"TX: " +
                  convertOutputTraffic.valor +
                  " " +
                  convertOutputTraffic.unidade}
              </div>
            )}
        </EdgeLabelRenderer>
      )}
      {positionHandlers.map(({ x, y, active }, handlerIndex) => (
        <EdgeLabelRenderer key={`edge${id}_handler${handlerIndex}`}>
          {!editMode &&
            //handlerIndex > 0 &&
            showLabelEnlace &&
            (!!itens?.inputTraffic || !!itens?.outputTraffic) && (
              <div
                className="nodrag nopan"
                style={{
                  ...styles.label,
                  zIndex: 1,
                  transform: `translate(-50%, -50%) translate(${positionLabel?.x}px,${positionLabel?.y}px)`,
                }}
              >
                {"RX: " +
                  convertInputTraffic.valor +
                  " " +
                  convertInputTraffic.unidade}
                <br />
                {"TX: " +
                  convertOutputTraffic.valor +
                  " " +
                  convertOutputTraffic.unidade}
              </div>
              // <div
              //   className="nodrag nopan"
              //   style={{
              //     ...styles.divEdge,

              //     //  transform: `translate(-50%, -50%) translate(${positionLabel?.x}px,${positionLabel?.y}px)`,
              //   }}
              // ></div>
            )}
          {editMode && selected && (
            <div
              data-active={active ?? -1}
              style={{
                ...styles.divEdge,
                width: active === 1 ? "1200px" : "20px",
                height: active === 1 ? "1200px" : "20px",
                transform: `translate(-50%, -50%) translate(${x}px,${y}px)`,
              }}
              className="nodrag nopan"
              onMouseMove={(event) => {
                handleMouseMove(event, id, handlerIndex);
              }}
              onMouseUp={() => {
                handleMouseUp();
              }}
              onMouseLeave={() => {
                handleMouseUp();
              }}
            >
              <button
                data-active={active ?? -1}
                style={{
                  ...styles.buttonDraggable,
                  border:
                    active === 1 ? "1px solid #FFFFFF" : "1px solid #33333300",
                }}
                className="edgebutton"
                onMouseDown={(event) => {
                  handleMouseDown(event, id, handlerIndex, active);
                }}
                onContextMenu={(event) => {
                  handleDeleteEdge(event, id, handlerIndex);
                }}
              ></button>
            </div>
          )}
        </EdgeLabelRenderer>
      ))}
    </>
  );
}
