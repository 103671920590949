import { alpha } from "@mui/material";

const useStyles = (theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    padding: 1,
    maxWidth: "432px",
    width: "90%",
    minWidth: "232px",
    maxHeight: "90vh",
    flexDirection: "column",
    display: "flex",
    alignItems: "felx-start",
  },
  paperPreview: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "100%",
    padding: 0,
    gap: 1,
  },
  boxCover: {
    height: "100px",
    borderRadius: "5px",
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    width: "100%",
  },
  avatar: {
    position: "absolute",
    width: "100px",
    height: "100px",
    mb: 2,
    ml: 3,
    color: "black",
    outline: "8px solid" + " " + "background.paper",
    cursor: "pointer",
    "&:hover": {
      filter: "brightness(90%)",
    },
  },
  avatarEdit: {
    position: "absolute",
    width: "100px",
    height: "100px",
    mb: 2,
    ml: 3,
    outline: "8px solid" + " " + "background.paper",
  },
  boxAvatar: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "end",
  },
  boxIcon: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    color: "white",
    bgcolor: "primary.main",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    position: "absolute",
    margin: "0px 0px 10px 90px",
  },
  boxLoginAndButton: {
    display: "flex",
    flexDirection: { sm: "row", xs: "column" },
    justifyContent: "end",
    alignItems: { xs: "end" },
    width: { sm: "100%", xs: "50%" },
    gap: { xs: 2, sm: 6 },
  },
  login: {
    display: "flex",
    fontWeight: 500,
    color: "text.primary",
    textWrap: "nowrap",
  },
  boxLoginAndButton: {
    display: "flex",
    flexWrap: "wrap",
    width: { xs: "70%", sm: "80%" },
    flexDirection: "row-reverse",
    alignItems: "top",
    justifyContent: "space-between",
  },
  boxButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "end",
    gap: 1,
    padding: 0,
    width: "100%",
  },
  imageButton: {
    width: "40px",
    height: "40px",
    color: "white",
    backgroundColor: "primary.main",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    position: "absolute",
    margin: "0px 0px 10px 90px",
    "&:hover": {
      backgroundColor: "background.textField",
      color: "primary.main",
    },
  },
  subtitle: {
    fontSize: "14px",
    fontWeight: 500,
    color: "text.primary",
  },
  dialogTitle: {
    padding: 0,
  },
  dialogContent: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    gap: 1.5,
  },
  boxRowFields: {
    display: "flex",
    flexDirection: "column",
    with: "100%",
    gap: 1,
    //padding: 1,
  },
  textfield: {
    width: "100%",
  },
  paragraph: {
    color: "text.secondary",
    fontSize: "14px",
    fontWeight: 500,
  },
  boxFullName: {
    display: "flex",
    flexDirection: "row",
    gap: 0.5,
  },
  loadingButton: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: "primary.main",
  },
  //AvatarModal
  avatarModal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
    padding: "20px",
  },
  imgAvatarModal: { maxWidth: "100%", maxHeight: "100%" },
});

export default useStyles;
