import { useTheme, Typography, Snackbar, Alert } from "@mui/material";
import { Outlet, useLocation } from "react-router";
import { useEffect, useState } from "react";
import api from "../../services/api";

export default function EasyMaps(props) {
  const [snackbar, setSnackbar] = useState(null);
  const [mapGroups, setMapGroups] = useState([]);
  const [maps, setMaps] = useState([]);
  const [loadingPage, setLoadingPage] = useState([]);

  const theme = useTheme();
  const location = useLocation();
  useEffect(() => {
    handleApi();
  }, []);

  async function handleApi() {
    try {
      setLoadingPage(true);
      await Promise.all([handleApiGetGroupMaps(), handleApiGetMaps()]);
    } catch (error) {
    } finally {
      setLoadingPage(false);
    }
  }

  async function handleApiGetMaps() {
    try {
      const response = await api.get("/easyMaps/map/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          setMaps(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiGetGroupMaps() {
    try {
      const response = await api.get("/easyMaps/groupMaps/findMany");
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os Grupo de Mapas",
          severity: "error",
        });
      } else {
        if (response.data) {
          setMapGroups(response.data);
        }
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }
  function formatedTitle(input) {
    const parts = input.replace("geolocalizado", "geolocalização").split("/");

    const capitalize = (str) =>
      str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    let title = "";
    parts.map((a, index) => {
      if (index != 0) title += " de " + capitalize(a);
      else title += capitalize(a);
    });
    // Retorna o texto formatado
    return title;
  }

  return (
    <>
      <Typography sx={{ fontSize: "20px" }}>
        {formatedTitle(location.pathname.replace("/easyMaps/", ""))}
      </Typography>
      <Outlet
        context={{
          maps,
          mapGroups,
          loadingPage,
          setMaps,
          setMapGroups,
          api,
        }}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
