import { Edit, KeyOutlined, Lock, LockOpen, Person } from "@mui/icons-material";

import {
  Menu,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

export default function MenuAdm({
  anchorEl,
  handleClose,
  open,
  setOpenEditProfileModal = () => {},
  setOpenEditLoginModal = () => {},
  setOpenEditPermissionModal = () => {},
  setModalAlert = () => {},
  activated = true,
}) {
  const styles = {
    boxDialogTitle: {
      display: "flex",
      flexDirection: "row",
      gap: 1,
      marginBottom: 1,
    },
    foto: {
      width: "80px",
      height: "80px",
    },
    fotoModalPermission: {
      width: "50px",
      height: "50px",
    },
    textfield: {
      width: "100%",
      minWidth: "min-content",
    },
    name: {
      color: "text.primary",
      fontWeight: 500,
    },
    login: {
      color: "text.secondary",
      fontWeight: 400,
    },
    boxTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
      color: "primary.main",
    },
    boxLabelsSwitch: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "end",
      alignItems: "start",
    },
    label: {
      marginTop: "20px",
      fontWeight: 500,
      color: "text.primary",
    },
    subLabel: {
      fontWeight: 400,
      color: "text.secondary",
    },

    buttonCancelDialog: {
      color: "primary.main",
    },
    dialogLoginContent: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      gap: 5,
      maxWidth: "max-content",
      minWidth: "min-content",
      width: "100%",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
    },
    boxFoto: {
      display: "flex",
      width: "440px",
      height: "80px",
      justifyContent: "space-between",
      alignItems: "start",
    },
    boxContainerFoto: {
      display: "flex",
      alignItems: " center",
      gap: "12px",
    },
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "options-button",
        }}
      >
        <MenuList>
          <MenuItem
            color="text.secondary"
            onClick={() => {
              setOpenEditLoginModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Edit fontSize="small" />
            </ListItemIcon>
            <ListItemText>Editar Senha</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenEditProfileModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <Person fontSize="small" />
            </ListItemIcon>
            <ListItemText>Editar Detalhes</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenEditPermissionModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <KeyOutlined fontSize="small" />
            </ListItemIcon>
            <ListItemText>Alterar Permissões</ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setModalAlert(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              {activated ? (
                <Lock fontSize="small" />
              ) : (
                <LockOpen fontSize="small" />
              )}
            </ListItemIcon>

            <ListItemText> {activated ? "Desativar" : "Ativar"}</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
