import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { io } from "socket.io-client";

import Layout from "./pages/layout";
import LayoutEasyMaps from "./pages/layoutEasymaps";
import LayoutEasyMapsPublic from "./pages/layoutEasymapsPublic";
import Construcao from "./pages/construcao";
import Login from "./pages/login";
import Perfil from "./pages/perfil";
import HomeIn from "./pages/homeIn";
import IotShopping from "./pages/iotShopping";
import IotProduto from "./pages/iotProduto";
import IotAdministrcao from "./pages/iotAdministracao";
//import Acessos from "./pages/Acessos";
import Suporte from "./pages/suporte";
import Chat from "./pages/suporte/chat";
import RelatoriosFlow from "./pages/easyFlow/relatoriosFlow";
import EasyFlow from "./pages/easyFlow";
import Administracao from "./pages/administracao";
import L from "leaflet";
//import CriaUsuario from "./pages/criaUsuario";
//import Empresas from "./pages/empresas";
import Acesso from "./pages/acesso";
import Cadastro from "./pages/cadastro";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CssBaseline } from "@mui/material";
import EasyMaps from "./pages/easyMaps";
import EasyMapsOld from "./pages/easymapsOld";
import EasyReport from "./pages/easyReportOld";
import EasyAdd from "./pages/easyAdd";
//import HostsEasyAdd from "./pages/easyAdd/paginaInicial";
import foto from "./assets/fullscreen.png";

import SolicitarRedefinirSenha from "./pages/solicitarRedefinirSenha";
import RedefinirSenha from "./pages/redefinirSenha";
import AgendarRelatorio from "./pages/easyReportOld/agendarRelatorio";
import SelectionPage from "./pages/easyReportOld/selectionPage";
import NovoRelatorioOld from "./pages/easyReportOld/agendarRelatorio/novoRelatorioOld";
import HistoricoRelatorio from "./pages/easyReportOld/agendarRelatorio/historicoRelatorio";
import GerarRelatorio from "./pages/easyReportOld/gerarRelatorio";
import { myTheme } from "./themes";
import MapaGeolocalizado from "./pages/easyMaps/maps/geolocalizado";
import MapaTopologia from "./pages/easyMaps/maps/topologia";
import { socket, socketCliente } from "./services/socket";
import LayoutEasyReport from "./pages/layoutEasyReport";
import Relatorios from "./pages/easyReport/relatorios";
import Agendamento from "./pages/easyReport/agendamento";
import NovoRelatorio from "./pages/easyReport/relatorios/novoRelatorio";
import PerfilRelatorio from "./pages/easyReport/perfilRelatorio";
import { NotificationProvider } from "./componentes/notificationProvider";
import MapaGeolocalizadoPublic from "./pages/easyMaps/maps/geolocalizadoPublic";
import MapaTopologiaPublic from "./pages/easyMaps/maps/topologiaPublic";
import NotFoundMaps from "./pages/easyMaps/pages/notFound";
import GruposEasyMaps from "./pages/easyMaps/pages/grupos";
import MapasGeolocalizadosEasyMaps from "./pages/easyMaps/pages/geolocalizado";
import MapaTopologiaEasyMaps from "./pages/easyMaps/pages/topologia";
import MapaTopologiaPOPEasyMaps from "./pages/easyMaps/pages/pop";
import MapaGeolocalizadoERP from "./pages/easyMaps/maps/geolocalizadoFTTH";
import ProtectedRoute from "./pages/protectedRoute";
import ScriptsOnus from "./pages/scriptsOnus";

function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [checkLogin, setCheckLogin] = useState(null);
  const [colorMode, setColorMode] = useState(prefersDarkMode);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [isConnectedCliente, setIsConnectedCliente] = useState(
    socketCliente.connected
  );

  useEffect(() => {
    setColorMode(prefersDarkMode);
  }, [prefersDarkMode]);

  useEffect(() => {
    let colorModeStorage = JSON.parse(localStorage.getItem("theme"));
    if (colorModeStorage) {
      setColorMode(colorModeStorage.colorMode);
    }
  }, []);

  useEffect(() => {
    function onConnectCliente() {
      setIsConnectedCliente(true);

      socketCliente.emit(
        "first_connection",
        `Frontend cliente estabeleceu conexão, id conection: ${socketCliente.id}`
      );
    }
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Frontend cliente estabeleceu conexão, id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(false);
    }
    function onDisconnectCliente() {
      setIsConnectedCliente(false);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }
    if (!checkLogin) {
      if (isConnected) socket.disconnect();
      if (isConnectedCliente) socketCliente.disconnect();
    }

    if (checkLogin) {
      //atualiza query socketCliente para enviar id do usuarioCliente
      socketCliente.io.opts.query = {
        usuarioClienteId: JSON.parse(localStorage.getItem("1"))?.usuarioCliente
          ?.id,
      };
      socketCliente.connect();
      socket.connect();

      socketCliente.on("connect", onConnectCliente);
      socketCliente.on("disconnect", onDisconnectCliente);
      socketCliente.on("connect_error", connectErrorEvent);
      socketCliente.io.on("reconnect_attempt", reconnectAttemptEvent);
      socketCliente.io.on("reconnect_error", reconnectErrorEvent);
      socketCliente.io.on("reconnect", reconnectEvent);

      socket.on("connect", onConnect);
      socket.on("disconnect", onDisconnect);
      socket.on("connect_error", connectErrorEvent);
      socket.io.on("reconnect_attempt", reconnectAttemptEvent);
      socket.io.on("reconnect_error", reconnectErrorEvent);
      socket.io.on("reconnect", reconnectEvent);
      return () => {
        socketCliente.off("connect", onConnectCliente);
        socketCliente.off("disconnect", onDisconnect);
        socketCliente.off("connect_error", connectErrorEvent);
        socketCliente.io.off("reconnect_attempt", reconnectAttemptEvent);
        socketCliente.io.off("reconnect_error", reconnectErrorEvent);
        socketCliente.io.off("reconnect", reconnectEvent);

        socket.off("connect", onConnect);
        socket.off("disconnect", onDisconnect);
        socket.off("connect_error", connectErrorEvent);
        socket.io.off("reconnect_attempt", reconnectAttemptEvent);
        socket.io.off("reconnect_error", reconnectErrorEvent);
        socket.io.off("reconnect", reconnectEvent);
      };
    }
  }, [checkLogin]);
  // function conectarSocket() {
  //   const usuarioClienteId = JSON.parse(localStorage.getItem("1"))?.usuarioCliente?.id;
  //   if (usuarioClienteId) {
  //     socketCliente.io.opts.query = { usuarioClienteId };
  //     socketCliente.connect();
  //   } else {
  //     console.error("ID do usuário não encontrado no localStorage");
  //   }
  // }
  useEffect(() => {
    const fullscreenStyle = `
    .custom-tooltip{
      background-color: rgba(255,255,255,0.7);
      border: none;
    }
    .leaflet-interactive{
      outline:none
    }

    .leaflet-touch .leaflet-control-fullscreen a {
      background-position: 2px 2px;
    }
    .leaflet-fullscreen-on .leaflet-control-fullscreen a {
      background-position:0 -26px;
    }
    .leaflet-touch.leaflet-fullscreen-on .leaflet-control-fullscreen a {
      background-position: 2px -24px;
    }
  
    /* Do not combine these two rules; IE will break. */
    .leaflet-container:-webkit-full-screen {
      width:100%!important;
      height:100%!important;
    }
    .leaflet-container.leaflet-fullscreen-on {
      width:100%!important;
      height:100%!important;
    }
  
    .leaflet-pseudo-fullscreen {
      position:fixed!important;
      width:100%!important;
      height:100%!important;
      top:0!important;
      left:0!important;
      z-index:99999;
    }
  
    @media
      (-webkit-min-device-pixel-ratio:2),
      (min-resolution:192dpi) {
        .leaflet-control-fullscreen a {
          background-image:url(fullscreen@2x.png);
        }
    }`;
    // Cria a folha de estilo embutida para aplicar o filtro ao Leaflet
    const style = colorMode
      ? `
      .leaflet-layer,
      .leaflet-control-zoom-in,
      .leaflet-control-zoom-out,
      .leaflet-control-attribution {
        filter: invert(100%) hue-rotate(180deg) brightness(60%) contrast(95%) sepia(20%) grayscale(30%);
        //filter: invert(100%) hue-rotate(180deg) brightness(100%) contrast(85%) ;
      }
      .leaflet-control-fullscreen a {
        filter: invert(100%);
        background:${"#f2f2f2"} url(${foto}) no-repeat 0 0;
       
        background-size:26px 52px;
      }
      .leaflet-div-icon {
        border: none!important;
        background: transparent!important;
   
      }
      .leaflet-tooltip {
        background-color:#3d3d3d;
        border: 0px;
        box-shadow: none;
    }
  
    .leaflet-tooltip-top:before {
      
      margin-top: -12px;
      border-top-color:#3d3d3d;
    
      }
      
.leaflet-bottom {
    bottom: 0;
    display: none;
}
    ` + fullscreenStyle
      : ` 
        .leaflet-layer,
      .leaflet-control-attribution {
        //filter: invert(100%) hue-rotate(180deg) brightness(60%) contrast(95%) sepia(20%) grayscale(30%);
        filter: brightness(100%) contrast(90%) grayscale(30%) sepia(5%)  ;
      }
    .leaflet-tooltip {
      
      border: 0px;
      box-shadow: none;
  }
    .leaflet-control-fullscreen a {
      background:${"#fff"} url(${foto}) no-repeat 0 0;
      background-size:26px 52px;
    }
    .leaflet-div-icon {
      border: none!important;
      background: transparent!important;
    }

.leaflet-bottom {
  bottom: 0;
  display: none;
}
 
    ` + fullscreenStyle;

    const styleElement = L.DomUtil.create("style", "leaflet-custom-style");

    styleElement.type = "text/css";
    if (styleElement.styleSheet) {
      styleElement.styleSheet.cssText = style;
    } else {
      styleElement.appendChild(document.createTextNode(style));
    }

    document.getElementsByTagName("head")[0].appendChild(styleElement);

    // Remove a folha de estilo embutida quando o componente é desmontado
    return () => {
      document.getElementsByTagName("head")[0].removeChild(styleElement);
    };
  }, [colorMode]);

  useEffect(() => {
    // Define o estilo a ser aplicado com base no modo de cor
    const style = colorMode
      ? `::-webkit-scrollbar-track {
        background: #1E1E1E; 
      }
      ::-webkit-scrollbar-thumb {
        background: #555;
      }
      ::-webkit-scrollbar {
        width: 10px; 
        height:10px
      }
        ::-webkit-scrollbar-thumb:hover {
          background: #474747;
        }
     `
      : `::-webkit-scrollbar-track {
        background: #e2e2e2;
      }
      ::-webkit-scrollbar-thumb {
        background: #b3b3b3; 
        }

     ::-webkit-scrollbar-thumb:hover {
           background: #8f8e8e; 
       
      }
      ::-webkit-scrollbar {
        width: 10px;
        height:10px
       
      }`;

    // Cria o elemento <style> e adiciona o CSS
    const styleElement = document.createElement("style");
    styleElement.type = "text/css";

    // Verifica compatibilidade para inserir o CSS
    if (styleElement.styleSheet) {
      styleElement.styleSheet.cssText = style;
    } else {
      styleElement.appendChild(document.createTextNode(style));
    }
    // Adiciona o elemento <style> ao <head> do documento
    document.head.appendChild(styleElement);
    // Remove o estilo ao desmontar o componente
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [colorMode]);

  const theme = React.useMemo(() => myTheme(colorMode), [colorMode]);

  return (
    <ThemeProvider theme={theme}>
      <NotificationProvider>
        <CssBaseline />
        <div className="App">
          <BrowserRouter>
            <Routes>
              <Route
                exact
                path="/login"
                element={<Login setCheckLogin={setCheckLogin} />}
              />
              <Route
                exact
                path="/resetPassword/:token/:id"
                element={<RedefinirSenha />}
              />

              <Route
                exact
                path="/resetPassword"
                element={<SolicitarRedefinirSenha />}
              />

              <Route path="*" element={<Navigate replace to="/login" />} />
              <Route path="/cadastro" element={<Cadastro />} />

              {/* layout EasyMaps */}
              <Route
                element={
                  <ProtectedRoute
                    setCheckLogin={setCheckLogin}
                    checkLogin={checkLogin}
                  >
                    <LayoutEasyMaps
                      socket={socket}
                      socketCliente={socketCliente}
                      colorMode={colorMode}
                      setColorMode={setColorMode}
                      setCheckLogin={setCheckLogin}
                    />
                  </ProtectedRoute>
                }
              >
                {/* Redirecionamentos  */}
                <Route
                  path="/easyMaps"
                  element={<Navigate replace to="/easyMaps/grupos" />}
                />
                <Route
                  path="/easyMaps/*"
                  element={<Navigate replace to="/easyMaps/grupos" />}
                />
                <Route
                  path="/easyMaps/pages/notFound"
                  element={<NotFoundMaps />}
                />

                <Route element={<EasyMaps />}>
                  {/* O componete EasyMaps é reponsavel por baixar os mapas */}
                  <Route path="/easyMaps/grupos" element={<GruposEasyMaps />} />
                  <Route
                    path="/easyMaps/mapas/geolocalizado"
                    element={<MapasGeolocalizadosEasyMaps />}
                  />
                  <Route
                    path="/easyMaps/mapas/topologia"
                    element={<MapaTopologiaEasyMaps />}
                  />
                  <Route
                    path="/easyMaps/mapas/pop"
                    element={<MapaTopologiaPOPEasyMaps />}
                  />
                </Route>

                <Route
                  path="/easyMaps/mapa/rp/:id"
                  element={
                    <MapaGeolocalizadoERP socketCliente={socketCliente} />
                  }
                />
                <Route
                  path="/easyMaps/mapa/geo/:id"
                  element={<MapaGeolocalizado socketCliente={socketCliente} />}
                />
                <Route
                  path="/easyMaps/mapa/top/:id"
                  element={<MapaTopologia socketCliente={socketCliente} />}
                />
              </Route>
              <Route
                element={
                  <LayoutEasyMapsPublic
                    colorMode={colorMode}
                    setColorMode={setColorMode}
                  />
                }
              >
                {/* Redirecionamentos  */}

                <Route
                  path="/easyMaps/*"
                  element={<Navigate replace to="/easyMaps/pages/notFound" />}
                />

                <Route
                  path="/easyMaps/pages/notFound"
                  element={<NotFoundMaps />}
                />
                <Route
                  path="/easyMaps/mapa/public/geo/:id/:token"
                  element={
                    <MapaGeolocalizadoPublic socketCliente={socketCliente} />
                  }
                />
                <Route
                  path="/easyMaps/mapa/public/top/:id/:token"
                  element={
                    <MapaTopologiaPublic socketCliente={socketCliente} />
                  }
                />
                <Route
                  path="/easyMaps/mapa/public/rp/:id/:token"
                  element={
                    <MapaGeolocalizadoERP
                      isPublic={true}
                      socketCliente={socketCliente}
                    />
                  }
                />
              </Route>

              {/* <Route
              element={
                <ProtectedRoute
                  setCheckLogin={setCheckLogin}
                  checkLogin={checkLogin}
                >
                  <LayoutEasyReport
                    socket={socket}
                    socketCliente={socketCliente}
                    colorMode={colorMode}
                    setColorMode={setColorMode}
                  setCheckLogin={setCheckLogin}
                  />
                </ProtectedRoute>
              }
            >
              <Route
                path="/easyReport/*"
                element={<Navigate replace to="/easyReport/relatorios" />}
              />
              <Route
                path="/easyReport/relatorios/novoRelatorio"
                element={<NovoRelatorio />}
              />
              <Route
                path="/easyReport/perfilRelatorios"
                element={<PerfilRelatorio />}
              />

              <Route path="/easyReport/relatorios" element={<Relatorios />} />
              <Route path="/easyReport/agendamento" element={<Agendamento />} />
              <Route
                path="/easyReport/perfilRelatorios"
                element={<div>Home</div>}
              />
              <Route path="/easyReport/lixeira" element={<div>Home</div>} />
            </Route> */}

              <Route
                element={
                  <ProtectedRoute
                    setCheckLogin={setCheckLogin}
                    checkLogin={checkLogin}
                  >
                    <Layout
                      socket={socket}
                      socketCliente={socketCliente}
                      colorMode={colorMode}
                      setColorMode={setColorMode}
                    />
                  </ProtectedRoute>
                }
              >
                <Route path="/homeIn" element={<HomeIn />} />
                <Route path="/administracao" element={<Administracao />} />
                <Route path="/perfil" element={<Perfil />} />
                <Route
                  path="/easyAdd"
                  element={<EasyAdd socket={socketCliente} />}
                />
                <Route path="/scriptONUs" element={<ScriptsOnus />} />

                {/* <Route path="/easyAdd/hosts" element={<EasyAdd />} /> */}
                <Route element={<EasyReport />}>
                  <Route
                    path="/easyReport/*"
                    element={
                      <Navigate replace to="/easyReport/selecionarPagina" />
                    }
                  />
                  <Route
                    path="/easyReport/selecionarPagina"
                    element={<SelectionPage />}
                  />
                  <Route
                    path="/easyReport/agendar"
                    element={<AgendarRelatorio />}
                  />
                  <Route
                    path="/easyReport/gerar"
                    element={<GerarRelatorio />}
                  />
                  <Route
                    path="/easyReport/agendar/novoRelatorio"
                    element={<NovoRelatorioOld />}
                  />
                  <Route
                    path="/easyReport/agendar/historico/:relatorioId"
                    element={<HistoricoRelatorio />}
                  />
                </Route>
                <Route path="/easySGP" element={<Construcao />} />

                <Route
                  path="/easyAdd"
                  element={<EasyAdd socket={socketCliente} />}
                />

                <Route path="/easySGP" element={<Construcao />} />

                <Route path="/suporte" element={<Suporte socket={socket} />}>
                  <Route path="/suporte/:chatId" element={<Chat />} />
                </Route>
                <Route path="/documentacao" element={<Construcao />} />
                <Route path="/easyFlow" element={<EasyFlow />}>
                  <Route
                    path="/easyFlow/relatoriosFlow"
                    element={<RelatoriosFlow />}
                  />
                </Route>
              </Route>
            </Routes>
          </BrowserRouter>
        </div>
      </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;
